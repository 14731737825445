import React, { Component } from 'react';
import { number, func, bool } from 'prop-types';
import { Scoped, k, a } from 'kremling';
export class FormWizardSteps extends Component {
  static propTypes = {
    step: number,
    changeStep: func,
    step1Validated: bool,
    step2Validated: bool,
    step3Validated: bool
  };
  render() {
    const {
      step,
      changeStep,
      step1Validated,
      step2Validated,
      step3Validated
    } = this.props;
    const finished = step === 4;
    return <Scoped css={css}>
        <div className="form-step-container">
          <div className={a('form-step').m('form-step--active', step === 1).m('form-step--available', !finished)} onClick={!finished ? () => changeStep(1) : null}>
            {step === 1 ? <>
                <strong>Step 1:</strong>&nbsp;Enter Form Details
              </> : <strong>1</strong>}
          </div>
          <div className={a('form-step').m('form-step--active', step === 2).m('form-step--available', step1Validated && !finished)} onClick={() => step1Validated && !finished ? changeStep(2) : null}>
            {step === 2 ? <>
                <strong>Step 2:</strong>&nbsp;Select Form
              </> : <strong>2</strong>}
          </div>
          <div className={a('form-step').m('form-step--active', step === 3).m('form-step--available', step2Validated && !finished)} onClick={() => step2Validated && !finished ? changeStep(3) : null}>
            {step === 3 ? <>
                <strong>Step 3:</strong>&nbsp;Map Fields
              </> : <strong>3</strong>}
          </div>
          <div className={a('form-step').m('form-step--active', finished).m('form-step--available', finished)} onClick={() => step3Validated ? changeStep(4) : null}>
            {step === 4 ? <>
                <strong>Step 4:</strong>&nbsp;Add Cinch Code
              </> : <strong>4</strong>}
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i37"] body,body[kremling="i37"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i37"] .form-step-container,[kremling="i37"].form-step-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  user-select: none;
}

[kremling="i37"] .form-step,[kremling="i37"].form-step {
  padding: 0 1.6rem;
  height: 3.6rem;
  width: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  margin-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  background-color: var(--color-grey-25);
  color: var(--color-grey-400);
  transition: width 400ms ease;
  cursor: default;
}

[kremling="i37"] .form-step-container>.form-step:last-child,[kremling="i37"].form-step-container>.form-step:last-child {
  margin-right: 0;
}

[kremling="i37"] .form-step.form-step--available,[kremling="i37"].form-step.form-step--available {
  background-color: var(--color-highlight);
  border: solid 0.1rem #D2D9E9;
  color: var(--color-grey-900);
  cursor: pointer;
}

[kremling="i37"] .form-step.form-step--active,[kremling="i37"].form-step.form-step--active {
  width: 24rem;
  cursor: default;
}`,
  id: 'i37',
  namespace: 'kremling'
};