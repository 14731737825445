import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'components';
import { Stepper, Step, StepContent, StepLabel, StepButton } from '@mui/material';
import { BusinessInformationStep } from './business-information-step.component';
import { AuthorizedRepresentativeStep } from './authorized-representative-step.component';
import { BusinessAddressStep } from './business-address-step.component';
import { SubmitStep } from './submit-step.component';
import { SubmitA2PStep } from './submit-a2p-step.component';
import { SubmitBrandStep } from './submit-brand-step.component';
import { BusinessTypeStep } from './business-type-step.component';
import { CampaignInformationStep } from './campaign-information-step.component';
import { Button } from 'components';
import { UserStateContext } from 'context/user-state-context';
export const CustomerProfileModal = props => {
  const {
    onClose,
    setShowBuy
  } = props;
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [profile, setProfile] = useState(props.profile);
  const [activeStep, setActiveStep] = useState(0);
  const [completedStep, setCompletedStep] = useState(0);
  const [verification, setVerification] = useState();
  const [a2pVerification, setA2PVerification] = useState();
  const updateProfile = (key, subkey, value) => {
    const newProfile = JSON.parse(JSON.stringify(profile));
    if (!newProfile[key]) {
      newProfile[key] = {};
    }
    newProfile[key][subkey] = value;
    setProfile(newProfile);
  };
  useEffect(() => {
    setCompletedStep(completedStep => Math.max(completedStep, activeStep));
  }, [activeStep]);
  useEffect(() => {
    // Check for which step we're on
    if (profile.brand_status === 'APPROVED') {
      setActiveStep(7);
    } else if (profile.brand_status) {
      setActiveStep(6);
    } else if (profile.a2p_business_information || profile.trust_bundle_status === 'in-review') {
      setActiveStep(5);
    } else if (profile.status === 'in-review') {
      setActiveStep(4);
    } else if (profile.business_address) {
      setActiveStep(3);
    } else if (profile.authorized_representative) {
      setActiveStep(2);
    } else if (profile.business_information) {
      setActiveStep(1);
    }
  }, []);
  const steps = [{
    label: 'Business Profile',
    canClick: completedStep >= 0 && profile?.status !== 'in-review' && profile?.brand_status !== 'PENDING',
    error: verification?.steps?.customer_profile_business_information?.passed === false,
    content: <BusinessInformationStep onClose={onClose} setActiveStep={setActiveStep} verification={verification?.steps?.customer_profile_business_information} profile={profile} updateProfile={updateProfile} />
  }, {
    label: 'Authorized Representative',
    canClick: completedStep >= 1 && profile?.status !== 'in-review' && profile?.brand_status !== 'PENDING',
    error: verification?.steps?.authorized_representative_1?.passed === false,
    content: <AuthorizedRepresentativeStep profile={profile} setActiveStep={setActiveStep} verification={verification?.steps?.authorized_representative_1} updateProfile={updateProfile} />
  }, {
    label: 'Business Address',
    canClick: completedStep >= 2 && profile?.status !== 'in-review' && profile?.brand_status !== 'PENDING',
    error: verification?.steps?.customer_profile_address?.passed === false,
    content: <BusinessAddressStep profile={profile} setActiveStep={setActiveStep} verification={verification?.steps?.customer_profile_address} updateProfile={updateProfile} />
  }, {
    label: 'Submit Information',
    canClick: completedStep >= 3 && profile?.brand_status !== 'PENDING',
    error: verification?.status === 'noncompliant',
    content: <SubmitStep profile={profile} setActiveStep={setActiveStep} setVerification={setVerification} verification={verification} />
  }, {
    label: 'A2P Business Information',
    canClick: completedStep >= 4 && profile?.trust_bundle_status !== 'in-review' && profile?.brand_status !== 'PENDING',
    error: false,
    content: <BusinessTypeStep profile={profile} setActiveStep={setActiveStep} updateProfile={updateProfile} />
  }, {
    label: 'Submit A2P Information',
    canClick: completedStep >= 5 && profile?.brand_status !== 'PENDING',
    error: a2pVerification?.status === 'noncompliant',
    content: <SubmitA2PStep profile={profile} setActiveStep={setActiveStep} a2pVerification={a2pVerification} setA2PVerification={setA2PVerification} />
  }, {
    label: 'Submit Brand',
    error: false,
    canClick: completedStep >= 6,
    content: <SubmitBrandStep profile={profile} setProfile={setProfile} setActiveStep={setActiveStep} />
  }, {
    label: 'Campaign Information',
    error: false,
    canClick: completedStep >= 7,
    content: <CampaignInformationStep profile={profile} updateProfile={updateProfile} />
  }];
  return <Modal size="lg" type="scroll" {...props} allowBackdropClick title="Create Customer Profile">
      <div className="modal__body">
        <p>Before you can purchase phone numbers we need to verify your business.</p>

        {hasPermission('communication.bypass_10dlc') && <p>
            As a superuser, you can bypass this and directly purchase numbers. <Button onClick={() => setShowBuy(true)}>Bypass</Button>
          </p>}

        <Stepper nonLinear={true} activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => <Step completed={completedStep > index} key={`step${index}`}>
              {step.canClick ? <StepButton onClick={() => setActiveStep(index)}>
                  <StepLabel error={step.error}>{step.label}</StepLabel>
                </StepButton> : <StepLabel error={step.error}>{step.label}</StepLabel>}
              <StepContent>{step.content}</StepContent>
            </Step>)}
        </Stepper>
      </div>
    </Modal>;
};