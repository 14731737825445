import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask id="mask0_24_6448" style={{
      maskType: 'alpha'
    }} width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_24_6448)">
        <path fill="#53A6D6" d="M7.08 16.298a.884.884 0 01-.653-.269.894.894 0 01-.267-.654V5.548H2.958a.889.889 0 01-.654-.27.895.895 0 01-.269-.656c0-.258.09-.475.27-.653a.894.894 0 01.653-.267h8.25c.257 0 .475.09.654.27.18.18.27.4.27.657s-.09.475-.27.652a.894.894 0 01-.654.267H8.006v9.827c0 .257-.09.475-.27.654a.895.895 0 01-.656.27zm7.5 0a.884.884 0 01-.653-.269.894.894 0 01-.267-.654v-5.66h-1.535a.888.888 0 01-.654-.27.895.895 0 01-.27-.657c0-.257.09-.475.27-.652a.894.894 0 01.654-.267h4.917c.256 0 .474.09.653.27.18.18.27.399.27.656 0 .258-.09.475-.27.653a.894.894 0 01-.653.267h-1.536v5.66c0 .257-.09.475-.27.654a.895.895 0 01-.656.27z"></path>
      </g>
    </svg>;
}
export default Icon;