import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
export const DynamicTypeAheadMui = props => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const defaultFilters = props.getItemsFilters || {};
    props.getItems({
      ...defaultFilters,
      search: search,
      limit: 999,
      offset: 0
    }).then(({
      data
    }) => {
      const results = data.results;
      setOptions(results);
      for (const result of results) {
        if (result.id === props.value) {
          setValue(result);
          if (result?.name) {
            setInputValue(result.name);
          }
        }
      }
    });
    setIsLoading(false);
  }, [search]);
  return <>
      {!isLoading && <div>
          <Autocomplete freeSolo value={value} inputValue={inputValue} onChange={(event, newValue) => {
        setValue(newValue);
        props.onChange(newValue);
      }} onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
        setInputValue(newInputValue);
      }} options={options} getOptionLabel={option => {
        return option[props.displayProperty];
      }} sx={{
        width: 300,
        ...props.sx
      }} renderInput={params => {
        return <TextField {...params} size={props.size || 'medium'} placeholder={props.placeholder} label={props.label} />;
      }} renderOption={(propsOpt, option) => {
        return <li {...propsOpt} key={option.id}>
                  {option[props.displayProperty]}
                </li>;
      }} />
        </div>}
    </>;
};