import React, { createRef, useEffect, useState, useCallback, useRef } from 'react';
import { Scoped, a, m } from 'kremling';
import { isEqual } from 'lodash';
import styles from './dynamic-type-ahead.styles.scss';
import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';
import Tooltip from 'rc-tooltip';
export const DynamicTypeAhead = props => {
  const contentEl = createRef();
  const isScrollLoading = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAbove, setIsOpenAbove] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const getNestedProp = (item, prop) => prop?.split('__').reduce((obj, key) => obj[key] ? obj[key] : null, item);
  useEffect(() => {
    if (isOpen || isOpenAbove) {
      const handleDocumentClick = e => {
        // This isn't working because contentEl is null... :(
        if (contentEl.current && !contentEl.current.contains(e.target)) {
          close();
        }
      };
      document.addEventListener('click', handleDocumentClick);
      return () => {
        // On unmount
        document.removeEventListener('click', handleDocumentClick);
      };
    }
  }, [isOpen, isOpenAbove, contentEl]);
  useEffect(() => {
    const {
      value,
      displayProperty,
      keyProperty,
      getItemsFilters
    } = props;
    if (!value || value === '' || !displayProperty) {
      setSearch('');
      return;
    }
    let id = typeof value === 'string' || typeof value === 'number' ? value : value[keyProperty];
    if (id) {
      // If we only have the ID then fetch the data.
      props.getItems({
        ...getItemsFilters,
        [keyProperty]: id,
        limit: 1
      }).then(({
        data
      }) => {
        if (data.count > 1) {
          console.warning('Endpoint is not set up properly and returned more than one item.');
        }
        if (data.count) {
          if (data.results[0][keyProperty] !== id) {
            throw 'Filtering not properly set up on endpoint and we got the wrong item';
          }

          // Check placeholder to avoid to fire search in the component if this not have only ID
          const placeholder = getNestedProp(data.results[0], displayProperty);
          if (props.placeholder !== placeholder) {
            setSearch(placeholder);
            setSelectedIndex(0);
          }
        }
      });
    }
  }, [props.value]);
  const getItemBySearchOrLimit = (search, limit, offset) => {
    const defaultFilters = props.getItemsFilters || {};
    props.getItems({
      ...defaultFilters,
      search,
      limit,
      offset
    }).then(({
      data
    }) => {
      setItems(items => offset ? [...items, ...data.results] : data.results);
      setNextPage(data.next);
      isScrollLoading.current = false;
    });
  };
  useEffect(() => {
    getItemBySearchOrLimit(search, limit, offset);
  }, [search, limit, offset]);

  // TODO Check if these functions should useRef or anything like that.
  const open = () => {
    setIsOpen(true);
  };

  // TODO Check if these functions should useRef or anything like that.
  const close = () => {
    setIsOpen(false);
    setIsOpenAbove(false);
    setShowTooltip(false);
  };
  const onSearch = e => {
    if (!isOpen) open();
    setSearch(e.target.value);
    setOffset(0);
    setSelectedIndex(0);
  };
  const onFocus = e => {
    e.target.setSelectionRange(0, e.target.value.length);
    if (!isOpen) {
      open();
    }
  };
  const toggle = selectedItem => {
    if (isOpen && !selectedItem) {
      close();
    }
    if (selectedItem) {
      clear();
    }
    if (!isOpen) {
      open();
    }
  };
  const clear = () => {
    props.onChange(null);
    setSearch('');
    setSelectedIndex(0);
  };
  const onSelect = item => {
    const {
      displayProperty
    } = props;
    props.onChange(item);
    setSearch(displayProperty ? getNestedProp(item, displayProperty) : item);
    setSelectedIndex(0);
    close();
  };
  const onBlur = () => {
    if (isOpen) {
      close();
    }
  };
  const handleScroll = e => {
    if (nextPage && !isScrollLoading.current) {
      const height = e.target.scrollHeight - e.target.clientHeight;
      const position = e.target.scrollTop;
      if (position >= height * 0.9) {
        isScrollLoading.current = true;
        setOffset(offset => offset + limit);
      }
    }
  };
  const displayTextWidth = text => {
    const canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = 'normal 13.3333pt san-serif';
    const metrics = context.measureText(text);
    const container = document.getElementsByClassName('type-ahead__container')[0].offsetWidth;
    if (metrics.width >= container) {
      return true;
    }
    return false;
  };
  return <Scoped css={styles}>
      <div className={"type-ahead " + props.className} ref={contentEl}>
        {!!props.disabled ? <div className="type-ahead__container">
            <input type="text" className="form-control" value={search} placeholder={props.placeholder} disabled={true} />
          </div> : <>
            <div className="type-ahead__container">
              <input type="text" className={!props.value ? 'form-control placeholderWithoutValue' : 'form-control placeholderStyle'} onChange={onSearch} value={search} onFocus={onFocus} placeholder={props.placeholder} autoComplete="none" onBlur={onBlur} />
            </div>
            <Button icon={props.value ? 'fa-regular-times' : 'fa-solid-caret-down'} actionType="flat" onClick={() => toggle(props.value)} tabIndex={-1} type="button" />
            <div onScroll={e => handleScroll(e)} className={a('type-ahead__content').m('type-ahead__content--open', isOpen).m('type-ahead__content--above', isOpenAbove)}>
              <ul className="select-list select-list__controlled">
                {items.map((item, i) => <li key={props.keyProperty ? item[props.keyProperty] : item} role="option">
                    <Tooltip visible={showTooltip && selectedIndex === i} placement="right" overlay={item.name}>
                      <a onMouseDown={() => onSelect(item)} className={m('active', selectedIndex === i)} onMouseOver={() => {
                  const show = displayTextWidth(item.name);
                  if (show) {
                    setShowTooltip(true);
                  } else {
                    setShowTooltip(false);
                  }
                  setSelectedIndex(i);
                }}>
                        <div className="select-list__content">
                          {props.displayComponent ? props.displayComponent(item) : props.displayProperty ? getNestedProp(item, props.displayProperty) : item}
                        </div>
                        {isEqual(props.value, props.keyProperty ? getNestedProp(item, props.displayProperty) : item) && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </Tooltip>
                  </li>)}
              </ul>
            </div>
          </>}
      </div>
    </Scoped>;
};