import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_10_845" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.902 0.141H24.902V24.141H0.902z"></path>
      </mask>
      <g mask="url(#mask0_10_845)">
        <path fill={props.fill || '#FFB82B'} d="M11.056 14.949c.213 0 .391-.072.535-.216a.726.726 0 00.215-.534v-4.115a.725.725 0 00-.216-.535.726.726 0 00-.534-.215.725.725 0 00-.534.215.726.726 0 00-.216.535v4.115c0 .212.072.39.216.534a.726.726 0 00.534.216zm3.693 0c.212 0 .39-.072.534-.216a.726.726 0 00.215-.534v-4.115a.725.725 0 00-.215-.535.726.726 0 00-.535-.215.725.725 0 00-.534.215.726.726 0 00-.216.535v4.115c0 .212.072.39.216.534a.726.726 0 00.535.216zm-1.845 6.692a9.272 9.272 0 01-3.705-.746 9.596 9.596 0 01-3.017-2.026 9.57 9.57 0 01-2.031-3.01 9.215 9.215 0 01-.749-3.698c0-.44.031-.877.093-1.31.061-.432.148-.86.261-1.283.07-.283.216-.478.435-.584.219-.107.454-.086.704.061.125.074.221.19.29.35.068.159.078.323.03.492-.102.37-.18.742-.233 1.118-.053.375-.08.754-.08 1.136 0 2.234.775 4.125 2.325 5.675 1.55 1.55 3.442 2.325 5.675 2.325 2.234 0 4.125-.775 5.675-2.325 1.55-1.55 2.325-3.441 2.325-5.675 0-2.233-.775-4.125-2.325-5.675-1.55-1.55-3.441-2.325-5.675-2.325a7.93 7.93 0 00-1.133.08 8.582 8.582 0 00-1.072.224.78.78 0 01-.51-.009.717.717 0 01-.38-.322.62.62 0 01-.035-.66.888.888 0 01.573-.45 6.168 6.168 0 011.23-.273c.464-.06.888-.09 1.27-.09 1.32 0 2.561.248 3.723.745a9.589 9.589 0 013.034 2.031 9.653 9.653 0 012.046 3.02 9.18 9.18 0 01.754 3.703c0 1.314-.249 2.548-.748 3.705a9.596 9.596 0 01-2.03 3.017 9.59 9.59 0 01-3.016 2.03 9.245 9.245 0 01-3.704.75zM6.614 7.045c-.327 0-.607-.117-.841-.35a1.148 1.148 0 01-.351-.842c0-.327.117-.608.35-.842.235-.234.515-.35.842-.35.327 0 .607.117.841.35.234.234.351.515.351.842 0 .327-.117.607-.35.841a1.148 1.148 0 01-.842.351z"></path>
      </g>
    </svg>;
}
export default Icon;