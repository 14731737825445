import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { Transition, animated } from 'react-spring/renderprops';
import { Icon } from '../../icon/icon.component';
import { Media } from '../../media/media.component';
import { Button } from '../../button/button.component';
import { toasterService } from '../../toaster/toaster-service';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
import utils from '../../../shared/utils';
import { Emoji } from '../../../components/emoji/emoji.component';
import { SmsTemplatesMenu } from 'components/templates/sms-templates.component';
import { UserStateContext } from 'context/user-state-context';
import { ModalFileViewer2 } from 'components/file-viewer/modal-file-viewer.component-mui';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
export function MessagingComponent(props) {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const {
    messages,
    customerDetails,
    customer,
    phoneNumbers,
    sendMessage,
    isLoading
  } = props;
  const [addFileOpen, setAddFileOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [attachments, setAttachments] = useState([]);
  const addFile = file => {
    setAttachments([...attachments, file]);
    setAddFileOpen(false);
  };
  const removeFile = index => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };
  const openAddFile = () => {
    if (customerDetails.sms_unsubscribe_id) {
      return;
    }
    if (attachments.length >= 10) {
      toasterService.error('Message cannot contain include any more attachments');
      return;
    }

    // TODO add up size to make sure it's less than max.

    setAddFileOpen(true);
  };
  const innerSendMessage = () => {
    sendMessage(message, phoneNumber, attachments).then(() => {
      setMessage('');
      setAttachments([]);
    });
  };
  const handleTemplateChoice = (template, revision) => {
    setMessage(message ? message + revision.message : revision.message);
    setAttachments([...attachments, ...revision.media]);
  };
  return <Scoped css={styles}>
      <div className="drawer__body" style={{
      height: '100%',
      overflowY: 'auto'
    }}>
        {isLoading ? <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        pt: '25px'
      }}>
            <CircularProgress />
          </Box> : <div className="d-flex h-100 flex-column">
            <div className="flex-grow messages-list">
              <div className="messages-list-content">
                <Transition native items={messages} keys={message => message.id} from={{
              transform: 'translateY(10rem)'
            }} enter={{
              transform: 'translateY(0)'
            }} config={{
              mass: 0.5,
              tension: 120,
              friction: 10
            }}>
                  {message => props => <animated.div style={props} className={`message message--${message.direction} mb-2`}>
                        {(message.status === 'sent' || message.status === 'queued') && <div className="message-status" data-tip={message.status.charAt(0).toUpperCase() + message.status.slice(1)}>
                            <Icon name="fa-regular-ellipsis-h" size={10} />
                          </div>}
                        {(message.status === 'failed' || message.status === 'undelivered') && <div className="message-status" data-tip={message.status.charAt(0).toUpperCase() + message.status.slice(1)}>
                            <Icon name="fa-solid-exclamation" size={10} />
                          </div>}
                        <div className="message-bubble">
                          {(message.media || []).map((media, index) => <Media media={media} key={index} />)}
                          {message.message}
                        </div>
                        <div className="d-flex w-100 justify-content-between text-sm">
                          <div>{message.direction === 'outgoing' ? message.twilio_phone : message.phone}</div>
                          <div className="ml-3">{moment(message.created_when).local().format('MMM D, YYYY h:mm A')}</div>
                        </div>
                      </animated.div>}
                </Transition>
              </div>
            </div>

            {hasPermission('customer.send_two_way_messaging') && <>
                <textarea placeholder="Send customer a message" className="form-control mt-3 reply-box" value={customerDetails.sms_unsubscribe_id ? 'Customer unsubscribed from text messages' : !customer.phone ? "Customer doesn't have a phone number" : !phoneNumbers.length ? 'You need to buy a phone number first' : message} onChange={e => setMessage(e.target.value)} autoFocus disabled={customerDetails.sms_unsubscribe_id || !customer.phone || !phoneNumbers.length} />
                {!!attachments.length && <div className="mt-3">
                    <strong>Attachments:</strong>
                    {attachments.map((a, i) => <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="truncate">{a.name}</div>
                        <div>
                          <Button small actionType="flat" icon="fa-regular-times" onClick={() => removeFile(i)} />
                        </div>
                      </div>)}
                  </div>}
                <div className="d-flex mt-3">
                  <select disabled={customerDetails.sms_unsubscribe_id || !customer.phone || !phoneNumbers.length} className="form-control" onChange={e => setPhoneNumber(e.target.value)} value={phoneNumber}>
                    <option value="">Automatic</option>
                    {phoneNumbers.map(phone => <option value={phone.id} key={phone.id}>
                        {utils.formatPhoneNumber(phone.phone)}
                      </option>)}
                  </select>
                  {!customerDetails.sms_unsubscribe_id && customer.phone && phoneNumbers.length && <Box sx={{
              position: 'relative',
              bottom: '5px'
            }}>
                      <SmsTemplatesMenu handleTemplateChoice={handleTemplateChoice} placement="top-start" />
                    </Box>}

                  {!customerDetails.sms_unsubscribe_id && customer.phone && phoneNumbers.length && <Box sx={{
              position: 'relative',
              bottom: '5px'
            }}>
                      <Emoji onEmojiClick={emoji => setMessage(message + emoji)} />
                    </Box>}
                  <Button className={classnames('mx-3', {
              disabled: customerDetails.sms_unsubscribe_id || !customer.phone || !phoneNumbers.length
            })} actionType="flat" icon="fa-regular-paperclip" onClick={() => openAddFile()} disabled={customerDetails.sms_unsubscribe_id || !customer.phone || !phoneNumbers.length} />
                  <Button className={classnames({
              disabled: customerDetails.sms_unsubscribe_id || !customer.phone || !phoneNumbers.length
            })} actionType="primary" disabled={!message || customerDetails.sms_unsubscribe_id} onClick={() => innerSendMessage()}>
                    <span style={{
                color: 'white'
              }}> Send </span>
                  </Button>
                </div>
              </>}
          </div>}

        <ModalFileViewer2 open={addFileOpen} onSubmit={addFile} onClose={() => setAddFileOpen(false)} />
      </div>
    </Scoped>;
}