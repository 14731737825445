import React, { useState, useEffect } from 'react';
import { AsyncModalDialog } from '../../components/modal/async-modal-dialog.component';
export const AddFieldModal = props => {
  const [name, setName] = useState('');
  const [id, setID] = useState('entity_data__');
  const [type, setType] = useState('');
  useEffect(() => {
    setID(`entity_data__${name.toLowerCase().replace(/[\W_]+/g, ' ').trim().replace(/[ ]+/g, '_')}`);
  }, [name]);
  const submit = () => {
    return props.addField(props.modelID, {
      id,
      name,
      type
    });
  };
  return <AsyncModalDialog onClose={props.onClose} open={props.open} title={props.title} onSubmit={submit}>
      <p>
        <label>Name</label>
        <input required className="form-control" defaultValue={name} onChange={e => setName(e.target.value)} />
      </p>
      <p>
        <label>ID</label>
        <input disabled className="form-control" value={id} />
      </p>

      <p>
        <label>Type</label>
        <select className="form-control" value={type} onChange={e => setType(e.target.value)}>
          <option value="text">Text</option>
          <option value="date">Date</option>
          <option value="datetime">Date/Time</option>
          <option value="number">Number</option>
          <option value="boolean">Boolean</option>
          <option value="list">List</option>
        </select>
      </p>
    </AsyncModalDialog>;
};