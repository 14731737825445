import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@mui/material/Typography';
import { Box, Button, Slider, Stack } from '@mui/material';
export const SliderFilter = props => {
  const {
    showChip,
    filterKey,
    filters,
    disabled,
    chipStyle,
    label,
    handleFilterChange,
    onVal,
    offVal,
    defaultVal
  } = props;
  if (showChip) {
    if (!filters[`${filterKey}_after`] || !filters[`${filterKey}_before`]) {
      return null;
    }
    const after = filters[`${filterKey}_after`] * 100;
    const before = filters[`${filterKey}_before`] * 100;
    const chipLabel = `${label}: ` + (after.toFixed(0) || 0) + ' - ' + (before.toFixed(0) || 0) + '%';
    return <Chip label={<span>{chipLabel}</span>} deleteIcon={<CloseOutlinedIcon sx={{
      transform: 'scale(0.75)'
    }} />} onDelete={() => {
      const {
        [`${filterKey}_after`]: _,
        [`${filterKey}_before`]: __,
        ...newFilters
      } = filters || {};
      handleFilterChange(newFilters);
    }} className={chipStyle} />;
  }
  const [value, setValue] = React.useState(() => {
    const v = [0, 100];
    if (filters[`${filterKey}_after`]) {
      v[0] = (filters[`${filterKey}_after`] * 100).toFixed(0);
    }
    if (filters[`${filterKey}_before`]) {
      v[1] = (filters[`${filterKey}_before`] * 100).toFixed(0);
    }
    return v;
  });
  const handleChange = (event, newValue) => {
    if (newValue[0] == 0 && newValue[1] == 100) {
      const {
        [`${filterKey}_after`]: _,
        [`${filterKey}_before`]: __,
        ...newFilters
      } = filters || {};
      handleFilterChange(newFilters);
    } else {
      handleFilterChange({
        ...(filters || {}),
        [`${filterKey}_after`]: (newValue[0] / 100).toFixed(2),
        [`${filterKey}_before`]: (newValue[1] / 100).toFixed(2)
      });
    }
  };
  return <FormControl sx={{
    width: '100%'
  }}>
      <FormLabel>
        <Typography variant="tableHeader">{label}</Typography>
      </FormLabel>
      <Stack spacing={2}>
        {value[0] == 0 && value[1] == 100 ? <Stack sx={{
        display: 'flex',
        alignItems: 'center'
      }} direction="row" spacing={1}>
            <Typography> All Scores </Typography>
            <Typography sx={{
          color: '#3898D9'
        }}>0 - 100% </Typography>
          </Stack> : <Box className="d-flex w-100  justify-content-between">
            <Stack sx={{
          display: 'flex',
          alignItems: 'center'
        }} direction="row" spacing={1}>
              <Typography> Score range </Typography>
              <Typography sx={{
            color: '#3898D9'
          }}>{value[0] + ' - ' + value[1] + '%'} </Typography>
            </Stack>
            <Button onClick={() => {
          const {
            [`${filterKey}_after`]: _,
            [`${filterKey}_before`]: __,
            ...newFilters
          } = filters || {};
          handleFilterChange(newFilters);
          setValue([0, 100]);
        }}>
              <Typography sx={{
            color: '#3898D9',
            textTransform: 'none'
          }}> Clear </Typography>
            </Button>
          </Box>}

        <Box sx={{
        pl: '50px',
        width: 230
      }}>
          <Slider value={value} onChangeCommitted={handleChange} onChange={(e, newValue) => setValue(newValue)} valueLabelDisplay="off" />
        </Box>
      </Stack>
    </FormControl>;
};