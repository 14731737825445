import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_10_851" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.902 0.141H24.902V24.141H0.902z"></path>
      </mask>
      <g mask="url(#mask0_10_851)">
        <path fill={props.fill || '#EF3C34'} d="M10.112 20.641a1.801 1.801 0 01-1.275-.527l-3.908-3.907a1.8 1.8 0 01-.527-1.275V9.35a1.801 1.801 0 01.527-1.275l3.908-3.908a1.8 1.8 0 011.275-.527h5.58a1.801 1.801 0 011.275.527l3.908 3.908a1.801 1.801 0 01.527 1.275v5.58a1.8 1.8 0 01-.527 1.275l-3.907 3.908a1.801 1.801 0 01-1.275.527h-5.581zm2.79-3.788c.23 0 .421-.078.576-.233a.781.781 0 00.232-.575.781.781 0 00-.232-.575.781.781 0 00-.576-.233.782.782 0 00-.575.233.781.781 0 00-.232.575c0 .229.077.42.232.575a.781.781 0 00.575.233zm0-3.481c.213 0 .391-.072.535-.216a.726.726 0 00.215-.534V8.084a.725.725 0 00-.215-.535.726.726 0 00-.535-.215.725.725 0 00-.534.215.726.726 0 00-.216.535v4.538c0 .213.072.39.216.534a.727.727 0 00.535.216zm-2.9 5.77h5.8l4.1-4.1v-5.8l-4.1-4.1h-5.8l-4.1 4.1v5.8l4.1 4.1z"></path>
      </g>
    </svg>;
}
export default Icon;