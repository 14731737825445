import * as React from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Chip from '@mui/material/Chip';
import moment from 'moment';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import dayjs from 'dayjs';
const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: '#53A6D6'
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);
export const DateRangeFilter = props => {
  const {
    showChip,
    filterKey,
    filters,
    disabled,
    chipStyle,
    label,
    handleFilterChange
  } = props;
  if (showChip) {
    if (!filters[`${filterKey}_after`] || !filters[`${filterKey}_before`]) {
      return null;
    }
    let dateDiff = moment(filters[`${filterKey}_before`]).diff(moment(filters[`${filterKey}_after`]), 'days', true);
    let chipLabel = `${label}: ` + moment(filters[`${filterKey}_after`]).format('MM/DD/YY');
    if (dateDiff > 1) {
      chipLabel += ' - ' + moment(filters[`${filterKey}_before`]).format('MM/DD/YY');
    }
    return <Chip label={<span>{chipLabel}</span>} deleteIcon={<CloseOutlinedIcon sx={{
      transform: 'scale(0.75)'
    }} />} onDelete={() => {
      const {
        [`${filterKey}_after`]: _,
        [`${filterKey}_before`]: __,
        ...newFilters
      } = filters || {};
      handleFilterChange(newFilters);
    }} className={chipStyle} />;
  }
  const [dateVal, setDateVal] = React.useState(!filters[`${filterKey}_before`] && !filters[`${filterKey}_after`] ? 'all' : moment(filters[`${filterKey}_before`]).diff(moment(filters[`${filterKey}_after`]), 'days', true) > 1 ? 'range' : 'date');
  const [dateValue, setDateValue] = React.useState(filters[`${filterKey}_after`] ? dayjs(filters[`${filterKey}_after`]) : dayjs());
  const [rangeAfterDate, setRangeAfterDate] = React.useState(filters[`${filterKey}_after`] ? dayjs(filters[`${filterKey}_after`]) : dayjs().subtract(7, 'day'));
  const [rangeBeforeDate, setRangeBeforeDate] = React.useState(filters[`${filterKey}_before`] ? dayjs(filters[`${filterKey}_before`]) : dayjs());
  return <FormControl>
      <FormLabel id={`${filterKey}_after`}>
        <Typography variant="tableHeader">{label}</Typography>
      </FormLabel>
      <RadioGroup sx={{
      ml: '8px'
    }} onChange={(e, v) => {
      setDateVal(v);
      if (v == 'all') {
        const {
          [`${filterKey}_after`]: _,
          [`${filterKey}_before`]: __,
          ...newFilters
        } = filters || {};
        handleFilterChange(newFilters);
      } else if (v == 'date') {
        const after = new Date(new Date(dateValue).setHours(0, 0, 0, 0));
        const d = new Date(after);
        const before = new Date(d.setDate(d.getDate() + 1));
        handleFilterChange({
          ...(filters || {}),
          [`${filterKey}_after`]: after.toISOString(),
          [`${filterKey}_before`]: before.toISOString()
        });
      } else if (v == 'range') {
        const after = new Date(new Date(rangeAfterDate).setHours(0, 0, 0, 0));
        const before = new Date(new Date(rangeBeforeDate).setHours(23, 59, 59, 999));
        handleFilterChange({
          ...(filters || {}),
          [`${filterKey}_after`]: dayjs(after).toISOString(),
          [`${filterKey}_before`]: dayjs(before).toISOString()
        });
      }
    }} defaultValue="all" name={`${filterKey}-date-radio-group`}>
        <FormControlLabel value="all" control={<CustomRadio />} label="Show all" checked={dateVal == 'all'} disabled={disabled} />
        <FormControlLabel value="date" control={<CustomRadio />} label="Date" checked={dateVal == 'date'} disabled={disabled} />
        <FormControlLabel value="range" control={<CustomRadio />} label="Date Range" checked={dateVal == 'range'} disabled={disabled} />

        {dateVal == 'date' && <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disabled={disabled} value={dateValue} OpenPickerButtonProps={{
          style: {
            fontSize: 24
          }
        }} onChange={newDate => {
          const after = new Date(new Date(newDate).setHours(0, 0, 0, 0));
          const d = new Date(after);
          const before = new Date(d.setDate(d.getDate() + 1));
          setDateValue(dayjs(after));
          handleFilterChange({
            ...(filters || {}),
            [`${filterKey}_after`]: dayjs(after).toISOString(),
            [`${filterKey}_before`]: dayjs(before).toISOString()
          });
        }} renderInput={params => <TextField disabled={disabled} {...params} />} />
          </LocalizationProvider>}

        {dateVal == 'range' && <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{
        start: 'Start date',
        end: 'End date'
      }} sx={{
        padding: '10px'
      }}>
            <DateRangePicker disabled={disabled} value={[rangeAfterDate, rangeBeforeDate]} onChange={newValue => {
          const after = dayjs(new Date(new Date(newValue[0]).setHours(0, 0, 0, 0)));
          const before = dayjs(new Date(new Date(newValue[1]).setHours(23, 59, 59, 999)));
          setRangeAfterDate(after);
          //setRangeBeforeDate(before);
          handleFilterChange({
            ...(filters || {}),
            [`${filterKey}_after`]: after.toISOString(),
            [`${filterKey}_before`]: before.toISOString()
          });
        }} renderInput={(startProps, endProps) => <React.Fragment>
                  <TextField {...startProps} disabled={disabled} />
                  <Box sx={{
            mx: 1
          }}> to </Box>
                  <TextField {...endProps} disabled={disabled} />
                </React.Fragment>} />
          </LocalizationProvider>}
      </RadioGroup>
    </FormControl>;
};