import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_1036_2692" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#24335D" d="M0.759 0H24.759V24H0.759z"></path>
      </mask>
      <g mask="url(#mask0_1036_2692)">
        <path fill="#fff" d="M6.067 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V5.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h5.942v17H6.067zm7.442 0V12h7.75v6.692c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H13.51zm0-10v-7h5.942c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283V10.5h-7.75z"></path>
      </g>
    </svg>;
}
export default Icon;