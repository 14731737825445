import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Scoped } from 'kremling';
import { debounce } from 'lodash';
import css from './node-modal.styles.scss';
const sideBarWidth = 220;
const defaultBoxWidth = 124;
const gap = 14;
export const ModalWrapper = props => {
  const nodeModalRef = useRef(null);
  const {
    node,
    stageX,
    stageY,
    scale,
    clientX,
    clientY
  } = props;
  const [width] = useState(node.modalWidth || 380);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const calcSize = () => {
    const boxWidth = defaultBoxWidth * scale;
    const viewWidth = window.innerWidth;
    const viewHeight = window.innerHeight;
    const spaceOnLeft = clientX + stageX + node.container.x * scale;
    const spaceOnRight = viewWidth - (spaceOnLeft + boxWidth * scale + gap);
    const onLeft = spaceOnLeft - width - gap;
    const onRight = spaceOnLeft + boxWidth + gap;
    const left = spaceOnLeft > spaceOnRight ? onLeft : onRight;
    let top = 0;
    let rect = nodeModalRef.current.getBoundingClientRect();
    if (rect) {
      if (rect.height + gap * 2 > viewHeight) {
        top = gap;
      } else if (rect.height + stageY + node.container.y * scale > viewHeight) {
        top = viewHeight - rect.height - gap;
      } else {
        top = stageY + clientY + node.container.y * scale;
      }
    } else {
      top = stageY + clientY + node.container.y * scale;
    }
    setTop(top);
    setLeft(left);
  };
  useEffect(() => {
    const debouncedReize = debounce(calcSize, 500);
    document.addEventListener('resize', debouncedReize);
    return () => {
      document.removeEventListener('resize', debouncedReize);
    };
  }, []);
  useEffect(() => {
    calcSize();
  }, [props.node, props.stageX, props.stageY, props.scale, nodeModalRef.current]);
  if (!node) {
    // Is this necessary?
    return null;
  }
  return createPortal(<Scoped css={css}>
      <div ref={nodeModalRef} className="node-modal" style={{
      left,
      top,
      width
    }}>
        {props.children}
      </div>
    </Scoped>, document.body);
};