import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Scoped } from 'kremling';
import classnames from 'classnames';
import styles from './file-viewer.styles.scss';
import { Loader } from '../loader/loader.component';
import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';
import moment from 'moment-timezone';
import { fileSize, formatFile } from './utils';
import { DashboardModal } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { createMultipartUpload, listParts, prepareUploadPart, completeMultipartUpload, abortMultipartUpload } from './provider';
import { getCompanyFiles, createCompanyFilesFolder, deleteCompanyFile, renameCompanyFile } from '../../shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Modal } from '../modal/modal.component';
import { toasterService } from '../toaster/toaster-service';
const renderIcon = file => {
  if (file.isFolder) {
    if (file.expanded) {
      return <Icon name="fa-regular-folder-open" className="mr-3" />;
    } else {
      return <Icon name="fa-regular-folder" className="mr-3" />;
    }
  }
  if (['jpg', 'jpeg', 'png', 'bmp'].indexOf(file.extension) > -1) {
    return <Icon name="fa-regular-file-image" className="mr-3" />;
  }
  if (['pdf'].indexOf(file.extension) > -1) {
    return <Icon name="fa-regular-file-pdf" className="mr-3" />;
  }
  return <Icon name="fa-regular-file" className="mr-3" />;
};
export function FileViewer(props) {
  const {
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uppy, setUppy] = useState();
  const [newFolderTarget, setNewFolderTarget] = useState(null);
  const [newFolder, setNewFolder] = useState();
  const [newFolderError, setNewFolderError] = useState();
  const [deleteFile, setDeleteFile] = useState();
  const [deleteFileMessage, setDeleteFileMessage] = useState();
  const [renameFile, setRenameFile] = useState();
  const [renameFileError, setRenameFileError] = useState();
  const [renameNewFile, setRenameNewFile] = useState();
  const loadData = useCallback(oldFiles => {
    // TODO refactor this nightmare to make it faster
    getCompanyFiles(asCompany.id).then(({
      data
    }) => {
      setLoading(false);
      const newFiles = data.map(file => {
        const oldFile = oldFiles && oldFiles.find(f => f.Key === file.Key);
        if (oldFile) {
          return oldFile;
        }
        return formatFile(file);
      }).sort((a, b) => {
        for (let i = 0; i < Math.max(a.sortParts.length, b.sortParts.length); i++) {
          if (a.sortParts[i] !== b.sortParts[i]) {
            return a.sortParts[i] < b.sortParts[i] ? -1 : 1;
          }
        }
        return 0;
      }).map((file, index, array) => {
        if (file.parentFolder !== '/') {
          for (let i = index - 1; i >= 0; i--) {
            if (array[i].shortKey === file.parentFolder) {
              file.visible = array[i].expanded;
            }
          }
        } else {
          file.visible = true;
        }
        return file;
      });
      setFiles(newFiles);
    });
  }, [setLoading, setFiles, asCompany]);
  useEffect(() => {
    loadData();
  }, [loadData]);
  useEffect(() => {
    loadData([]);
  }, [asCompany]);
  const selectFile = file => {
    // If file is a folder then expand it
    if (file.isFolder) {
      file.expanded = !file.expanded;
      files.filter(f => f.parentFolder === file.shortKey || !file.expanded && file.shortKey === f.parentFolder.substring(0, file.shortKey.length)).forEach(f => {
        f.visible = file.expanded;
        if (!file.expanded && f.isFolder) {
          f.expanded = file.expanded;
        }
      });
    }
    if (props.onSelect) {
      props.onSelect({
        Key: file.Key,
        ETag: file.ETag,
        LastModified: file.LastModified,
        Owner: file.Owner,
        Size: file.Size,
        StorageClass: file.StorageClass,
        name: file.name,
        isFolder: file.isFolder,
        url: file.url,
        extension: file.extension,
        formatedSize: fileSize(file.Size)
      });
    }
    files.forEach(f => f.selected = f === file);
    setFiles([...files]);
  };
  const openModal = (event, parentFolder) => {
    event.stopPropagation();
    const uppy = new Uppy({
      autoProceed: true,
      maxTotalFileSize: 25000000,
      // Max file size 25mb
      allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif', '.pdf']
    }).use(AwsS3Multipart, {
      companionUrl: API_URL + `/company-files/${asCompany.id}/upload`,
      parentFolder,
      createMultipartUpload,
      listParts,
      prepareUploadPart,
      completeMultipartUpload,
      abortMultipartUpload
    });
    setUppy(uppy);
  };
  const openNewFolder = (event, targetFolder) => {
    event.stopPropagation();
    setNewFolderTarget(targetFolder);
    setNewFolder('');
  };
  const updateNewFolder = newFolder => {
    setNewFolder(newFolder);
    if (RegExp(/[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/g).test(newFolder)) {
      setNewFolderError('Folder name must not contain special characters.');
    } else {
      setNewFolderError(null);
    }
  };
  const createFolder = () => {
    if (newFolderError) {
      return;
    }
    if (!(newFolder || '').length) {
      setNewFolderError('Folder name is required.');
      return;
    }
    createCompanyFilesFolder(asCompany.id, newFolderTarget + newFolder).then(() => {
      loadData(files);
      setNewFolderTarget(null);
      setNewFolder(null);
      setNewFolderError(null);
      toasterService.success('Successfully create new folder');
    }).catch(err => {
      toasterService.error('There was an error trying to create the folder. Please try again.');
    });
  };
  const openDeleteFile = (event, file) => {
    event.stopPropagation();
    if (file.isFolder) {
      const subItemCount = files.filter(f => f.shortKey.substring(0, file.shortKey.length) === file.shortKey).length - 1;
      if (subItemCount > 0) {
        setDeleteFileMessage(`Are you sure you want to delete the folder ${file.name} and its ${subItemCount} files?`);
      } else {
        setDeleteFileMessage(`Are you sure you want to delete the empty folder ${file.name}?`);
      }
    } else {
      setDeleteFileMessage(`Are you sure you want to delete the file ${file.name}?`);
    }
    setDeleteFile(file);
  };
  const confirmDeleteFile = () => {
    deleteCompanyFile(asCompany.id, deleteFile.shortKey).then(() => {
      loadData(files);
      setDeleteFile(null);
      setDeleteFileMessage(null);
      toasterService.success('Successfully deleted file.');
    }).catch(err => {
      toasterService.error('There was an error trying to delete the file. Please try again.');
    });
  };
  const openRenameFile = (event, file) => {
    event.stopPropagation();
    setRenameFile(file);
    setRenameFileError(null);
    setRenameNewFile(file.name);
  };
  const updateRenameFile = newName => {
    setRenameNewFile(newName);
    if (renameFile.isFolder && RegExp(/[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/g).test(newName)) {
      setRenameFileError('Folder name must not contain special characters.');
    } else if (!renameFile.isFolder && RegExp(/[/]/g).test(newName)) {
      setRenameFileError('File name must not contain forward slash.');
    } else if (newName.length === 0) {
      setRenameFileError('Name is required.');
    } else {
      setRenameFileError(null);
    }
  };
  const confirmRenameFile = () => {
    if (!(renameNewFile || '').length) {
      setRenameFileError('Name is required.');
      return;
    }
    let oldKey, newKey;
    if (renameFile.parentFolder !== '/') {
      oldKey = `${renameFile.parentFolder}${renameFile.name}`;
      newKey = `${renameFile.parentFolder}${renameNewFile}`;
    } else {
      oldKey = renameFile.name;
      newKey = renameNewFile;
    }
    if (renameFile.isFolder) {
      oldKey += '/';
      newKey += '/';
    }
    renameCompanyFile(asCompany.id, oldKey, newKey).then(() => {
      setFiles(files.map(file => {
        if (file.shortKey.substring(0, oldKey.length) === oldKey) {
          // Renaming!
          const expanded = file.extension;
          const visible = file.visible;
          const prefix = file.Key.substring(0, file.Key.length - file.shortKey.length);
          file.Key = prefix + newKey + file.shortKey.substring(oldKey.length);
          file.url = file.url.replace(oldKey, newKey);
          file = formatFile(file);
          file.visible = visible;
          file.expanded = expanded;
        }
        return file;
      }));
      setRenameFile(null);
      setRenameFileError(null);
      setRenameNewFile(null);
      toasterService.success('Successfully renamed file.');
    }).catch(err => {
      toasterService.error('There was an error trying to rename the file. Please try again.');
    });
  };
  if (loading) return <Loader overlay />;
  return <Scoped css={styles}>
      <table className="file-browser">
        <thead>
          <tr>
            <th>File</th>
            <th style={{
            width: '80px'
          }}>Size</th>
            <th style={{
            width: '120px'
          }}>Last Modified</th>
            <th style={{
            width: '140px'
          }}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {files.filter(file => !!file.visible).map(file => <tr key={file.Key} className={classnames({
          selected: !!file.selected
        })} onClick={() => selectFile(file)}>
                <td style={{
            paddingLeft: file.paddingLeft + 'px'
          }}>
                  {renderIcon(file)}
                  {file.name}
                </td>
                <td>{!file.isFolder && fileSize(file.Size)}</td>
                <td>{!file.isFolder && moment(file.LastModified).fromNow()}</td>
                <td className="text-right">
                  {file.isFolder && hasPermission('files.add_files') && <>
                      <Button icon="fa-regular-folder-plus" actionType="flat" onClick={e => openNewFolder(e, file.shortKey)} />
                      <Button icon="fa-regular-upload" actionType="flat" onClick={e => openModal(e, file.shortKey)} />
                    </>}
                  {!file.isFolder && <Button tag="a" target="_blank" href={file.url} icon="fa-regular-download" actionType="flat" />}
                  {hasPermission('files.change_files') && <Button icon="fa-regular-edit" actionType="flat" onClick={e => openRenameFile(e, file)} />}
                  {hasPermission('files.delete_files') && <Button icon="fa-regular-trash" actionType="flat" onClick={e => openDeleteFile(e, file)} />}
                </td>
              </tr>)}

          {!files.length && <tr className="no-hover">
              <td colSpan="4">
                <i>There are currently no files here.</i>
              </td>
            </tr>}
        </tbody>
      </table>
      {hasPermission('files.add_files') && <div className="d-flex top-group">
          <Button className="mr-3" onClick={e => openNewFolder(e, '')}>
            <Icon name="fa-solid-folder-plus" />
            New top-level folder
          </Button>
          <Button onClick={openModal}>
            <Icon name="fa-solid-upload" />
            Upload top-level file
          </Button>
        </div>}
      {uppy && <DashboardModal uppy={uppy} closeModalOnClickOutside open={true} onRequestClose={() => {
      // TODO it might be better to find a way to update the files after they're uploaded and not when the modal closes
      loadData(files);
      setUppy(null);
    }} />}

      <Modal open={newFolderTarget !== null} onClose={() => setNewFolderTarget(null)} allowBackdropClick title="Create New Folder">
        <div className="modal__body">
          <p>Enter the name of the new folder.</p>
          <div className="form-group">
            <input className="form-control" name="newFolder" placeholder="New Folder Name" onChange={e => updateNewFolder(e.target.value)} value={newFolder || ''} autoFocus />
            {newFolderError && <p className="form-error mt-2">{newFolderError}</p>}
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setNewFolderTarget(null)}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => createFolder()}>
            Submit
          </Button>
        </div>
      </Modal>

      <Modal open={!!deleteFile} onClose={() => setDeleteFile(null)} allowBackdropClick title="Delete File">
        <div className="modal__body">
          <p>{deleteFileMessage} This cannot be undone</p>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setDeleteFile(null)}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => confirmDeleteFile()}>
            Confirm
          </Button>
        </div>
      </Modal>

      <Modal open={!!renameFile} onClose={() => setRenameFile(null)} allowBackdropClick title="Rename File">
        <div className="modal__body">
          <p>Enter the new name for the {!!renameFile && renameFile.isFolder ? 'folder' : 'file'}.</p>
          <div className="form-group">
            <input className="form-control" name="renameNewFile" placeholder="New Name" onChange={e => updateRenameFile(e.target.value)} value={renameNewFile || ''} autoFocus />
            {renameFileError && <p className="form-error mt-2">{renameFileError}</p>}
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setRenameFile(null)}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => confirmRenameFile()}>
            Confirm
          </Button>
        </div>
      </Modal>
    </Scoped>;
}