import React, { Component } from 'react';
import { func, object, shape, string, number } from 'prop-types';
import { UserStateContext } from 'context/user-state-context';
import { getEmailTemplates } from 'shared/common.api';
import { DynamicTypeAhead } from 'components';
export const notificationOptions = {
  custom: 'Custom List',
  notification_list: 'Notification List'
};
export class ActionNotifications extends Component {
  static contextType = UserStateContext;
  constructor(props) {
    super(props);
  }
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      method: string,
      message: string,
      from: string,
      to: string,
      message_type: string,
      email_template: string,
      notification_option: string,
      alert_level: string
    })
  };
  onMessageChange = e => {
    this.props.update({
      message: e.target.value
    });
  };
  onMethodChange = e => {
    this.props.update({
      method: e.target.value
    });
  };
  onFromChange = e => {
    this.props.update({
      from: e.target.value
    });
  };
  onToChange = e => {
    this.props.update({
      to: e.target.value
    });
  };
  changeNotificationOption = e => {
    /*
    Set the default alert level if the user switches over to the new feature
    */
    const newValue = e.target.value;
    if (newValue === 'custom') {
      this.props.update({
        alert_level: null
      });
    } else if (newValue === 'notification_list') {
      this.props.update({
        alert_level: 'low'
      });
    }
    this.props.update({
      notification_option: e.target.value
    });
  };
  onAlertLevelChange = e => {
    this.props.update({
      alert_level: e.target.value
    });
  };
  onMessageTypeChange = e => {
    this.props.update({
      message_type: e.target.value
    });
  };
  insertData = e => {
    const {
      message
    } = this.props.data;
    this.props.update({
      message: `${message}{{${e.target.value}}}`
    });
  };
  getId = (groupId, fieldId) => {
    return `${groupId}.${fieldId.replace(/__/g, '.')}`;
  };
  render() {
    const {
      message,
      method,
      from,
      to,
      message_type = 'custom',
      email_template,
      notification_option,
      alert_level
    } = this.props.data;
    const fields = this.context.companyFields;
    return <div>
        <div className="form-group">
          <label>Send By</label>
          <select className="form-control" onChange={this.onMethodChange} value={method || ''}>
            <option value="sms">SMS</option>
            <option value="email">Email</option>
          </select>
        </div>
        {method === 'email' && message_type === 'custom' && <div className="form-group">
            <label>Send From</label>
            <p>Enter a single email address here.</p>
            <input type="text" className="form-control" onChange={this.onFromChange} value={from || ''} />
          </div>}
        <div className="form-group">
          <label>Send To</label>

          {/** TODO gauruntee backwards compatability! */}
          <select className="form-control" style={{
          height: '2rem'
        }} onChange={this.changeNotificationOption} value={notification_option}>
            {Object.keys(notificationOptions).map(key => {
            if (key === 'notification_list' && !this.context.hasIntegration('notification_list')) {
              return null;
            } else {
              return <option key={key} value={key}>
                    {notificationOptions[key]}
                  </option>;
            }
          })}
          </select>
        </div>

        {notification_option === 'custom' && <div className="form-group">
            <p>Enter as many as you want separated by a semi-colon.</p>
            <input type="text" className="form-control" onChange={this.onToChange} value={to || ''} />
          </div>}

        {notification_option === 'notification_list' && <div className="form-group">
            <p>Select alert level contacts from the Notification List</p>
            <select className="form-control" onChange={this.onAlertLevelChange} value={alert_level}>
              <option key="low" value="low">
                Low
              </option>
              <option key="medium" value="medium">
                Medium
              </option>
              <option key="high" value="high">
                High
              </option>
            </select>
          </div>}

        {method === 'email' && <div className="form-group">
            <label>Message Type</label>
            <select className="form-control" value={message_type} onChange={this.onMessageTypeChange}>
              <option value="custom">Custom Message</option>
              <option value="template">Template</option>
            </select>
          </div>}

        {method === 'email' && message_type === 'template' && <div className="form-group">
            <label>Template</label>
            <DynamicTypeAhead getItems={getEmailTemplates} getItemsFilters={{
          company: this.context.asCompany.id,
          archived: false,
          published: true
        }} placeholder="Choose Published Email Template" displayProperty="name" keyProperty="id" value={email_template} onChange={val => this.props.update({
          email_template: val ? val.id : null
        })} />
          </div>}

        {(method !== 'email' || message_type !== 'template') && <div className="form-group">
            <div className="d-flex">
              <div className="flex-grow">
                <label>Message</label>
              </div>
              <div>
                <select className="form-control" style={{
              height: '2rem'
            }} onChange={this.insertData} value={''}>
                  <option>Insert Dynamic Data</option>
                  {fields.map(field => <optgroup key={field.id} label={field.name}>
                      {field.fields.map(subfield => <option key={subfield.id} value={this.getId(field.id, subfield.id)}>
                          {subfield.name}
                        </option>)}
                    </optgroup>)}
                </select>
              </div>
            </div>
            <textarea cols="3" className="form-control" style={{
          height: '100%'
        }} onChange={this.onMessageChange} value={message || ''}></textarea>
          </div>}
      </div>;
  }
}