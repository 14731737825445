import React from 'react';
import moment from 'moment-timezone';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
import { Box, Button, Checkbox, ClickAwayListener, Divider, IconButton, Paper, Popper, Rating, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { BroadcastsIcon, Calendar24HoursIcon, Calendar30DaysFilledIcon, Calendar30DaysIcon, Calendar365DaysIcon, Calendar7DaysIcon, CalendarAllIcon, CalendarDateRangeIcon, CarrotDownIcon, CarrotUpIcon, CloseIcon, DialogIcon, DoubleDashIcon, JourneyFilledIcon, JourneyIcon, LocationOnIcon, MailEnvelopeFilledIcon, MailEnvelopeIcon, MailboxFilledIcon, MailboxIcon, OpenInNewWindowIcon, ReviewFilledIcon, ReviewIcon, RightCaretIcon, SMSIcon, SegmentIcon, StatusDotIcon, SurveysIcon, SurveyResponsesIcon, TemplatesIcon, TransactionFilledIcon, TransactionIcon, SMSOutgoingIcon, SMSIncomingIcon, SMSOutgoingFilledIcon, SMSIncomingFilledIcon } from 'components/mui';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { LicenseInfo } from '@mui/x-license-pro';
import CircularProgress from '@mui/material/CircularProgress';
import { AntSwitch } from 'components/mui/switch/ant-switch.component';
import { useEffect } from 'react';
import { getCustomerScheduleTypes, getEmailLog, getJourneyTimeline, getMailBoxDetails, getReviewDetails, getSMSLog, getSurveyTimeline } from 'shared/common.api';
import { history } from '../../../root.component';
LicenseInfo.setLicenseKey(MUIX_API);
import { withStyles } from '@mui/styles';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
import numeral from 'numeral';
import { UserStateContext } from 'context/user-state-context';
import utils from 'shared/utils';
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white'
  }
})(Tooltip);
export function TimelineComponent(props) {
  // These props are a lazy way to pass data around. We need to optimize this later.
  const {
    customerTimeline,
    customerTimelineSummary,
    goToSchedule,
    goToTransaction,
    customerDetails,
    filters,
    updateFilters,
    selectedTimelineView,
    setSelectedTimelineView,
    isLoading,
    goToMessages
  } = props;
  const [datePickerMenuOpen, setDatePickeMenuOpen] = React.useState(false);
  const [datePickerMenuAnchorEl, setDatePickerMenuAnchorEl] = React.useState(null);
  const [datePickerSelection, setDatePickerSelection] = React.useState('all');
  const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState(false);
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = React.useState(null);
  const [dateRangeCalendar, setDateRangeCalendar] = React.useState(() => {
    const before = filters['date_before'];
    const after = filters['date_after'];
    if (before) {
      return [moment(after), moment(before)];
    }
    return [];
  });
  const [dateRangeCalendarPending, setDateRangeCalendarPending] = React.useState([]);
  const [typePickerOpen, setTypePickerOpen] = React.useState(false);
  const [typePickerAnchorEl, setTypePickerAnchorEl] = React.useState(null);
  const [typeSelectAll, setTypeSelectAll] = React.useState(false);
  const [typeHover, setTypeHover] = React.useState(null);
  const [expandedCells, setExpandedCells] = React.useState([]);
  const [fetchedApiData, setFetchedApiData] = React.useState([]);
  const {
    closeCustomerDrawer
  } = React.useContext(CustomerDrawerContext);
  const {
    asCompany
  } = React.useContext(UserStateContext);
  const [mailerTooltipOpen, setMailerTooltipOpen] = React.useState(null);
  const [mailerTooltipAnchorEl, setMailerTooltipAnchorEl] = React.useState(null);
  const [eventPickerOpen, setEventPickerOpen] = React.useState(false);
  const [eventPickerAnchorEl, setEventPickerAnchorEl] = React.useState(null);
  const [eventSelectAll, setEventSelectAll] = React.useState(false);
  const [eventHover, setEventHover] = React.useState(null);
  const [typeTypes, setTypeTypes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const datePickerTypes = [{
    key: 'all',
    name: 'All',
    icon: <CalendarAllIcon />
  }, {
    key: '24hours',
    name: 'Last 24 Hours',
    icon: <Calendar24HoursIcon />
  }, {
    key: '7days',
    name: 'Last 7 Days',
    icon: <Calendar7DaysIcon />
  }, {
    key: '30days',
    name: 'Last 30 Days',
    icon: <Calendar30DaysIcon />
  }, {
    key: '365days',
    name: 'Last year',
    icon: <Calendar365DaysIcon />
  }];
  useEffect(() => {
    const newTypes = [{
      key: 'transaction',
      name: 'Transaction',
      icon: <TransactionIcon />
    }, {
      key: 'schedule',
      name: 'Schedule',
      icon: <Calendar30DaysIcon />,
      types: []
    }, {
      key: 'email',
      name: 'Email',
      icon: <MailEnvelopeIcon />,
      types: [{
        key: 'delivered',
        name: 'Delivered'
      }, {
        key: 'open',
        name: 'Opened'
      }, {
        key: 'click',
        name: 'Clicked'
      }, {
        key: 'bounce',
        name: 'Bounced'
      }, {
        key: 'deferred',
        name: 'Deferred'
      }, {
        key: 'dropped',
        name: 'Dropped'
      }, {
        key: 'spamreport',
        name: 'Reported as Spam'
      }, {
        key: 'unsubscribed',
        name: 'Unsubscribed'
      }, {
        key: 'processed',
        name: 'Processed'
      }]
    }, {
      key: 'text',
      name: 'Text Message',
      icon: <SMSIcon />,
      types: [{
        key: 'delivered',
        name: 'Delivered'
      }, {
        key: 'undelivered',
        name: 'Undelivered'
      }, {
        key: 'failed',
        name: 'Failed'
      }, {
        key: 'queued',
        name: 'Queued'
      }]
    }, {
      key: 'mail',
      name: 'Mail',
      icon: <MailboxIcon />,
      types: [{
        key: 'shipped',
        name: 'Shipped'
      }, {
        key: 'invalid_address',
        name: 'Invalid Address'
      }, {
        key: 'bad_address',
        name: 'Bad Address'
      }, {
        key: 'queued',
        name: 'Queued'
      }]
    }, {
      key: 'journey',
      name: 'Journey',
      icon: <JourneyIcon />
    }, {
      key: 'review',
      name: 'Review',
      icon: <ReviewIcon />,
      types: [{
        key: 'replied',
        name: 'Replied'
      }, {
        key: 'noReply',
        name: 'Awaiting Reply'
      }]
    }, {
      key: 'survey',
      name: 'Survey',
      icon: <SurveyResponsesIcon />
    }];
    //get all schedule events
    getCustomerScheduleTypes(props.customerID).then(({
      data
    }) => {
      const scheduletypes = [];
      data.filter(s => s.type != '').map(type => {
        if (!scheduletypes.find(d => d.key == type.type)) {
          scheduletypes.push({
            key: type.type,
            name: type.type
          });
        }
      });
      newTypes.find(d => d.key == 'schedule').types = scheduletypes;
      setTypeTypes(newTypes);
    });
  }, []);
  useEffect(() => {
    const after = filters['date_after'];
    const before = filters['date_before'];
    const days = moment().diff(moment(after), 'days');
    if (!after && !before && days == 0) {
      return setDatePickerSelection('all');
    }
    if (days < 5 && after) {
      return setDatePickerSelection('24hours');
    }
    if (days < 25 && after) {
      return setDatePickerSelection('7days');
    }
    if (days < 300 && after) {
      return setDatePickerSelection('30days');
    }
    if (days < 400 && after) {
      return setDatePickerSelection('365days');
    }
    return setDatePickerSelection('all');
  }, [filters]);
  const handleRowClick = ref => {
    if (expandedCells.some(c => c == ref.id)) {
      //removing
      const cells = expandedCells.filter(items => items != ref.id);
      setExpandedCells(cells);
    } else {
      // setExpandedCells((expandedCells) => [...expandedCells, ref.id]);
      //forcing only one cell to be expanded
      setExpandedCells([ref.id]);
      if (!fetchedApiData.some(d => d.id == ref.id)) {
        const data = ref;
        if (ref.type == 'Transaction') {
          if (ref.data.possession) {
            const getVehicle = customerDetails.vehicles.filter(v => v.possession === ref.data.possession);
            data.vehicle = getVehicle[0];
          }
          setFetchedApiData(fetchedApiData => [...fetchedApiData, data]);
        }
        if (ref.type == 'Email') {
          getEmailLog(ref.sg_message_id).then(({
            data
          }) => {
            data.type = ref.type;
            setFetchedApiData(fetchedApiData => [...fetchedApiData, data]);
          });
        }
        if (ref.type == 'SMS') {
          getSMSLog(ref.id, {
            company: asCompany.id
          }).then(({
            data
          }) => {
            data.type = ref.type;
            if (ref.data?.events?.length == 0 || ref.data.events.length == 1 && ref.data.events[0].event == 'queued') {
              data.events = [{
                event: data.direction == 'outgoing' ? 'delivered' : 'received',
                created_when: ref?.data?.events[0]?.created_when || ref.created_when
              }];
            } else {
              data.events = ref.data.events;
            }
            setFetchedApiData(fetchedApiData => [...fetchedApiData, data]);
          });
        }
        if (ref.type == 'Mailer') {
          getMailBoxDetails(ref.id).then(({
            data
          }) => {
            data.type = ref.type;
            setFetchedApiData(fetchedApiData => [...fetchedApiData, data]);
          });
        }
        if (ref.type == 'Review') {
          getReviewDetails(ref.id).then(({
            data
          }) => {
            data.type = ref.type;
            data.reply_when = ref.detail1;
            data.review_when = ref.created_when;
            data.comment = ref.comment;
            setFetchedApiData(fetchedApiData => [...fetchedApiData, data]);
          });
        }
        if (ref.type == 'Schedule') {
          const data = ref.data;
          data._type = ref.data.type;
          data.type = 'Schedule';
          setFetchedApiData(fetchedApiData => [...fetchedApiData, data]);
        }
        if (ref.type == 'Journey') {
          getJourneyTimeline({
            session_id: ref.customer_session_id,
            customer: props.customerID
          }).then(({
            data
          }) => {
            const events = {
              id: ref.id,
              type: 'Journey',
              events: data.results
            };
            data.type = 'Journey';
            setFetchedApiData(fetchedApiData => [...fetchedApiData, events]);
          });
        }
        if (ref.type == 'Survey') {
          getSurveyTimeline({
            invite: ref.id
          }).then(({
            data
          }) => {
            const survey = {
              id: ref.id,
              type: ref.type,
              completed: data?.survey?.completed_when,
              first: data?.survey?.first_answered_when,
              firstQuestion: data?.answer?.q_text,
              firstAnswer: data?.answer?.answer,
              overallScore: data?.survey?.overall_score
            };
            setFetchedApiData(fetchedApiData => [...fetchedApiData, survey]);
          });
        }
      }
    }
  };
  const convertNormalizedScore = normalizedScore => {
    if (normalizedScore == 0) return 1;
    if (normalizedScore > 0 && normalizedScore <= 0.25) return 2;
    if (normalizedScore > 0.25 && normalizedScore <= 0.5) return 3;
    if (normalizedScore > 0.5 && normalizedScore <= 0.75) return 4;
    if (normalizedScore > 0.75) return 5;
  };
  const handleDatePickerClose = () => {
    if (!dateRangeMenuOpen) {
      setDatePickeMenuOpen(false);
      setDatePickerMenuAnchorEl(null);
    }
  };
  const handleDatePickerClick = (e, date) => {
    setDatePickeMenuOpen(true);
    setDatePickerMenuAnchorEl(e.currentTarget);
  };
  const handleDatePickerMenuClick = key => {
    setDateRangeCalendar([]);
    //setDatePickerSelection(key);
    const new_filters = {
      ...filters
    };
    delete new_filters['date_after'];
    delete new_filters['date_before'];
    if (key == '24hours') {
      new_filters['date_after'] = moment().subtract(24, 'hours').startOf('day').toISOString();
    }
    if (key == '7days') {
      new_filters['date_after'] = moment().subtract(7, 'days').startOf('day').toISOString();
    }
    if (key == '30days') {
      new_filters['date_after'] = moment().subtract(30, 'days').startOf('day').toISOString();
    }
    if (key == '365days') {
      new_filters['date_after'] = moment().subtract(365, 'days').startOf('day').toISOString();
    }
    updateFilters(new_filters);
    setPage(0);
    setDatePickeMenuOpen(false);
    setDatePickerMenuAnchorEl(null);
    setDateRangeAnchorEl(null);
    setDateRangeMenuOpen(false);
  };
  const handleDateRangeClose = () => {
    setDateRangeAnchorEl(null);
    setDateRangeMenuOpen(false);
  };
  const handleDatePickerCalendarSubmit = () => {
    handleDateRangeClose();
    setDatePickeMenuOpen(false);
    setDatePickerMenuAnchorEl(null);
    setDateRangeCalendar(dateRangeCalendarPending);
    const new_filters = {
      ...filters
    };
    if (dateRangeCalendarPending.length < 1) {
      delete new_filters['date_before'];
      delete new_filters['date_after'];
    } else if (dateRangeCalendarPending[1] == null) {
      new_filters['date_after'] = dateRangeCalendarPending[0].toISOString();
      new_filters['date_before'] = dateRangeCalendarPending[0].toISOString();
    } else {
      new_filters['date_after'] = dateRangeCalendarPending[0].toISOString();
      new_filters['date_before'] = dateRangeCalendarPending[1].toISOString();
    }
    updateFilters(new_filters);
    setDateRangeCalendarPending([]);
  };
  const handleTypepickerClose = () => {
    setTypePickerOpen(false);
    setTypePickerAnchorEl(null);
  };
  const handleTypePickerClick = e => {
    setTypePickerAnchorEl(e.currentTarget);
    setTypePickerOpen(true);
  };
  const handleTypePickerMenuClick = type => {
    if (filters['type']?.some(t => t == type)) {
      //removing
      const t = filters['type']?.filter(items => items != type);
      handleSelectedTypes(t);
      setTypeSelectAll(false);
    } else {
      if (filters['type']?.length + 1 === typeTypes.length) {
        setTypeSelectAll(true);
      }
      if (!filters['type']) {
        handleSelectedTypes([type]);
      } else {
        handleSelectedTypes([...filters['type'], type]);
      }
    }
    setPage(0);
  };
  const handleTypeSelectAll = () => {
    if (typeSelectAll) {
      handleSelectedTypes([]);
    } else {
      handleSelectedTypes(typeTypes.map(t => t.key));
    }
    setTypeSelectAll(!typeSelectAll);
    setPage(0);
  };
  const handleSelectedTypes = newTypes => {
    const new_filters = {
      ...filters
    };
    if (newTypes.length == 0) {
      delete new_filters['type'];
    }
    new_filters['type'] = newTypes;
    updateFilters(new_filters);
    setPage(0);
  };
  const handleSelectedEvent = newTypes => {
    const new_filters = {
      ...filters
    };
    if (newTypes.length == 0) {
      delete new_filters['event'];
    }
    new_filters['event'] = newTypes;
    updateFilters(new_filters);
    setPage(0);
  };
  const handleEventPickerClose = () => {
    setEventPickerOpen(false);
    setEventPickerAnchorEl(null);
  };
  const handleEventPickerClick = e => {
    setEventPickerAnchorEl(e.currentTarget);
    setEventPickerOpen(true);
  };
  const handleEventPickerMenuClick = type => {
    if (filters['event']?.some(t => t == type)) {
      //removing
      const t = filters['event']?.filter(items => items != type);
      handleSelectedEvent(t);
      setEventSelectAll(false);
    } else {
      let l = 0;
      typeTypes.map(t => {
        if (t.types) {
          l += t.types.length;
        }
      });
      if (filters['event']?.length + 1 === l) {
        setEventSelectAll(true);
      }
      if (!filters['event']) {
        handleSelectedEvent([type]);
      } else {
        handleSelectedEvent([...filters['event'], type]);
      }
    }
    setPage(0);
  };
  const handleEventSelectAll = () => {
    if (eventSelectAll) {
      handleSelectedEvent([]);
    } else {
      const everything = [];
      typeTypes.map(t => {
        if (t.types) {
          t.types.map(e => {
            everything.push(t.key + '-' + e.key);
          });
        }
      });
      handleSelectedEvent(everything);
    }
    setEventSelectAll(!eventSelectAll);
  };
  const getEmailColor = tt => {
    if (tt == 'delivered') {
      return ['#3898D9', 'Email - Delivered'];
    }
    if (tt == 'open') {
      return ['#3EB87B', 'Email - Opened'];
    }
    if (tt == 'click') {
      return ['#3EB87B', 'Email - Clicked'];
    }
    if (tt == 'bounce') {
      return ['#EF3C34', 'Email - Bounced'];
    }
    if (tt == 'deferred') {
      return ['#EF3C34', 'Email - Deferred'];
    }
    if (tt == 'dropped') {
      return ['#EF3C34', 'Email - Dropped'];
    }
    if (tt == 'spamreport') {
      return ['#EF3C34', 'Email - Reported as Spam'];
    }
    if (tt == 'unsubscribed') {
      return ['#EF3C34', 'Email - Unsubscribed'];
    }
    if (tt == 'processed') {
      return ['#616161', 'Email - Processed'];
    }
    return ['#616161', tt ? 'Email - ' + tt?.charAt(0).toUpperCase() + tt?.slice(1) : 'Email'];
  };
  const getSMSColor = (tt, direction) => {
    if (direction == 'incoming') {
      return ['#3EB87B', 'Text Message - Received'];
    }
    // if (direction == 'outgoing') {
    //   return ['#3EB87B', 'Text Message - Outgoing'];
    // }
    if (tt == 'delivered') {
      return ['#3EB87B', 'Text Message - Delivered'];
    }
    if (tt == 'undelivered') {
      return ['#EF3C34', 'Text Message - Undelivered'];
    }
    if (tt == 'failed') {
      return ['#EF3C34', 'Text Message - Failed'];
    }
    if (tt == 'queued') {
      return ['#616161', 'Text Message - Processed'];
    }
    return ['#616161', tt ? 'Text Message - ' + tt?.charAt(0).toUpperCase() + tt?.slice(1) : 'Text Message'];
  };
  const getMailerColor = tt => {
    if (tt == 'shipped') {
      return ['#3898D9', 'Mailer - Shipped'];
    }
    if (tt == 'invalid_address') {
      return ['#EF3C34', 'Mailer - Invalid Address'];
    }
    if (tt == 'bad_address') {
      return ['#EF3C34', 'Mailer - Bad Address'];
    }
    if (tt == 'queued') {
      return ['#616161', 'Mailer - Queued'];
    }
    return ['#616161', tt ? 'Mailer - ' + tt?.charAt(0).toUpperCase() + tt?.slice(1) : 'Mailer'];
  };
  const getReviewColor = tt => {
    if (!!tt) {
      return ['#3898D9', 'Review - Replied'];
    }
    return ['#616161', 'Review'];
  };
  const getIcon = (ref, expanded) => {
    if (ref.type == 'Email') {
      const [color, title] = getEmailColor(ref.detail1);
      if (expanded) {
        return <Tooltip title={title}>
            <span>
              <MailEnvelopeFilledIcon fill={color} />
            </span>
          </Tooltip>;
      } else {
        return <Tooltip title={title}>
            <span>
              <MailEnvelopeIcon fill={color} />
            </span>
          </Tooltip>;
      }
    }
    if (ref.type == 'Transaction') {
      if (expanded) {
        return <Tooltip title="Transaction">
            <span>
              <TransactionFilledIcon fill="#616161" />
            </span>
          </Tooltip>;
      } else {
        return <Tooltip title="Transaction">
            <span>
              <TransactionIcon fill="#616161" />
            </span>
          </Tooltip>;
      }
    }
    if (ref.type == 'SMS') {
      const [color, title] = getSMSColor(ref.detail1, ref?.data?.direction);
      if (expanded) {
        if (ref.data.direction == 'outgoing') {
          return <Tooltip title={title}>
              <span>
                <SMSOutgoingFilledIcon fill={color} />
              </span>
            </Tooltip>;
        }
        return <Tooltip title={title}>
            <span>
              <SMSIncomingFilledIcon fill={color} />
            </span>
          </Tooltip>;
      } else {
        if (ref?.data?.direction == 'outgoing') {
          return <Tooltip title={title}>
              <span>
                <SMSOutgoingIcon fill={color} />
              </span>
            </Tooltip>;
        }
        return <Tooltip title={title}>
            <span>
              <SMSIncomingIcon fill={color} />
            </span>
          </Tooltip>;
      }
    }
    if (ref.type == 'Mailer') {
      const [color, title] = getMailerColor(ref.detail1);
      if (expanded) {
        return <Tooltip title={title}>
            <span>
              <MailboxFilledIcon fill={color} />
            </span>
          </Tooltip>;
      } else {
        return <Tooltip title={title}>
            <span>
              <MailboxIcon fill={color} />
            </span>
          </Tooltip>;
      }
    }
    if (ref.type == 'Review') {
      const [color, title] = getReviewColor(ref.detail1);
      if (expanded) {
        return <Tooltip title={title}>
            <span>
              <ReviewFilledIcon fill={color} />
            </span>
          </Tooltip>;
      } else {
        return <Tooltip title={title}>
            <span>
              <ReviewIcon fill={color} />
            </span>
          </Tooltip>;
      }
    }
    if (ref.type == 'Schedule') {
      if (expanded) {
        return <Tooltip title="Schedule">
            <span>
              <Calendar30DaysFilledIcon fill="#616161" />
            </span>
          </Tooltip>;
      } else {
        return <Tooltip title="Schedule">
            <span>
              <Calendar30DaysIcon fill="#616161" />
            </span>
          </Tooltip>;
      }
    }
    if (ref.type == 'Journey') {
      if (expanded) {
        return <Tooltip title="Journey">
            <span>
              <JourneyFilledIcon fill="#616161" />
            </span>
          </Tooltip>;
      } else {
        return <Tooltip title="Journey">
            <span>
              <JourneyIcon fill="#616161" />
            </span>
          </Tooltip>;
      }
    }
    if (ref.type == 'Survey') {
      return <Tooltip title="Survey">
          <span>
            <SurveyResponsesIcon fill="#616161" />
          </span>
        </Tooltip>;
    }
  };
  const isExpandedCell = id => {
    return !!expandedCells.find(c => c == id);
  };
  const hasFetchedApiData = id => {
    return !!fetchedApiData.find(d => d.id == id);
  };
  const makeOverflowTooltip = (content, length, bold) => {
    if (content) {
      return <Tooltip title={content.length > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {content.length > length ? <>{('' + content).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <>No details</>;
  };
  const handleMailerTooltipHoverOpen = (e, data) => {
    setMailerTooltipOpen(data);
    setMailerTooltipAnchorEl(e.currentTarget);
  };
  const handleMailerTooltipHoverClose = () => {
    setMailerTooltipOpen(null);
    setMailerTooltipAnchorEl(null);
  };
  const formatTile = tile => {
    let color = '#616161';
    if (tile.node_type == 'trigger') {
      color = '#3EB87B';
    } else if (tile.node_type == 'action') {
      if (tile.node_name == 'action_time_delay' || tile.node_name == 'action_await_sms_reply' || tile.node_name == 'action_rate_limit') {
        color = '#FFB82B';
      } else {
        color = '#E77A16';
      }
    } else if (tile.node_type == 'condition') {
      color = '#FFB82B';
    }
    let formatedTileName = '';
    if (tile?.tile_name) {
      if (utils.journeyTileLookup(tile.tile_name)) {
        formatedTileName = utils.journeyTileLookup(tile.tile_name);
      } else {
        formatedTileName = tile.tile_name.replace(/_/g, ' ') || '';
      }
    }
    return <Stack direction="row" sx={{
      alignItems: 'center',
      display: 'flex'
    }}>
        <DialogIcon fill={color} />
        <Typography sx={{
        textTransform: 'capitalize'
      }}>{formatedTileName}</Typography>
      </Stack>;
  };
  const formatEvent = event => {
    let formatedEvent = '';
    if (!event) {
      return <DoubleDashIcon />;
    }
    if (event == '*' || event == 'continue') {
      formatedEvent = 'Continue';
    } else {
      formatedEvent = event.replace(/_/g, ' ') || '';
    }
    return <Typography sx={{
      textTransform: 'capitalize'
    }}>{formatedEvent} </Typography>;
  };
  const getFetchedApiData = id => {
    const data = fetchedApiData.find(d => d.id == id);
    if (data.type == 'Transaction') {
      return <Stack>
          <Box sx={{
          px: '12px'
        }}>
            <Table>
              <colgroup>
                <col style={{
                width: '10%'
              }} />
                <col style={{
                width: '30%'
              }} />
                <col style={{
                width: '70%'
              }} />
              </colgroup>
              <TableBody>
                {data.vehicle && (data.vehicle.year || data.vehicle.make || data.vehicle.model) && <TableRow>
                    <TableCell />
                    <TableCell>
                      <Typography variant="tableHeader"> Vehicle: </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {makeOverflowTooltip((data.vehicle.year + ' ' || '') + (data.vehicle.make + ' ' || '') + (data.vehicle.model || ''), 42)}
                      </Typography>
                    </TableCell>
                  </TableRow>}

                {data.vehicle && data.vehicle.vin && <TableRow>
                    <TableCell />
                    <TableCell>
                      <Typography variant="tableHeader"> VIN: </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography> {makeOverflowTooltip(data.vehicle.vin, 42)}</Typography>
                    </TableCell>
                  </TableRow>}
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader"> Invoice Number: </Typography>
                  </TableCell>
                  <TableCell>
                    {data.data.entity_number ? <Typography> {makeOverflowTooltip(data.data.entity_number, 42)}</Typography> : <DoubleDashIcon />}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader"> Location: </Typography>
                  </TableCell>
                  <TableCell>
                    {data.data.location_friendly_name ? <Typography> {makeOverflowTooltip(data.data.location_friendly_name, 42)}</Typography> : <DoubleDashIcon />}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader"> Total: </Typography>
                  </TableCell>
                  <TableCell>
                    {data.data.total ? <Typography> {makeOverflowTooltip(numeral(data.data.total).format('$0,0.00'), 42)}</Typography> : <DoubleDashIcon />}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Stack>;
    }
    if (data.type == 'Email') {
      return <Stack>
          <Box sx={{
          px: '12px'
        }}>
            <Table sx={{
            borderRadius: '8px',
            background: '#fff'
          }}>
              <colgroup>
                <col style={{
                width: '10%'
              }} />
                <col style={{
                width: '30%'
              }} />
                <col style={{
                width: '60%'
              }} />
                <col style={{
                width: '10%'
              }} />
              </colgroup>
              <TableBody>
                {!!data.revision && <TableRow className="row" sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                closeCustomerDrawer();
                history.push(`/templates/edit/email/${data.template_id}`);
              }}>
                    <TableCell sx={{
                  pl: '12px'
                }}>
                      <CustomTooltip placement="right" title={<object style={{
                    maxHeight: '50vh',
                    maxWidth: '50vw'
                  }} data={data.thumbnail_url} type="image/jpeg" />}>
                        <object style={{
                      height: '25px',
                      width: '25px'
                    }} data={data.thumbnail_url} type="image/jpeg">
                          <TemplatesIcon />
                        </object>
                      </CustomTooltip>
                    </TableCell>
                    <TableCell>
                      <Typography variant="tableHeader">Email Template: </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography> {makeOverflowTooltip(data.template_name, 42)}</Typography>
                    </TableCell>
                    <TableCell className="centered icons__remove">
                      <Tooltip title="View Template">
                        <IconButton>
                          <OpenInNewWindowIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>}

                {!!data.journey_customer_node && <TableRow className="row" sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                closeCustomerDrawer();
                history.push(`/customer-journeys/${data.journey_id}`);
              }}>
                    <TableCell>
                      <JourneyIcon />
                    </TableCell>
                    <TableCell>
                      <Typography variant="tableHeader">Journey: </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography> {makeOverflowTooltip(data.journey_name, 42)}</Typography>
                    </TableCell>
                    <TableCell className="centered icons__remove">
                      <Tooltip title="View Journey">
                        <IconButton>
                          <OpenInNewWindowIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>}
                {!!data.broadcast && <TableRow className="row" sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                closeCustomerDrawer();
                history.push(`/email-broadcasts?id=${data.broadcast}`);
              }}>
                    <TableCell>
                      <BroadcastsIcon />
                    </TableCell>
                    <TableCell>
                      <Typography variant="tableHeader">Broadcast: </Typography>
                    </TableCell>
                    <TableCell>
                      {makeOverflowTooltip(data.broadcast_segment_name + ' - ' + moment.utc(data.broadcast_schedule_when).local().format('MM/D/YY h:mm A'), 40)}
                    </TableCell>
                    <TableCell className="centered icons__remove">
                      <Tooltip title="View Broadcast">
                        <IconButton>
                          <OpenInNewWindowIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>

            <Table>
              <colgroup>
                <col style={{
                width: '7%'
              }} />
                <col style={{
                width: '30%'
              }} />
                <col style={{
                width: '73%'
              }} />
              </colgroup>
              <TableBody>
                {data.events?.map((event, index) => <TableRow key={event.id + index}>
                    <TableCell />
                    <TableCell> {moment.utc(event.created_when).local().format('MM/D/YY h:mm A')} </TableCell>
                    <TableCell>
                      {getStatusIcon(data, event.event)} {getFormatedName(data, event.event)}
                    </TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
          </Box>
        </Stack>;
    }
    if (data.type == 'SMS') {
      return <Stack>
          <Box sx={{
          px: '12px'
        }}>
            <Table sx={{
            borderRadius: '8px',
            background: '#fff'
          }}>
              <colgroup>
                <col style={{
                width: '10%'
              }} />
                <col style={{
                width: '30%'
              }} />
                <col style={{
                width: '60%'
              }} />
                <col style={{
                width: '10%'
              }} />
              </colgroup>
              <TableBody>
                {!!data.broadcast && <TableRow className="row" sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                closeCustomerDrawer();
                history.push(`/sms-broadcasts?id=${data.broadcast}`);
              }}>
                    <TableCell sx={{
                  pl: '12px'
                }}>
                      <BroadcastsIcon />
                    </TableCell>
                    <TableCell>
                      <Typography variant="tableHeader">Broadcast: </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {makeOverflowTooltip(data.broadcast_segment_name + ' - ' + moment.utc(data.broadcast_schedule_when).local().format('MM/D/YY h:mm A'), 38)}
                      </Typography>
                    </TableCell>
                    <TableCell className="centered icons__remove">
                      <Tooltip title="View Broadcast">
                        <IconButton>
                          <OpenInNewWindowIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>}

                {!!data.journey_id && <TableRow className="row" sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                closeCustomerDrawer();
                history.push(`/customer-journeys/${data.journey_id}`);
              }}>
                    <TableCell>
                      <JourneyIcon />
                    </TableCell>
                    <TableCell>
                      <Typography variant="tableHeader">Journey: </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography> {makeOverflowTooltip(data.journey_name, 42)}</Typography>
                    </TableCell>
                    <TableCell className="centered icons__remove">
                      <Tooltip title="View Journey">
                        <IconButton>
                          <OpenInNewWindowIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>

            <Table>
              <colgroup>
                <col style={{
                width: '11%'
              }} />
                <col style={{
                width: '31%'
              }} />
                <col style={{
                width: '58%'
              }} />
              </colgroup>
              <TableBody>
                {data.events.filter(e => e.event != 'queued').map((event, index) => <TableRow key={event.id + index}>
                      <TableCell />
                      <TableCell> {moment.utc(event.created_when).local().format('MM/D/YY h:mm A')} </TableCell>
                      <TableCell>
                        {getStatusIcon(data.type, event.event)} {getFormatedName(data, event.event)}
                      </TableCell>
                      {!data.journey_id && !data.broadcast && <TableCell>
                          <IconButton onClick={e => goToMessages()}>
                            <OpenInNewWindowIcon />
                          </IconButton>
                        </TableCell>}
                    </TableRow>)}
              </TableBody>
            </Table>
          </Box>
        </Stack>;
    }
    if (data.type == 'Mailer') {
      return <Stack>
          <Box sx={{
          px: '12px'
        }}>
            <Table sx={{
            borderRadius: '8px',
            background: '#fff'
          }}>
              <colgroup>
                <col style={{
                width: '10%'
              }} />
                <col style={{
                width: '30%'
              }} />
                <col style={{
                width: '60%'
              }} />
                <col style={{
                width: '10%'
              }} />
              </colgroup>
              <TableBody>
                <TableRow className="row" sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                closeCustomerDrawer();
                history.push(`/templates/print/${data.mail_template_id}`);
              }}>
                  <TableCell sx={{
                  pl: '12px'
                }}>
                    <Box onMouseEnter={e => {
                    handleMailerTooltipHoverOpen(e, data.mail_thumbnail_url);
                  }}>
                      <object style={{
                      height: '25px',
                      width: '25px'
                    }} data={data.mail_thumbnail_url.front} type="image/jpeg">
                        <TemplatesIcon />
                      </object>
                    </Box>
                  </TableCell>
                  <TableCell onMouseEnter={e => {
                  handleMailerTooltipHoverClose(e);
                }}>
                    <Typography variant="tableHeader">Mailer Info: </Typography>
                  </TableCell>
                  <TableCell onMouseEnter={e => {
                  handleMailerTooltipHoverClose(e);
                }}>
                    <Typography>
                      {makeOverflowTooltip(<Stack spacing={1} direction="row">
                          <Typography> Version: {data.mail_revision_number}</Typography>
                          <Divider sx={{
                        height: '16px',
                        color: '#1D252D'
                      }} orientation="vertical" />
                          <Typography>
                            Dimensions: {data.template_length_inches} x {data.template_width_inches}
                          </Typography>
                        </Stack>, 42)}
                    </Typography>
                  </TableCell>
                  <TableCell className="centered icons__remove">
                    <Tooltip title="View Template">
                      <IconButton>
                        <OpenInNewWindowIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>

                {!!data.journey_id && <TableRow onMouseEnter={e => {
                handleMailerTooltipHoverClose(e);
              }} className="row" sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: '#F3F3F4'
                }
              }} onClick={e => {
                closeCustomerDrawer();
                history.push(`/customer-journeys/${data.journey_id}`);
              }}>
                    <TableCell>
                      <JourneyIcon />
                    </TableCell>
                    <TableCell>
                      <Typography variant="tableHeader">Journey: </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography> {makeOverflowTooltip(data.journey_name, 42)}</Typography>
                    </TableCell>
                    <TableCell className="centered icons__remove">
                      <Tooltip title="View Journey">
                        <IconButton>
                          <OpenInNewWindowIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>

            <Table onMouseEnter={e => {
            handleMailerTooltipHoverClose(e);
          }}>
              <colgroup>
                <col style={{
                width: '7%'
              }} />
                <col style={{
                width: '30%'
              }} />
                <col style={{
                width: '73%'
              }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell> {moment.utc(data.mail_status_when).local().format('MM/D/YY h:mm A')} </TableCell>
                  <TableCell>
                    {getStatusIcon(data.type, data.mail_status)} {getFormatedName(data, data.mail_status)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Stack>;
    }
    if (data.type == 'Review') {
      return <Stack component="span" sx={{
        py: '16px',
        px: '30px'
      }} spacing="30px">
          <Box component="span" sx={{
          color: '#1D252D'
        }}>
            <Stack direction="row" spacing={2} component="span">
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography align="center" component={'span'} sx={{
                color: '#1D252D'
              }}>
                  <Calendar24HoursIcon /> {moment(data.review_when).format('MMM D, YYYY')}
                </Typography>
              </Box>
              <Typography align="center" component={'span'} sx={{
              color: '#1D252D'
            }}>
                <LocationOnIcon />
                {data.location ? <span>
                    {data.location.name} - {data.location.address1} {data.location.address2}, {data.location.city}, {data.location.state}{' '}
                    {data.location.zip_code}
                  </span> : <em>Unknown Location</em>}
              </Typography>
            </Stack>
          </Box>
          <Box component="span" sx={{
          color: '#1D252D'
        }}>
            {data.comment}
          </Box>
          <Divider sx={{
          px: 2
        }} />

          {data.reply_when && <Box sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '8px'
        }}>
              <Stack sx={{
            p: '16px'
          }} spacing={'10px'}>
                <Typography sx={{
              color: '#1D252D80',
              fontSize: 'small'
            }}>
                  {data.reply_when && moment(data.reply_when).format('dddd, MMMM D, YYYY | h:mm A')}
                </Typography>
                <Typography>{data.reply}</Typography>
              </Stack>
            </Box>}
        </Stack>;
    }
    if (data.type == 'Schedule') {
      return <Stack>
          <Box sx={{
          px: '12px'
        }}>
            <Table>
              <colgroup>
                <col style={{
                width: '10%'
              }} />
                <col style={{
                width: '30%'
              }} />
                <col style={{
                width: '70%'
              }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader"> Location: </Typography>
                  </TableCell>
                  <TableCell>
                    {data.location_friendly_name ? <Typography> {makeOverflowTooltip(data.location_friendly_name, 42)}</Typography> : <DoubleDashIcon />}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader"> Status: </Typography>
                  </TableCell>
                  <TableCell>
                    {data.status ? <Typography> {makeOverflowTooltip(data.status, 42)}</Typography> : <DoubleDashIcon />}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader"> Description: </Typography>
                  </TableCell>
                  <TableCell>
                    {data.description ? <Typography> {makeOverflowTooltip(data.description, 42)}</Typography> : <DoubleDashIcon />}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Stack>;
    }
    if (data.type == 'Journey') {
      return <Stack>
          <Box sx={{
          px: '12px'
        }}>
            <Table>
              <colgroup>
                <col style={{
                width: '25%'
              }} />
                <col style={{
                width: '42%'
              }} />
                <col style={{
                width: '33%'
              }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="tableHeader"> Event Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="tableHeader"> Tile Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="tableHeader"> Event</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.events.map(event => <TableRow key={event.id} sx={{
                cursor: 'pointer'
              }} onClick={e => {
                closeCustomerDrawer();
                history.push(`/customer-journeys/${data.id}`);
              }}>
                    <TableCell>
                      <Typography> {moment.utc(event.last_event.created_when).local().format('MM/D/YY h:mm A')} </Typography>
                    </TableCell>
                    <TableCell>{formatTile(event)}</TableCell>
                    <TableCell>{formatEvent(event.last_event.event)}</TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
          </Box>
        </Stack>;
    }
    if (data.type == 'Survey') {
      return <Stack>
          <Box sx={{
          px: '12px'
        }}>
            <Table>
              <colgroup>
                <col style={{
                width: '10%'
              }} />
                <col style={{
                width: '29%'
              }} />
                <col style={{
                width: '71%'
              }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader"> Survey Started:</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography> {moment.utc(data.first).local().format('MM/D/YY h:mm A')} </Typography>{' '}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader"> Survey Completed:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{data.completed ? moment.utc(data.completed).local().format('MM/D/YY h:mm A') : '--'}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader">First Survey Question:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography> {data.firstQuestion ? makeOverflowTooltip(data.firstQuestion, 42) : '--'} </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader">First Survey Answer:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography> {data.firstAnswer ? makeOverflowTooltip(data.firstAnswer, 42) : '--'} </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="tableHeader">Overall Score:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography> {data?.overallScore ? Number(data.overallScore) * 100 + '%' : '--'} </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Stack>;
    }
    return <></>;
  };
  const getStatusIcon = (type, event) => {
    if (type == 'Email') {
      const [color, title] = getEmailColor(event);
      return <StatusDotIcon fill={color} />;
    }
    if (type == 'SMS') {
      const [color, title] = getSMSColor(event, type?.direction);
      return <StatusDotIcon fill={color} />;
    }
    if (type == 'Mailer') {
      const [color, title] = getMailerColor(event);
      return <StatusDotIcon fill={color} />;
    }
    return <StatusDotIcon />;
  };
  const getFormatedName = (data, event) => {
    if (data.type == 'Email') {
      const [color, title] = getEmailColor(event);
      return <Typography variant="tableHeader">{title}</Typography>;
    }
    if (data.type == 'SMS') {
      const [color, title] = getSMSColor(event, data.direction);
      if (event == 'undelivered' || event == 'failed') {
        return <Tooltip title={(data.error_code || '') + ' - ' + (data.error_message || '')}>
            <Typography variant="tableHeader">{title}</Typography>
          </Tooltip>;
      }
      return <Typography variant="tableHeader">{title}</Typography>;
    }
    if (data.type == 'Mailer') {
      const [color, title] = getMailerColor(event);
      return <Typography variant="tableHeader">{title}</Typography>;
    }
    return <Typography variant="tableHeader">{event}</Typography>;
  };
  const makeDisplay = ref => {
    if (ref.type == 'Transaction') {
      return ref.detail1 + ' - ' + ref.detail2;
    }
    if (ref.type == 'Review') {
      return <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <GoogleIcon sx={{
          fontSize: 16
        }} />
          <Rating name="read-only" value={convertNormalizedScore(ref.detail2)} readOnly />
        </Stack>;
    }
    return ref.detail2 || ref.detail1;
  };
  const displayConditionalHover = ref => {
    if (ref.type === 'Transaction') {
      return <Tooltip title="Go to Transaction">
          <IconButton onClick={e => {
          e.preventDefault();
          goToTransaction(ref.id);
        }}>
            <OpenInNewWindowIcon />
          </IconButton>
        </Tooltip>;
    }
    if (ref.type === 'Review') {
      return <Tooltip title="Go to Review">
          <IconButton onClick={e => {
          e.preventDefault();
          closeCustomerDrawer();
          history.push(`/reviews?id=${ref.id}`);
        }}>
            <OpenInNewWindowIcon />
          </IconButton>
        </Tooltip>;
    }
    if (ref.type === 'Schedule') {
      return <Tooltip title="Go to Schedule">
          <IconButton onClick={e => {
          e.preventDefault();
          goToSchedule(ref.id);
        }}>
            <OpenInNewWindowIcon />
          </IconButton>
        </Tooltip>;
    }
    if (ref.type === 'Journey') {
      return <Tooltip title="Go to Journey">
          <IconButton onClick={e => {
          e.preventDefault();
          closeCustomerDrawer();
          history.push(`/customer-journeys/${ref.id}`);
        }}>
            <OpenInNewWindowIcon />
          </IconButton>
        </Tooltip>;
    }
    return <Box sx={{
      width: '35px'
    }}></Box>;
  };
  return <Scoped css={styles}>
      <Stack sx={{
      height: '100%'
    }} className="d-flex h-100 justify-content-between">
        <Stack sx={{
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '25px',
          marginBottom: '25px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Box sx={{
          borderTop: '1px solid #EDEFF1',
          borderBottom: '1px solid #EDEFF1'
        }}>
            <Box sx={{
            pl: '12px',
            pr: '6px'
          }} className="d-flex h-100 justify-content-between">
              <Stack sx={{
              py: '10px'
            }} direction="row" spacing="10px">
                <Box className="d-flex h-100 justify-content-between" onClick={e => {
                handleDatePickerClick(e, datePickerTypes.find(d => d.key === datePickerSelection));
              }} sx={{
                borderRadius: '8px',
                py: '6px',
                px: '4px',
                cursor: 'pointer',
                border: '1px solid #E3E5E7',
                display: 'flex',
                alignItems: 'center',
                width: '222px'
              }}>
                  <Stack spacing="8px" sx={{
                  display: 'flex',
                  alignItems: 'center'
                }} direction="row">
                    {dateRangeCalendar.length > 0 && <CalendarDateRangeIcon />}
                    {dateRangeCalendar.length > 0 && <Typography>
                        {dateRangeCalendar[0] && dateRangeCalendar[1] ? dateRangeCalendar[0]?.format('MM/DD/YY') + ' to ' + dateRangeCalendar[1]?.format('MM/DD/YY') : dateRangeCalendar[0]?.format('MM/DD/YY') + ' to ' + dateRangeCalendar[0]?.format('MM/DD/YY')}
                      </Typography>}
                    {dateRangeCalendar.length == 0 && <Box> {datePickerTypes.find(d => d.key === datePickerSelection).icon}</Box>}
                    {dateRangeCalendar.length == 0 && <Typography> {datePickerTypes.find(d => d.key === datePickerSelection).name}</Typography>}
                  </Stack>
                  {datePickerMenuOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}
                </Box>
                <Box onClick={e => {
                handleTypePickerClick(e);
              }} className="d-flex h-100 justify-content-between" sx={{
                borderRadius: '8px',
                py: '2px',
                pl: '9px',
                pr: '2px',
                cursor: 'pointer',
                border: '1px solid #E3E5E7',
                display: 'flex',
                alignItems: 'center',
                background: !filters['type'] || filters['type']?.length == 0 ? '#fff' : '#C2E7FF',
                width: '175px'
              }} direction="row">
                  {(!filters['type'] || filters['type']?.length == 0) && <Typography>Type</Typography>}
                  {filters['type']?.length > 0 && <Box className="d-flex w-100 justify-content-between">
                      <Stack spacing="5px" direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      color: '#3898D9'
                    }}>
                          {selectedTimelineView == 'summary' ? typeTypes.find(d => d.key === filters['type'][0])?.name : 'Type (' + filters['type']?.length + ')'}
                        </Typography>

                        {selectedTimelineView == 'summary' && filters['type']?.length > 1 && <Typography sx={{
                      color: '#3898D9'
                    }}> {'+ ' + (filters['type']?.length - 1)}</Typography>}
                      </Stack>
                      {filters['type']?.length > 0 && <IconButton size="small" onClick={e => {
                    e.stopPropagation();
                    handleSelectedTypes([]);
                    handleTypepickerClose();
                  }}>
                          <CloseIcon size="sm" fill="#3898D9" />
                        </IconButton>}
                    </Box>}
                  {(!filters['type'] || filters['type']?.length == 0) && <> {typePickerOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}</>}
                </Box>

                {selectedTimelineView == 'log' && <Box onClick={e => {
                handleEventPickerClick(e);
              }} className="d-flex h-100 justify-content-between" sx={{
                borderRadius: '8px',
                py: '2px',
                pl: '9px',
                pr: '2px',
                cursor: 'pointer',
                border: '1px solid #E3E5E7',
                display: 'flex',
                alignItems: 'center',
                background: !filters['event'] || filters['event']?.length == 0 ? '#fff' : '#C2E7FF',
                width: '130px'
              }} direction="row">
                    {(!filters['event'] || filters['event']?.length == 0) && <Typography>Event</Typography>}
                    {filters['event']?.length > 0 && <Box className="d-flex w-100 justify-content-between">
                        <Stack spacing="5px" direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                          <Typography sx={{
                      color: '#3898D9'
                    }}>{'Event (' + filters['event']?.length + ')'}</Typography>
                        </Stack>
                        {filters['event']?.length > 0 && <IconButton size="small" onClick={e => {
                    e.stopPropagation();
                    handleSelectedEvent([]);
                    handleEventPickerClose();
                  }}>
                            <CloseIcon size="sm" fill="#3898D9" />
                          </IconButton>}
                      </Box>}
                    {(!filters['event'] || filters['event']?.length == 0) && <> {eventPickerOpen ? <CarrotUpIcon /> : <CarrotDownIcon />}</>}
                  </Box>}
              </Stack>
              <Stack sx={{
              py: '10px'
            }} direction="row" spacing="5px">
                <Box>
                  <Tooltip title="Summary View">
                    <IconButton onClick={e => {
                    const new_filters = {
                      ...filters
                    };
                    delete new_filters['type'];
                    delete new_filters['event'];
                    updateFilters(new_filters);
                    setPage(0);
                    setSelectedTimelineView('summary');
                  }}>
                      <SegmentIcon fill={selectedTimelineView == 'summary' ? '#3898D9' : '#1D252D'} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title="Log View">
                    <IconButton onClick={e => {
                    const new_filters = {
                      ...filters
                    };
                    delete new_filters['type'];
                    delete new_filters['event'];
                    updateFilters(new_filters);
                    setPage(0);
                    setSelectedTimelineView('log');
                  }}>
                      <SurveysIcon fill={selectedTimelineView == 'log' ? '#3898D9' : '#1D252D'} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider orientation="vertical" flexItem />
                {false && <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: '80px'
              }}>
                    {selectedTimelineView == 'summary' && <Typography>{(customerTimelineSummary?.length || 'No') + ' Items'} </Typography>}
                    {selectedTimelineView == 'log' && <Typography>{(customerTimeline?.length || 'No') + ' Items'} </Typography>}
                  </Box>}
              </Stack>
            </Box>
          </Box>
          {customerTimeline && customerTimelineSummary && customerDetails && !isLoading ? <Box>
              {selectedTimelineView == 'summary' && <Table>
                  <colgroup>
                    <col style={{
                width: '5%'
              }} />
                    <col style={{
                width: '33%'
              }} />
                    <col style={{
                width: '54%'
              }} />
                    <col style={{
                width: '8%'
              }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{
                  pl: '25px'
                }}>
                        <Typography variant="tableHeader"> Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="tableHeader"> Date</Typography>
                      </TableCell>
                      <TableCell sx={{
                  py: '0px'
                }} colSpan={2}>
                        <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                            <Typography variant="tableHeader"> Description</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0 ? customerTimelineSummary.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : customerTimelineSummary).map((ref, index) => <React.Fragment key={ref.id + index}>
                        <TableRow className="row" sx={{
                  cursor: 'pointer',
                  backgroundColor: isExpandedCell(ref.id) ? '#E4E9F1' : '#fff'
                }} key={ref.id} onClick={() => handleRowClick(ref)} onMouseEnter={e => {
                  if (!!mailerTooltipAnchorEl) {
                    handleMailerTooltipHoverClose(e);
                  }
                }}>
                          <TableCell sx={{
                    pl: '25px',
                    py: '8px'
                  }}>{getIcon(ref, isExpandedCell(ref.id))}</TableCell>
                          <TableCell sx={{
                    py: '8px'
                  }}>
                            {isExpandedCell(ref.id) ? <Typography variant="tableHeader">{moment.utc(ref.created_when).local().format('MM/D/YY h:mm A')}</Typography> : <Typography>{moment.utc(ref.created_when).local().format('MM/D/YY h:mm A')} </Typography>}
                          </TableCell>
                          <TableCell sx={{
                    pr: 0,
                    py: '8px'
                  }}>
                            {isExpandedCell(ref.id) ? <> {makeOverflowTooltip(makeDisplay(ref), 34, true)}</> : <> {makeOverflowTooltip(makeDisplay(ref), 34)} </>}
                          </TableCell>
                          <TableCell className="icons__remove" sx={{
                    py: '8px'
                  }}>
                            <Stack direction="row">
                              {displayConditionalHover(ref)}
                              {isExpandedCell(ref.id) ? <Tooltip title="Collapse">
                                  <IconButton>
                                    <CarrotUpIcon />
                                  </IconButton>
                                </Tooltip> : <Tooltip title="Expand">
                                  <IconButton>
                                    <CarrotDownIcon />
                                  </IconButton>
                                </Tooltip>}
                            </Stack>
                          </TableCell>
                        </TableRow>
                        {isExpandedCell(ref.id) && <TableRow>
                            <TableCell colSpan={4} sx={{
                    p: 0
                  }}>
                              <Box sx={{
                      minHeight: hasFetchedApiData(ref.id) ? '50px' : '100px',
                      backgroundColor: '#E4E9F1'
                    }}>
                                {hasFetchedApiData(ref.id) ? getFetchedApiData(ref.id) : <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pt: '25px'
                      }}>
                                    <CircularProgress />
                                  </Box>}
                              </Box>
                            </TableCell>
                          </TableRow>}
                      </React.Fragment>)}
                    {!customerTimelineSummary.length && Object.keys(filters).length == 0 && <TableRow>
                        <TableCell colSpan={4}>
                          <i>Customer has no timeline</i>
                        </TableCell>
                      </TableRow>}
                    {!customerTimelineSummary.length && Object.keys(filters).length > 0 && <TableRow>
                        <TableCell colSpan={4}>
                          {filters['type']?.length > 0 ? <i>{'Customer has no activity with these filters'} </i> : <>
                              {dateRangeCalendar.length > 0 ? <i>
                                  {'Customer has no activity in the range ' + dateRangeCalendar[0]?.format('MM/DD/YY') + ' to ' + (dateRangeCalendar[1] ? dateRangeCalendar[1]?.format('MM/DD/YY') : dateRangeCalendar[0]?.format('MM/DD/YY')) + '. Try adjusting filters'}
                                </i> : <i>
                                  {'Customer has no activity in the ' + datePickerTypes.find(d => d.key === datePickerSelection).name + '. Try adjusting filters'}
                                </i>}
                            </>}
                        </TableCell>
                      </TableRow>}
                  </TableBody>
                </Table>}

              {selectedTimelineView == 'log' && <Table>
                  <colgroup>
                    <col style={{
                width: '30%'
              }} />
                    <col style={{
                width: '20%'
              }} />
                    <col style={{
                width: '25%'
              }} />
                    <col style={{
                width: '25%'
              }} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="tableHeader"> Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="tableHeader"> Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="tableHeader"> Event</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="tableHeader"> Description</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0 ? customerTimeline.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : customerTimeline).map((ref, index) => <TableRow key={ref.id + index}>
                        <TableCell>{moment.utc(ref.created_when).local().format('MM/D/YY h:mm A')}</TableCell>
                        <TableCell>{ref.type}</TableCell>
                        <TableCell>{makeOverflowTooltip(ref.detail1, 16)}</TableCell>
                        <TableCell>{makeOverflowTooltip(ref.detail2, 16)}</TableCell>
                      </TableRow>)}
                    {!customerTimeline.length && <TableRow>
                        <TableCell colSpan="4">
                          <i>Customer has no timeline</i>
                        </TableCell>
                      </TableRow>}
                  </TableBody>
                </Table>}
            </Box> : <Box sx={{
          width: '100%'
        }}>
              <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: '55px'
          }}>
                <CircularProgress />
              </Box>
            </Box>}
        </Stack>
        {customerTimelineSummary && !isLoading && selectedTimelineView == 'summary' && <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        pr: '12px'
      }}>
            <TablePagination sx={{
          '& .MuiTablePagination-displayedRows': {
            margin: '0px'
          },
          overflow: 'hidden',
          border: '0px solid #fff'
        }} count={customerTimelineSummary?.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} rowsPerPageOptions={[5]} nextIconButtonProps={{
          style: {
            display: customerTimelineSummary?.length > 100 ? 'inline' : 'none'
          }
        }} backIconButtonProps={{
          style: {
            display: customerTimelineSummary?.length > 100 ? 'inline' : 'none'
          }
        }} />
          </Box>}
        {customerTimeline && !isLoading && selectedTimelineView == 'log' && <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        pr: '12px'
      }}>
            <TablePagination sx={{
          '& .MuiTablePagination-displayedRows': {
            margin: '0px'
          },
          overflow: 'hidden',
          border: '0px solid #fff'
        }} count={customerTimeline?.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} rowsPerPageOptions={[5]} nextIconButtonProps={{
          style: {
            display: customerTimeline?.length > 100 ? 'inline' : 'none'
          }
        }} backIconButtonProps={{
          style: {
            display: customerTimeline?.length > 100 ? 'inline' : 'none'
          }
        }} />
          </Box>}
      </Stack>

      <Popper anchorEl={datePickerMenuAnchorEl} open={datePickerMenuOpen} sx={{
      zIndex: 1500,
      width: '312px'
    }} onClose={handleDatePickerClose} placement="bottom">
        <ClickAwayListener onClickAway={handleDatePickerClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            p: '12px'
          }} className="d-flex h-100 justify-content-between">
              <Typography variant="tableHeader">Date</Typography>
              <Box sx={{
              cursor: 'pointer'
            }} onClick={e => {
              setDatePickeMenuOpen(false);
              setDatePickerMenuAnchorEl(null);
              setDateRangeAnchorEl(null);
              setDateRangeMenuOpen(false);
            }}>
                <CloseIcon />
              </Box>
            </Box>
            {datePickerTypes.map(filter => <Stack direction="row" sx={{
            cursor: 'pointer',
            p: '12px',
            display: 'flex',
            alignItems: 'center',
            ':hover': {
              backgroundColor: '#F3F3F4'
            }
          }} onMouseEnter={handleDateRangeClose} key={filter.key} spacing="12px" onClick={e => {
            handleDatePickerMenuClick(filter.key);
          }}>
                <Box> {filter.icon}</Box>
                <Typography> {filter.name}</Typography>
              </Stack>)}
            <Box sx={{
            pr: '12px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            ':hover': {
              backgroundColor: '#F3F3F4'
            }
          }} onMouseEnter={e => {
            setDateRangeAnchorEl(e.currentTarget);
            setDateRangeMenuOpen(true);
          }} className="d-flex h-100 justify-content-between">
              <Stack direction="row" spacing="12px" sx={{
              p: '12px',
              display: 'flex',
              alignItems: 'center'
            }}>
                <CalendarDateRangeIcon />
                <Typography>Date Range </Typography>
              </Stack>
              <RightCaretIcon />
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Popper anchorEl={dateRangeAnchorEl} sx={{
      zIndex: 1500
    }} open={dateRangeMenuOpen} onClose={handleDateRangeClose} placement="left">
        <Paper elevation={0} sx={{
        borderRadius: '16px',
        filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
      }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{
          after: 'after date',
          before: 'before date'
        }} sx={{
          padding: '10px'
        }}>
            <DateRangeCalendar value={dateRangeCalendarPending} onChange={newValue => {
            setDateRangeCalendarPending(newValue);
          }} />
          </LocalizationProvider>
          <Stack sx={{
          pb: '12px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse'
        }} direction="row">
            <Button onClick={() => {
            handleDatePickerCalendarSubmit();
          }}>
              <Typography sx={{
              textTransform: 'none'
            }}> Ok</Typography>
            </Button>
            <Button onClick={() => {
            handleDateRangeClose();
          }}>
              <Typography sx={{
              textTransform: 'none'
            }}> Cancel </Typography>
            </Button>
          </Stack>
        </Paper>
      </Popper>

      <Popper anchorEl={typePickerAnchorEl} open={typePickerOpen} sx={{
      zIndex: 1500,
      width: '312px'
    }} onClose={handleTypepickerClose} placement="bottom">
        <ClickAwayListener onClickAway={handleTypepickerClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <Box sx={{
            py: '12px',
            px: '16px'
          }} className="d-flex h-100 justify-content-between">
              <Typography variant="tableHeader">Type</Typography>
              <Box sx={{
              cursor: 'pointer'
            }} onClick={e => {
              handleTypepickerClose();
            }}>
                <CloseIcon />
              </Box>
            </Box>

            <Box sx={{
            py: '12px',
            px: '16px'
          }} className="d-flex h-100 justify-content-between">
              <Typography>Select all</Typography>
              <AntSwitch sx={{
              ml: '40px'
            }} checked={typeSelectAll} onChange={() => {
              handleTypeSelectAll();
            }} />
            </Box>
            <Box sx={{
            pl: '25px',
            pb: '25px'
          }}>
              {typeTypes.map(filter => <Box key={filter.key} onMouseEnter={e => setTypeHover(filter.key)} onMouseLeave={e => setTypeHover(null)} className="d-flex w-100 justify-content-between">
                  <Stack direction="row" sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
              }} spacing="4px">
                    <Checkbox color="primary" checked={!!filters['type']?.find(t => t == filter.key)} onChange={e => {
                  handleTypePickerMenuClick(filter.key);
                }} sx={{
                  color: '#1D252D',
                  '&.Mui-checked': {
                    color: '#53A6D6'
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }} />
                    <Stack direction="row" sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }} spacing="12px">
                      <Box>{filter.icon}</Box>
                      <Typography> {filter.name}</Typography>
                    </Stack>
                  </Stack>
                  {typeHover == filter.key && <Box onClick={e => {
                handleSelectedTypes([filter.key]);
              }} sx={{
                pr: '35px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
                      <Typography sx={{
                  color: '#3898D9'
                }}> Only</Typography>
                    </Box>}
                </Box>)}
            </Box>
            <Divider />
            <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            pr: '25px',
            py: '10px'
          }}>
              <Button onClick={e => {
              handleSelectedTypes([]);
            }}>
                <Typography sx={{
                color: '#3898D9',
                textTransform: 'none',
                cursor: 'pointer'
              }}> Clear</Typography>
              </Button>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Popper anchorEl={mailerTooltipAnchorEl} open={!!mailerTooltipOpen} sx={{
      zIndex: 1500
    }} onClose={handleMailerTooltipHoverClose} placement="left">
        <ClickAwayListener onClickAway={handleMailerTooltipHoverClose}>
          <Stack spacing="10px" sx={{
          p: '10px',
          backgroundColor: '#F3F3F4'
        }}>
            <Box sx={{
            border: '1px solid #1d252d'
          }}>
              <CustomTooltip placement="left-end" title={<object onMouseLeave={e => handleMailerTooltipHoverClose(e)} style={{
              maxHeight: '50vh',
              maxWidth: '50vw'
            }} data={mailerTooltipOpen?.front} type="image/jpeg" />}>
                <object style={{
                height: '83px',
                width: '125px'
              }} data={mailerTooltipOpen?.front} type="image/jpeg">
                  <TemplatesIcon />
                </object>
              </CustomTooltip>
            </Box>
            <Box sx={{
            border: '1px solid #1d252d'
          }}>
              <CustomTooltip placement="left-end" title={<object onMouseLeave={e => handleMailerTooltipHoverClose(e)} style={{
              maxHeight: '50vh',
              maxWidth: '50vw'
            }} data={mailerTooltipOpen?.back} type="image/jpeg" />}>
                <object style={{
                height: '83px',
                width: '125px'
              }} data={mailerTooltipOpen?.back} type="image/jpeg">
                  <TemplatesIcon />
                </object>
              </CustomTooltip>
            </Box>
          </Stack>
        </ClickAwayListener>
      </Popper>

      <Popper anchorEl={eventPickerAnchorEl} open={eventPickerOpen} sx={{
      zIndex: 1500,
      width: '312px'
    }} onClose={handleEventPickerClose} placement="bottom">
        <ClickAwayListener onClickAway={handleEventPickerClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          maxHeight: '66vh',
          overflowY: 'scroll',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '25px',
            marginBottom: '25px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }}>
            <Box sx={{
            py: '12px',
            px: '16px'
          }} className="d-flex h-100 justify-content-between">
              <Typography variant="tableHeader">Event</Typography>
              <Box sx={{
              cursor: 'pointer'
            }} onClick={e => {
              handleEventPickerClose();
            }}>
                <CloseIcon />
              </Box>
            </Box>

            <Box sx={{
            py: '12px',
            px: '16px'
          }} className="d-flex h-100 justify-content-between">
              <Typography>Select all</Typography>
              <AntSwitch sx={{
              ml: '40px'
            }} checked={eventSelectAll} onChange={() => {
              handleEventSelectAll();
            }} />
            </Box>
            <Box sx={{
            pl: '25px',
            pb: '25px'
          }}>
              {typeTypes.filter(t => !!t.types).map(events => <Box sx={{
              pt: '12px'
            }} key={events.key}>
                    <Stack direction="row" spacing="12px" sx={{
                display: 'flex',
                alignItems: 'center',
                pb: '12px'
              }}>
                      <Box> {events.icon}</Box>
                      <Typography sx={{
                  color: '#616161F2'
                }}>{events.name} </Typography>
                    </Stack>

                    {events.types.map(filter => <Box key={events.key + '-' + filter.key} onMouseEnter={e => setEventHover(events.key + '-' + filter.key)} onMouseLeave={e => setEventHover(null)} className="d-flex w-100 justify-content-between">
                        <Stack direction="row" sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }} spacing="4px">
                          <Checkbox color="primary" checked={!!filters['event']?.find(t => t == events.key + '-' + filter.key)} onChange={e => {
                    handleEventPickerMenuClick(events.key + '-' + filter.key);
                  }} sx={{
                    color: '#1D252D',
                    '&.Mui-checked': {
                      color: '#53A6D6'
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 22
                    }
                  }} />
                          <Stack direction="row" sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }} spacing="12px">
                            <Typography> {filter.name}</Typography>
                          </Stack>
                        </Stack>
                        {eventHover == events.key + '-' + filter.key && <Box onClick={e => {
                  handleSelectedEvent([events.key + '-' + filter.key]);
                }} sx={{
                  pr: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}>
                            <Typography sx={{
                    color: '#3898D9'
                  }}> Only</Typography>
                          </Box>}
                      </Box>)}
                  </Box>)}
            </Box>
            <Divider />
            <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            pr: '25px',
            py: '10px'
          }}>
              <Button onClick={e => {
              handleSelectedEvent([]);
            }}>
                <Typography sx={{
                color: '#3898D9',
                textTransform: 'none',
                cursor: 'pointer'
              }}> Clear</Typography>
              </Button>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Scoped>;
}