import React, { useState, useEffect, useContext } from 'react';
import { Scoped } from 'kremling';
import { toasterService } from '../../components/toaster/toaster-service';
import { Loader } from '../../components/loader/loader.component';
import { getEmail, getDomains, createEmailRevision, getEmailRevision, updateEmailRevision, getSegments, getPremadeBlocksTemplate, createPremadeBlocksTemplate, updatePremadeBlocksTemplate, deletePremadeBlocksTemplate, patchEmail } from '../../shared/common.api';
import { topolJson } from './email-template.utils';
import styles from './template-list.styles.scss';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { IFrame, DynamicTypeAheadMui, PageHeaderMui } from 'components';
import { api } from '../../shared/api';
import { UserStateContext } from '../../context/user-state-context';
import { Content, EditIcon, PreviewIcon, PublishIcon, SaveIcon, SendTestIcon, UndoIcon, ArrowBackIcon, DesktopIcon, MobileIcon, Modal, RedoIcon, DraftIcon, RevisionIcon, TemplatesIcon, InboxIcon, InboxMoveIcon } from 'components/mui';
import { Stack, Box, IconButton, Button, Tooltip, ButtonGroup, TextField, MenuItem, Typography } from '@mui/material';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
const isEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const EditEmailTemplate = props => {
  const {
    checkCompany,
    asCompany,
    user,
    hasPermission,
    companyFields
  } = useContext(UserStateContext);
  const [email, setEmail] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [revisions, setRevisions] = useState([]);
  const [revision, setRevision] = useState({});
  const [revisionID, setRevisionID] = useState();
  const [isPreview, setIsPreview] = useState(false);
  const [previewDesktop, setPreviewDesktop] = useState(true);
  const [forceReload, setForceReload] = useState(false);
  const [mergeTags, setMergeTags] = useState();
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [showSendTest, setShowSendTest] = useState(false);
  const [testData, setTestData] = useState({});
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [emailDetailsPublish, setEmailDetailsPublish] = useState(false);
  const [blocks, setBlocks] = useState(null);
  const [savedBlockModal, setSavedBlockModal] = useState(false);
  const [savedBlock, setSavedBlock] = useState({
    name: null,
    company: asCompany.id,
    definition: null,
    block_type: null,
    premade_category: null,
    img: null,
    template: null
  });
  const [redoCount, setRedoCount] = useState(0);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [domains, setDomains] = useState([]);
  const [testEmailPreset, setTestEmailPreset] = useState('');
  useEffect(() => {
    if (email?.revisions) {
      setRevisions(email.revisions.filter(r => !!r.published_when || !isReadOnly).map(revision => ({
        key: revision.id,
        name: !revision.published_when ? 'Draft' : `Published ${moment(revision.published_when).format('MMM D, YYYY h:mm A')}`,
        icon: !revision.published_when ? <DraftIcon /> : <RevisionIcon />
      })));
    } else {
      setRevisions([]);
    }
  }, [email]);
  const handlePublish = () => {
    if (!email.name) {
      toasterService.error('Template Name is required!');
    } else {
      setLoading(true);
      setShowEditDetails(false);
      setEmailDetailsPublish(false);
      setRevision({
        ...revision,
        published_when: new Date()
      });
      TopolPlugin.save();
    }
  };
  const checkFormErrors = () => {
    return !!formErrors.from_name || !!formErrors.subject || !!formErrors.from_address;
  };
  const validateEmailDetails = () => {
    return !checkFormErrors() && !!revision.subject && !!revision.from_address && !!revision.from_name;
  };
  const openSendTestModal = () => {
    TopolPlugin.save();
    setShowSendTest(true);
  };
  const updateRevision = e => {
    const name = e.target.name;
    const value = e.target.value;
    setRevision({
      ...revision,
      [name]: value
    });
  };
  const onBlur = (key, val) => {
    let isInvalid = key === 'from_address' && !isEmail(val) || isEmpty(val);
    setFormErrors({
      ...formErrors,
      [key]: isInvalid
    });
  };
  const savePlugin = () => {
    setShowEditDetails(false);
    TopolPlugin.save();
  };
  const updateDraft = (json, html, callback) => {
    setRevision(revision => {
      const newRevision = {
        ...revision,
        template: json,
        body_html: html
      };
      updateEmailRevision(newRevision.id, newRevision).then(() => {
        if (callback) {
          callback(revision);
        }
      });
      return newRevision;
    });
  };
  const checkPublished = revision => {
    if (revision.published_when) {
      // We just published a new revision
      loadEmail(email.id).then(() => {
        setForceReload(val => !val);
      });
    }
  };

  /*
  // We removed the "Save and Close" button.  Someone may be upset about that so I'm leaving the code for it commented out.
  const [saveAndCloseModal, setSaveAndCloseModal] = useState(false);
  const [publishReminder, setPublishReminder] = useState(false);
  const goBack = () => {
    const { idEmailTemplate } = props.match.params;
    getEmail(idEmailTemplate).then(({ data }) => {
      const revision_has_changes = data.revisions.find((rev) => rev.id == revisionID).has_unpublished_changes
      const reminder = localStorage.getItem('templatePublishChangesReminder')
       if (revision_has_changes && reminder != 'true') {
        setSaveAndCloseModal(true)
      } else {
        handleCloseClick()
      }
    })
  };
     const handleCloseClick = () => {
    if (!!props.history.location.state) {
      props.history.push({ pathname: props.history.location.state, state: 'draft' });
    }
    else {
      props.history.push('/templates')
    }
  }
  */

  const sendTest = async () => {
    try {
      let message = `${revision.body_html}`;
      if (testData.message) {
        message = `<div><p>${testData.message.replace(/(?:\r\n|\r|\n)/g, '<br>')}</p></div>${message}`;
      }
      message += "<div><p style='font-weight:800;color:red;text-align:center;margin:20px 0 10px 0'>This email was sent for TESTING purposes only.</p></div>";
      await api.post(`/sendgrid/test`, {
        to_address: testData.to,
        from_name: revision.from_name,
        preview_text: revision.preview_text || '',
        subject: revision.subject,
        from_address: revision.from_address,
        body_plain: revision.body_plain || ' ',
        body_html: message,
        segment_id: testData.segment_id,
        company_id: asCompany.id
      });
      snackbarService.popup({
        type: 'success',
        message: 'Test email successfully sent to ' + testData.to
      });
      setShowSendTest(false);
    } catch (error) {
      let errorMessage = 'Failed to send test email to ' + testData.to;
      if (!testData.to) {
        errorMessage = 'Failed to send test email';
      }
      snackbarService.popup({
        type: 'error',
        message: errorMessage
      });
    }
  };
  const fetchPremadeBlocks = () => {
    getPremadeBlocksTemplate({
      company: asCompany.id
    }).then(({
      data
    }) => {
      if (data) {
        const premadeBlocks = data.email_premade;
        const savedBlocks = data.email_saved.map((block, index) => ({
          ...block,
          uid: block.id,
          id: index
        }));
        setBlocks({
          premadeBlocks: premadeBlocks || [],
          savedBlocks: savedBlocks || []
        });
      } else {
        setBlocks({
          premadeBlocks: [],
          savedBlocks: []
        });
      }
    });
  };
  const undo = () => {
    TopolPlugin.undo();
    setRedoCount(r => r + 1);
  };
  const redo = () => {
    TopolPlugin.redo();
    setRedoCount(r => r - 1);
  };
  useEffect(() => {
    fetchPremadeBlocks();
    const interval = setInterval(() => TopolPlugin.save(), 60000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (!revisionID || blocks === null) return;
    // LOAD REVISION DETAILS NOW!
    setLoading(true);
    TopolPlugin.destroy();
    getEmailRevision(revisionID).then(({
      data
    }) => {
      setRevision(data);
      if (!data.published_when) {
        // Only load TOPOL if the revision is not published
        const TOPOL_OPTIONS = {
          id: '#widget',
          light: true,
          authorize: {
            apiKey: TOPOL_TOKEN,
            userId: asCompany.id
          },
          mergeTags: mergeTags,
          //removeTopBar: !!data.published_when,
          removeTopBar: true,
          savedBlocks: blocks.savedBlocks,
          premadeBlocks: blocks.premadeBlocks,
          contentBlocks: {
            product: {
              hidden: true
            }
          },
          disableAlerts: true,
          callbacks: {
            onInit: () => {
              if (!!data.published_when) {
                TopolPlugin.togglePreview();
              }
              setLoading(false);
            },
            onSave: (json, html) => updateDraft(json, html, checkPublished),
            // onSaveAndClose: (json, html) => updateDraft(json, html, goBack),
            onBlockSave: (json, html) => {
              TopolPlugin.save();
              setSavedBlockModal({
                isOpen: true,
                type: 'create'
              });
              setSavedBlock({
                company: asCompany.id,
                block_type: 'saved',
                definition: json.definition
              });
            },
            onBlockRemove(id) {
              TopolPlugin.save();
              setSavedBlock(blocks.savedBlocks.find(block => block.id === id));
              setSavedBlockModal({
                isOpen: true,
                type: 'delete'
              });
            },
            onBlockEdit(id) {
              TopolPlugin.save();
              setSavedBlock(blocks.savedBlocks.find(block => block.id === id));
              setSavedBlockModal({
                isOpen: true,
                type: 'edit'
              });
            },
            onAlert(notification) {
              snackbarService.popup({
                type: notification.type,
                message: notification.text
              });
            },
            onPreview(html) {}
          }
        };
        TopolPlugin.init(TOPOL_OPTIONS);
        TopolPlugin.load(JSON.stringify(data.template || topolJson));
      } else {
        setLoading(false);
      }
    });
  }, [revisionID, forceReload, blocks]);
  const handleSaveBlock = () => {
    let promise = Promise.resolve();
    if (savedBlockModal.type === 'create') {
      if (savedBlock.block_type === 'premade') {
        savedBlock.definition = [savedBlock.definition];
      }
      promise = promise.then(() => createPremadeBlocksTemplate(savedBlock));
    } else if (savedBlockModal.type === 'edit') {
      promise = promise.then(() => updatePremadeBlocksTemplate(savedBlock.uid, savedBlock));
    } else if (savedBlockModal.type === 'delete') {
      promise = promise.then(() => deletePremadeBlocksTemplate(savedBlock.uid, savedBlock));
    }
    setSavedBlockModal({
      isOpen: false,
      type: null
    });
    promise.then(() => fetchPremadeBlocks());
  };
  const loadEmail = id => {
    /*
     * Load the email from the API if the email is missing a draft revision create it.
     *
     * This function is called on page load and after a new revision is published.
     */
    return getEmail(id).then(({
      data
    }) => {
      // check for different company link
      return checkCompany(data.company).then(() => data);
    }).then(data => {
      const email = data;
      const isReadOnly = email.tags && email.tags.includes('READ-ONLY');
      const draft = (email.revisions || []).find(revision => !revision.published_when);
      if (!isReadOnly && !draft) {
        // If we don't have a draft for this email (and this isn't a read only template) then create one.
        let promise = Promise.resolve();
        if (email.revisions && email.revisions.length > 0) {
          // If we have a previous revision set up the draft based off of that revision
          promise = promise.then(() => getEmailRevision(email.revisions[0].id)).then(({
            data
          }) => {
            delete data.id;
            delete data.published_when;
            return data;
          });
        } else {
          // if we don't have a previous revision then make an set up an empty one.
          promise = promise.then(() => ({
            email: email.id,
            from_address: asCompany.default_email,
            from_name: asCompany.default_from_name
          }));
        }
        return promise.then(revision => createEmailRevision(revision)).then(({
          data
        }) => {
          if (!email.revisions) {
            email.revisions = [];
          }
          // Add new draft to top of revisions list.
          email.revisions.unshift(data);
          setEmail(email);
          if (email.revisions.length === 1) {
            setShowEditDetails(true);
          }
          return {
            email,
            draft: data
          };
        });
      }
      setEmail(email);
      setIsReadOnly(isReadOnly);
      if (isReadOnly) {
        const revisionPublished = email.revisions.find(r => !!r.published_when);
        if (revisionPublished) setRevisionID(revisionPublished.id);
      }
      return {
        email,
        draft,
        isReadOnly
      };
    });
  };
  useEffect(() => {
    /**
     * Init
     *
     * Load company fields, email details, and email domains.
     * Set up merge tags for TOPOL
     */
    const {
      idEmailTemplate
    } = props.match.params;
    Promise.all([loadEmail(idEmailTemplate), getDomains({
      company: asCompany.id
    }).then(({
      data
    }) => data.results)]).then(([{
      draft,
      isReadOnly
    }, domains]) => {
      setDomains(domains);
      if (isReadOnly) return;
      const topolMergeTags = companyFields.filter(field => ['cart_coupon', 'cart_detail', 'transaction_detail', 'coupon'].indexOf(field.id) === -1).map(field => ({
        name: field.name,
        items: field.fields.map(item => ({
          value: `{{ ${field.id}.${item.id.replace(/__/g, '.')} }}`,
          text: item.name
        }))
      }));
      topolMergeTags.push({
        name: 'Other',
        items: [{
          value: '{{ expires 15 }}',
          text: 'Expires in 15 Days'
        }, {
          value: '{{ expires 30 }}',
          text: 'Expires in 30 Days'
        }, {
          value: '{{ expires 45 }}',
          text: 'Expires in 45 Days'
        }, {
          value: '{{ expires 60 }}',
          text: 'Expires in 60 Days'
        }, {
          value: '{{ surveyLink }}',
          text: 'Survey Link'
        }, {
          value: '{{ swellReviewLink }}',
          text: 'Swell Review Link'
        }, {
          value: "{{ singleUseCouponCode 'coupon id here' }}",
          text: 'Single Use Coupon Code'
        }, {
          value: '<a href="{{ unsubscribe_url }}">Unsubscribe</a>',
          text: 'Unsubscribe Link'
        }]
      });
      setMergeTags(topolMergeTags);
      if (props?.location?.isFromCreate) {
        // If this is a newly created email template then open up the edit modal.
        setShowEditDetails(true);
      }
      setRevisionID(draft.id);
    }).catch(error => {
      toasterService.error('Something went wrong getting the email template ...');
    });
  }, [props]);

  // prepopulates send to email defaultValue with user.email
  useEffect(() => {
    setTestData({
      ...testData,
      to: user.email
    });
  }, [setTestEmailPreset, user.email]);
  const isSaveDisabled = () => {
    if (!savedBlock.name) {
      return true;
    } else if (!savedBlock.block_type) {
      return true;
    } else if (savedBlock.block_type === 'premade' && !savedBlock.premade_category) {
      return true;
    } else {
      return false;
    }
  };
  const togglePreview = () => {
    setIsPreview(p => !p);
    TopolPlugin.togglePreview();
  };
  const updateName = ({
    name
  }) => {
    patchEmail(email.id, {
      name
    }).then(() => {
      setEmail({
        ...email,
        name
      });
    });
  };
  return <Scoped css={styles}>
      <div className="wrapper-contain email-container">
        <PageHeaderMui type="Email Template" name={email.name} objIcon={<InboxMoveIcon />} icon={<TemplatesIcon size="lg" />} updateName={updateName} updateNamePermission={hasPermission('template.change_template')} />
        <Content height="100vh">
          {loading && <Loader />}
          {!loading && <Stack direction="row" sx={{
          padding: '12px 24px',
          width: '100%',
          gap: '20px'
        }}>
              <Box sx={{
            flex: '1'
          }}>
                <TextField value={revisionID || ''} select size="small" onChange={e => setRevisionID(e.target.value)} sx={{
              maxWidth: '290px',
              width: '100%'
            }}>
                  {revisions.map(revision => <MenuItem key={revision.key} value={revision.key}>
                      <Stack direction="row" sx={{
                  gap: '5px',
                  alignItems: 'center'
                }}>
                        {revision.icon}
                        {revision.name}
                      </Stack>
                    </MenuItem>)}
                </TextField>
              </Box>
              {/* not working on topols end, disabling until we/they come up with a fix
               <Tooltip title="Undo">
                <IconButton variant="outlined" disabled={!!revision.published_when} onClick={undo}>
                  <UndoIcon />
                </IconButton>
               </Tooltip>)}
               {redoCount > 0 && (
                <Tooltip title="Redo">
                  <IconButton variant="outlined" disabled={!!revision.published_when} onClick={redo}>
                    <RedoIcon />
                  </IconButton>
                </Tooltip>
               ) */}
              <Tooltip title="Toggle preview">
                <IconButton variant="outlined" disabled={!!revision.published_when} onClick={togglePreview}>
                  <PreviewIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send test email">
                <IconButton variant="outlined" onClick={openSendTestModal}>
                  <SendTestIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Save">
                <IconButton variant="outlined" disabled={!!revision.published_when} onClick={() => TopolPlugin.save()}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit details">
                <IconButton variant="outlined" disabled={!!revision.published_when} onClick={() => setShowEditDetails(true)}>
                  <EditIcon fill="#53A6D6" />
                </IconButton>
              </Tooltip>
              <Button disableElevation disabled={!validateEmailDetails() || !!revision.published_when} variant="contained" startIcon={<PublishIcon fill="#ffffff" />} onClick={() => {
            setShowEditDetails(true);
            setEmailDetailsPublish(true);
          }}>
                Publish
              </Button>
            </Stack>}

          {(isPreview || !!revision.published_when) && <Stack direction="row" sx={{
          padding: '12px 24px',
          borderTop: '1px solid #E2E2E2'
        }}>
              {!revision.published_when && <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={togglePreview} sx={{
            flexShrink: 0
          }}>
                  Exit Preview
                </Button>}

              <Box sx={{
            flex: 1,
            textAlign: 'center'
          }}>
                <ButtonGroup>
                  <Button variant={previewDesktop ? 'outlined-active' : 'outlined'} startIcon={<DesktopIcon />} onClick={() => setPreviewDesktop(true)} sx={{
                flexShrink: 0
              }}>
                    Desktop
                  </Button>
                  <Button active selected variant={!previewDesktop ? 'outlined-active' : 'outlined'} startIcon={<MobileIcon />} onClick={() => setPreviewDesktop(false)} sx={{
                flexShrink: 0
              }}>
                    Mobile
                  </Button>
                </ButtonGroup>
              </Box>
              {!revision.published_when && <Box sx={{
            flexShrink: 0,
            width: '155px'
          }} />}
            </Stack>}

          <div id="widget" style={{
          display: !revision.published_when ? 'block' : 'none',
          borderBottomRightRadius: '14px',
          borderBottomLeftRadius: '14px',
          overflow: 'hidden',
          borderTop: '1px solid #E2E2E2',
          margin: '0 auto',
          // first value is mobile preview, second is desktop preview
          width: isPreview && !previewDesktop ? '479px' : '100%',
          // first is mobile preview, second is desktop preview, third is builder view
          height: isPreview && !previewDesktop ? '88%' : isPreview && previewDesktop ? '88%' : '95%'
        }} />

          {!!revision.published_when && <div style={{
          margin: '0 auto',
          width: !previewDesktop ? '479px' : '100%',
          borderTop: '1px solid #E2E2E2'
        }}>
              <IFrame content={revision.body_html} style={{
            height: !!revision.published_when ? '75vh' : '100vh',
            border: 0,
            width: '100%'
          }} />
            </div>}
        </Content>

        <Modal open={!!showEditDetails} onClose={() => setShowEditDetails(false)} title="Email Details" fullWidth actions={<>
              <Button variant="outlined" sx={{
          width: '125px'
        }} onClick={() => setShowEditDetails(false)} size="large">
                Cancel
              </Button>
              <Button disableElevation variant="contained" sx={{
          width: '125px'
        }} size="large" disabled={!validateEmailDetails()} onClick={e => {
          const addressDomain = revision.from_address.split('@').pop();
          const cleanedAddressDomain = addressDomain.replace(/^[^.]*\.(?=\w+\.\w+$)/g, '');
          if (showEditDetails && emailDetailsPublish) {
            if (!domains.some(d => cleanedAddressDomain == d.domain.replace(/^[^.]*\.(?=\w+\.\w+$)/g, ''))) {
              setConfirmEmail(true);
            } else {
              handlePublish();
            }
          } else {
            savePlugin();
          }
        }}>
                {showEditDetails && emailDetailsPublish ? 'Publish' : 'Save'}
              </Button>
            </>}>
          <Stack gap="24px">
            <TextField variant="outlined" label="Email subject" onBlur={e => onBlur(e.target.name, revision.subject)} error={formErrors.subject} required fullWidth value={revision.subject || ''} name="subject" onChange={updateRevision} helperText={formErrors.subject ? 'Email subject is required' : ''} />
            <Stack direction="row" gap="24px">
              <TextField variant="outlined" label="From name" onBlur={e => onBlur(e.target.name, revision.from_name)} error={formErrors.from_name} required fullWidth value={revision.from_name || ''} name="from_name" onChange={updateRevision} helperText={formErrors.from_name ? 'From name is required' : ''} />
              <TextField variant="outlined" label="Email from address" onBlur={e => onBlur(e.target.name, revision.from_address)} error={formErrors.from_address} required fullWidth value={revision.from_address || ''} name="from_address" onChange={updateRevision} helperText={formErrors.from_address ? 'From address is required' : ''} />
            </Stack>

            <TextField variant="outlined" label="Plain message (for older email clients)" fullWidth value={revision.body_plain || ''} name="body_plain" onChange={updateRevision} />

            <TextField variant="outlined" label="Preview text now found in Settings tab" fullWidth value={revision.preview_text || ''} name="preview_text" onChange={updateRevision} disabled={!revision.preview_text} />
          </Stack>
        </Modal>

        <Modal open={!!confirmEmail} onClose={() => setConfirmEmail(false)} title="Email Address Mismatch Warning" fullWidth actions={<>
              <Button variant="outlined" sx={{
          width: '125px'
        }} onClick={() => setConfirmEmail(false)} size="large">
                Cancel
              </Button>
              <Button disableElevation variant="contained" sx={{
          width: '125px'
        }} size="large" onClick={e => {
          setConfirmEmail(false);
          handlePublish();
        }}>
                Confirm
              </Button>
            </>}>
          <Stack gap="24px">
            <Box>
              The email from address <strong>{revision.from_address}</strong> is sending from a different domain than the verified email
              domain(s):
              {domains.map((domain, index) => <Typography key={index}>
                  <strong>{domain.domain} </strong>
                </Typography>)}
              Are you sure you would like to use that email address?
            </Box>
          </Stack>
        </Modal>

        <Modal title="Send Test Email" open={!!showSendTest} onOpen={() => setTestEmailPreset(user.email)} onClose={() => setShowSendTest(false)} actions={<>
              <Button variant="outlined" sx={{
          width: '125px'
        }} onClick={() => setShowSendTest(false)} size="large">
                Cancel
              </Button>
              <Button disableElevation variant="contained" sx={{
          width: '125px'
        }} size="large" disabled={testData.to === null && !testEmailPreset} onClick={sendTest}>
                Send
              </Button>
            </>}>
          <Stack gap="24px">
            <TextField variant="outlined" label="Send email to" fullWidth defaultValue={testEmailPreset} value={testData.to} onChange={e => setTestData({
            ...testData,
            to: e.target.value
          })} />
            <DynamicTypeAheadMui getItems={getSegments} getItemsFilters={{
            company: asCompany.id
          }} placeholder="Customer segment (optional)" displayProperty="name" keyProperty="id" value={testData.segment_id || ''} onChange={segment => setTestData({
            ...testData,
            segment_id: segment?.id
          })} sx={{
            width: '100%'
          }} />

            <TextField variant="outlined" label="Message above test email" fullWidth multiline rows={5} onChange={e => setTestData({
            ...testData,
            message: e.target.value
          })} value={testData.message || ''} />
          </Stack>
        </Modal>
        <Modal open={!!savedBlockModal.isOpen} onClose={() => setSavedBlockModal({
        isOpen: false
      })} title={savedBlockModal.type === 'create' ? 'Create Block' : savedBlockModal.type === 'edit' ? 'Edit Block ' + savedBlock.name : savedBlockModal.type === 'delete' ? `Delete Block ${savedBlock.name}` : ''} actions={<>
              <Button variant="outlined" sx={{
          width: '125px'
        }} onClick={() => {
          setSavedBlock({
            name: null,
            company: asCompany.id,
            definition: null,
            block_type: null,
            premade_category: null,
            img: null,
            template: null
          });
          setSavedBlockModal({
            isOpen: false
          });
        }} size="large">
                Cancel
              </Button>
              <Button disableElevation variant="contained" sx={{
          width: '125px'
        }} size="large" disabled={savedBlockModal.isOpen && isSaveDisabled()} onClick={() => handleSaveBlock()}>
                {savedBlockModal.type === 'delete' ? 'Delete' : 'Save'}
              </Button>
            </>}>
          <Stack gap="24px">
            {(savedBlockModal.type === 'edit' || savedBlockModal.type === 'create') && <>
                <TextField variant="outlined" label="Block Name" fullWidth value={savedBlock.name || ''} onChange={e => setSavedBlock({
              ...savedBlock,
              name: e.target.value
            })} />
                {user.is_superuser && <>
                    <TextField label="Block Type" value={savedBlock.block_type || ''} onChange={e => setSavedBlock({
                ...savedBlock,
                block_type: e.target.value
              })} disabled={savedBlockModal.type === 'edit' ? true : false} select>
                      <MenuItem value="premade">Premade</MenuItem>
                      <MenuItem value="saved">Saved</MenuItem>
                    </TextField>
                    {savedBlock.block_type === 'premade' && <TextField label="Premade Category" value={savedBlock.premade_category || ''} select onChange={e => setSavedBlock({
                ...savedBlock,
                premade_category: e.target.value
              })}>
                        <MenuItem value="headers"> Headers</MenuItem>
                        <MenuItem value="content">Content</MenuItem>
                        <MenuItem value="ecomm">eCommerce</MenuItem>
                        <MenuItem value="footers">Footers</MenuItem>
                      </TextField>}
                  </>}
              </>}
            {savedBlockModal.type === 'delete' && <span>Are you sure you want to delete this block?</span>}
          </Stack>
        </Modal>
        {/*
         <Modal
          open={saveAndCloseModal}
          onClose={() => setSaveAndCloseModal(false)}
          allowBackdropClick
          title={
            'Save & Close'
          }
          type="scroll"
         >
          <div className="modal__body">
            <div className="row">
               <label> You have made changes to this template that are unpublished. Would you like to publish those changes now?</label>
            </div>
            <div className="row pt-3">
              <input
                type="checkbox"
                value={publishReminder}
                checked={publishReminder}
                onChange={e => {
                  localStorage.setItem(
                    'templatePublishChangesReminder',
                    !publishReminder
                  );
                  setPublishReminder(!publishReminder)
                }}
              />
              Do not show this message again this session
            </div>
          </div>
          <div className="modal__actions">
            <Button
              onClick={() => {
                setSaveAndCloseModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              actionType="primary"
              onClick={() => {
                setSaveAndCloseModal(false);
                toasterService.success('Successfully saved template');
                handleCloseClick();
              }}
            >
              No, Just Close
            </Button>
             <Button
              actionType="primary"
              onClick={() => {
                setSaveAndCloseModal(false);
                setShowEditDetails(true);
                setEmailDetailsPublish(true);
              }}
            >
              Yes, Publish
            </Button>
          </div>
         </Modal>
            */}
      </div>
    </Scoped>;
};