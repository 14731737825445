import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment-timezone';
import copy from 'copy-to-clipboard';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { Button } from 'components';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { DeleteIcon } from 'components/mui';
import { getApiKeys, deleteApiKey } from 'shared/common.api';
import { ModalContext } from 'context/modal-context';
import { ApiKeyModal } from './api-key-modal.component';
import { UserStateContext } from 'context/user-state-context';
export const ApiKeys = ({
  companyId
}) => {
  const [keys, setKeys] = useState();
  const {
    show,
    confirm,
    alert
  } = useContext(ModalContext);
  const {
    hasPermission
  } = useContext(UserStateContext);
  useEffect(() => {
    if (!companyId) {
      return;
    }

    // Load API tokens
    getApiKeys(companyId).then(({
      data
    }) => {
      setKeys(data);
    });
  }, [companyId]);
  const generateKey = () => {
    show(ApiKeyModal, {
      companyId
    }).then(data => {
      // TODO Show modal with token
      alert({
        message: <>
          <p>The following API key has been generated. This is the only time it will be shown so keep it in a safe place:</p>
          <code style={{
            display: 'block',
            width: '100%',
            padding: '10px 5px'
          }} onClick={() => {
            if (copy(data.token)) {
              snackbarService.popup({
                type: 'success',
                message: `API key copied to clipboard`
              });
            }
          }}>{data.token}</code>
        </>,
        title: 'API Key Generated'
      }).catch(() => {});
      // Load API tokens
      getApiKeys(companyId).then(({
        data
      }) => {
        setKeys(data);
      });
    }).catch(() => {});
  };
  const confirmDeleteApiKey = key => {
    confirm({
      title: 'Delete API Key',
      message: `Are you sure you want to delete this API key?  This action cannot be undone and the key will immediately stop working.`,
      submitText: 'Delete'
    }).then(() => {
      deleteApiKey(companyId, key.id).then(() => {
        getApiKeys(companyId).then(({
          data
        }) => {
          setKeys(data);
        });
      });
    }).catch(e => {
      console.error(e);
    });
  };
  return <>
    <div className="row">
      <div className="col-6 my-5">
        <strong>API Keys</strong>
      </div>
      <div className="col-6 my-5 text-right">
        {hasPermission('organization.change_company') && <Button onClick={generateKey}>Generate API Key</Button>}
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <Table sx={{
          backgroundColor: "#fff"
        }}>
          <TableHead>
            <TableRow>
              <TableCell width='25%'>
                Name
              </TableCell>
              <TableCell width='25%'>
                Role
              </TableCell>
              <TableCell width='25%'>
                Last Used
              </TableCell>
              <TableCell width='25%'>
                Expiration
              </TableCell>
              <TableCell>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {keys === undefined && <TableRow>
                <TableCell colSpan={5}><em>Loading...</em></TableCell>
              </TableRow>}

            {keys?.length === 0 && <TableRow>
                <TableCell colSpan={5}><em>No API Keys</em></TableCell>
              </TableRow>}

            {keys?.map(key => <TableRow key={key.id}>
                <TableCell>
                  {key.name}
                </TableCell>
                <TableCell>
                  {key.role}
                </TableCell>
                <TableCell>
                  {key.last_used_when ? moment(key.last_used_when).local().format('MMM D, YYYY h:mm A') : <em>Never</em>}
                </TableCell>
                <TableCell>
                  {key.expires_when ? moment(key.expires_when).local().format('MMM D, YYYY h:mm A') : <em>Never</em>}
                </TableCell>
                <TableCell>
                  {hasPermission('organization.change_company') && <IconButton onClick={() => confirmDeleteApiKey(key)}>
                      <DeleteIcon />
                    </IconButton>}
                </TableCell>
              </TableRow>)}

          </TableBody>
        </Table>
      </div>
    </div>
  </>;
};