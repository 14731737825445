import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <mask id="mask0_668_2484" style={{
      maskType: 'alpha'
    }} width="25" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.549 0H24.549V24H0.549z"></path>
      </mask>
      <g fill="#1D252D" mask="url(#mask0_668_2484)">
        <path d="M5.857 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V8.777a1.94 1.94 0 01-.698-.614 1.608 1.608 0 01-.302-.97V4.807c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.385c.505 0 .932.175 1.282.525.35.35.525.778.525 1.283v2.384c0 .37-.1.695-.302.971a1.94 1.94 0 01-.698.614v10.915c0 .505-.175.933-.525 1.283-.35.35-.777.525-1.282.525H5.857zM5.549 9v10.692a.3.3 0 00.087.221.3.3 0 00.221.087h13.385a.3.3 0 00.22-.087.3.3 0 00.087-.22V9h-14zm14.693-1.5a.3.3 0 00.22-.087.3.3 0 00.087-.22V4.807a.3.3 0 00-.086-.221.3.3 0 00-.221-.087H4.857a.3.3 0 00-.221.087.3.3 0 00-.087.22v2.385a.3.3 0 00.087.221.3.3 0 00.221.087h15.385z"></path>
        <path d="M12.886 17.86a.963.963 0 01-.337.058.83.83 0 01-.633-.256l-2.494-2.494a.718.718 0 01-.217-.527c0-.206.073-.382.217-.527a.707.707 0 01.532-.212c.21.003.384.074.522.212l1.323 1.323v-3.7c0-.212.072-.39.216-.534a.726.726 0 01.534-.216c.213 0 .391.072.535.216a.726.726 0 01.215.534v3.7l1.324-1.323a.717.717 0 01.526-.217c.207 0 .382.072.527.217a.707.707 0 01.213.532.725.725 0 01-.213.522l-2.494 2.494a.829.829 0 01-.296.198z"></path>
      </g>
    </svg>;
}
export default Icon;