import { sortBy } from 'lodash';
import { CUSTOMER_ORDER, TRANSACTION_COUPON_ORDER, TRANSACTION_DETAIL_ORDER, TRANSACTION_ORDER, SCHEDULE_ORDER } from './consts';
export const getFixed = modelId => {
  if (modelId === 'customer') {
    return CUSTOMER_ORDER;
  } else if (modelId === 'transaction') {
    return TRANSACTION_ORDER;
  } else if (modelId === 'coupon') {
    return TRANSACTION_COUPON_ORDER;
  } else if (modelId === 'transaction_detail') {
    return TRANSACTION_DETAIL_ORDER;
  } else if (modelId === 'schedule') {
    return SCHEDULE_ORDER;
  }
  return [];
};
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const isFixed = (modelId, fieldId) => {
  return getFixed(modelId).includes(fieldId);
};
export const handleSortFields = (fieldArray, orderArray) => {
  return sortBy(fieldArray, function (item) {
    return orderArray.indexOf(item.id) !== -1 ? orderArray.indexOf(item.id) : fieldArray.length;
  });
};
export const sortFields = fields => {
  return fields.map(field => {
    const fixed = getFixed(field.id);
    if (fixed) {
      field.fields = handleSortFields(field.fields, fixed);
    }
    return field;
  });
};