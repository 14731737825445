import React, { useState } from 'react';
import { CustomerProfileModal } from './customer-profile-modal.component';
import { BuyPhoneNumberModal } from './buy-phone-number-modal.component';
export const PhoneNumberModal = props => {
  if (!props.profile) {
    // We're not loaded yet so skip for now.
    return null;
  }
  const [showBuy, setShowBuy] = useState(props.profile.brand_status === 'APPROVED' && props.profile.campaign_status === 'VERIFIED');
  if (showBuy) {
    // Only allow them to purchase a phone number if the brand status is approved and the campaign is verified
    return <BuyPhoneNumberModal {...props} profile={props.profile} setShowBuy={setShowBuy} />;
  }
  return <CustomerProfileModal {...props} profile={props.profile} setShowBuy={setShowBuy} />;
};