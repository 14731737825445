import React, { useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/loader/loader.component';
import { UserStateContext } from 'context/user-state-context';
import { Scoped } from 'kremling';
import { PageHeader } from '../../components/page-header/page-header';
import { Pagination } from '../../components/pagination/pagination.component';
import styles from './users.styles.scss';
import { getUser, updateUser, getCompanies, getUserCompanies, getRoles, deleteUserCompany, updateUserCompany, createUserCompany } from '../../shared/common.api';
import { StaticTypeAhead } from '../../components/static-type-ahead/static-type-ahead.component';
import { sortBy } from 'lodash';
import { ColorPicker, Icon, PageHeaderMui, Toggle } from 'components';
import utils from 'shared/utils';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { Box, Button } from '@mui/material';
const User = props => {
  const {
    hasPermission,
    user: me,
    triggerNeedsPhoneModal,
    changedPhone
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [roles, setRoles] = useState({});
  const [globalRoles, setGlobalRoles] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    offset: 0,
    limit: 20
  });
  const [initalRender, setInitalRender] = useState(true);
  const [colorPreference, setColorPreference] = useState('');
  const [colorPreferenceSecondary, setColorPreferenceSecondary] = useState('');
  const [reload, setReload] = useState(false);
  useEffect(() => {
    getUser(props.match.params.userId).then(({
      data
    }) => {
      setUser(data);
      setColorPreference(data.color_preference || '#e4e9f1');
      setColorPreferenceSecondary(data.color_preference_secondary || '#ffffff');
      setIsLoading(false);
    });
    getRoles({
      limit: 1
    }).then(({
      data
    }) => {
      const promises = [];
      for (let i = 0; i < Math.ceil(data.count / 100); i++) {
        promises.push(getRoles({
          offset: i * 100,
          limit: 100
        }));
      }
      Promise.all(promises).then(allRoles => {
        const roles = {};
        const globalRoles = [];
        allRoles.forEach(({
          data
        }) => {
          data.results.forEach(role => {
            if (role.company) {
              if (!roles[role.company]) {
                roles[role.company] = [];
              }
              roles[role.company].push(role);
            } else {
              globalRoles.push(role);
            }
          });
          Object.keys(roles).forEach(companyId => {
            roles[companyId] = [...roles[companyId], ...globalRoles].sort((a, b) => {
              if (a.display_name === b.display_name) {
                return 0;
              }
              return a.display_name < b.display_name ? -1 : 1;
            });
          });
          setRoles(roles);
          setGlobalRoles(globalRoles);
        });
      });
    });
  }, []);
  const loadData = () => {
    getCompanies({
      ...paginationParams,
      search
    }).then(({
      data
    }) => {
      if (data) {
        const companies = data.results;
        setCount(data.count);

        // TODO Get user to company for these companies
        getUserCompanies(props.match.params.userId).then(({
          data
        }) => {
          companies.forEach(company => {
            data.results.forEach(utc => {
              if (utc.company === company.id) {
                company.role_name = utc.role_name;
                company.role_id = utc.role;
                company.utc_id = utc.id;
              }
            });
          });
          const sortCompanies = sortBy(companies, 'role_name');
          setCompanies(sortCompanies);
        });
      }
    });
  };
  const updateRole = (companyId, roleId, utcId) => {
    if (roleId === '') {
      if (utcId) {
        // Delete UTC
        deleteUserCompany(utcId).then(loadData);
      } else {
        // ... We shouldn't get here...
      }
    } else {
      if (utcId) {
        // Update UTC
        updateUserCompany(utcId, {
          role: roleId
        }).then(loadData);
      } else {
        // Create UTC
        createUserCompany({
          user: user.id,
          company: companyId,
          role: roleId
        }).then(loadData);
      }
    }
  };
  useEffect(() => {
    loadData();
  }, [search, paginationParams]);
  useEffect(() => {
    if (!!user.id && !initalRender) {
      triggerSave(true);
    }
    if (!!user.id) {
      setInitalRender(false);
    }
  }, [user.beta_opt_in, user.color_preference, user.color_preference_secondary]);
  const triggerSave = triggerReload => {
    const cleanedUser = JSON.parse(JSON.stringify(user));
    cleanedUser.phone = utils.formatIntlPhone(cleanedUser.phone);
    setUser(cleanedUser);
    updateUser(me.is_superuser ? cleanedUser.id : me.id, cleanedUser).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Your user account has been updated'
      });
      if (triggerReload) {
        window.location.reload();
      }
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unable to save your user information.  Please check your inputs and try again.'
      });
    });
  };
  useEffect(() => {
    if (!!changedPhone) {
      const cleanedUser = JSON.parse(JSON.stringify(user));
      cleanedUser.phone = utils.formatIntlPhone(changedPhone);
      setUser(cleanedUser);
    }
  }, [changedPhone]);
  if (isLoading) {
    return <Loader overlay />;
  }
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type="Users" name={!user ? 'User' : `${user.first_name} ${user.last_name}`} icon={<Icon name="custom-segment" size={34} />} />
        <div className="wrapper-scroll user-detail">
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>First Name</label>
                <input className="form-control" type="text" disabled={user.id !== me.id} value={user.first_name || ''} onChange={e => setUser({
                ...user,
                first_name: e.target.value
              })} onBlur={triggerSave} />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Last Name</label>
                <input className="form-control" type="text" disabled={user.id !== me.id} value={user.last_name || ''} onChange={e => setUser({
                ...user,
                last_name: e.target.value
              })} onBlur={triggerSave} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Email</label>
                <input className="form-control" type="text" disabled={true} value={user.email || ''} />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Phone Number</label>
                <input className="form-control" type="text" disabled={!me.is_superuser} value={user.phone || ''} onChange={e => setUser({
                ...user,
                phone: e.target.value
              })} onBlur={triggerSave} />
              </div>
            </div>
            <div className="col-3">
              <Box sx={{
              mt: 2
            }}>
                <Button variant="contained" onClick={e => {
                triggerNeedsPhoneModal(true);
              }}>
                  MFA Settings / Change Phone Number
                </Button>
              </Box>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Beta Site Opt in</label>
                <Toggle className="ml-3" checked={!!user.beta_opt_in} onToggle={() => {
                setUser({
                  ...user,
                  beta_opt_in: !user.beta_opt_in
                });
              }} />
              </div>
            </div>
            {me.is_superuser && <>
                <div className="col-3">
                  <div className="form-group">
                    <label>Background Color</label>
                    {reload && <span className="pl-2">
                        <Button variant="contained" onClick={e => {
                    setUser({
                      ...user,
                      color_preference: colorPreference,
                      color_preference_secondary: colorPreferenceSecondary
                    });
                  }}>
                          Click to save Colors and Apply sitewide
                        </Button>
                      </span>}
                    <div className="color-picker-wrapper">
                      <ColorPicker color={colorPreference} onChange={color => {
                    setColorPreference(color);
                    setReload(true);
                    const root = document.documentElement;
                    root?.style.setProperty('--custom-background-color', color);
                  }} />
                    </div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <label>Background Secondary Color</label>
                    <div className="color-picker-wrapper">
                      <ColorPicker color={colorPreferenceSecondary} onChange={color => {
                    setColorPreferenceSecondary(color);
                    setReload(true);
                    const root = document.documentElement;
                    root?.style.setProperty('--custom-background-color-secondary', color);
                  }} />
                    </div>
                  </div>
                </div>
              </>}
          </div>

          <hr />

          <div className="row">
            <div className="col-6 my-5">
              <strong>User Access</strong>
            </div>
            <div className="col-6 my-5" styles={{
            textAlign: 'right'
          }}>
              <input className="form-control" placeholder="Search Company" value={search} onChange={e => setSearch(e.target.value)} />
            </div>
          </div>
          {companies.length ? <>
              <table className="table-list">
                <thead>
                  <tr>
                    <th width="50%">Company</th>
                    <th width="50%">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.length && companies.map(company => <tr key={company.id}>
                        <td>{company.name}</td>
                        <td>
                          {hasPermission('authentication.change_usertocompany') ? <StaticTypeAhead items={roles[company.id] && [{
                    id: '',
                    display_name: 'Not in Company'
                  }, ...roles[company.id]] || [{
                    id: '',
                    display_name: 'Not in Company'
                  }, ...globalRoles]} placeholder={company && company.role_id ? company.role_name : 'Not in Company'} placeholderStyles={company && company.role_id} displayProperty="display_name" keyProperty="id" value={company && company.role_id ? company.role_name : ''} onChange={role => {
                    role && updateRole(company.id, role.id, company.utc_id);
                  }} /> : company.role_name || <i>Not in Company</i>}
                        </td>
                      </tr>)}
                </tbody>
              </table>
              <Pagination data={{
            count
          }} onChange={setPaginationParams} />
            </> : <span>No company results</span>}
        </div>
      </div>
    </Scoped>;
};
export default User;