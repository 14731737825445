import React, { Component } from 'react';
import { PageHeader } from '../../components/page-header/page-header';
import { getJourney, patchJourney, getJourneyEndStats, getJourneysTags } from '../../shared/common.api';
import { Loader } from '../../components/loader/loader.component';
import { Tags } from '../../components/tags/tags.component';
import { Status } from '../../components/status/status.component';
import moment from 'moment-timezone';
import { Scoped } from 'kremling';
import styles from './customer-journey.styles.scss';
import { Button, Icon, PageHeaderMui, TagModal } from 'components';
import { UserStateContext } from '../../context/user-state-context';
const Style = {
  journeyNavBar: {
    position: 'absolute',
    left: 350,
    display: 'flex',
    alignItems: 'center'
  }
};
const REVISION_MAX = 3;
const ASSET_MAX = 10;
export class CustomerJourneysDetail extends Component {
  static contextType = UserStateContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoadingJourney: true,
      journey: null,
      revisionsExpanded: false,
      segmentTriggersExpanded: false,
      segmentEditsExpanded: false,
      emailTemplatesExpanded: false,
      printTemplatesExpanded: false,
      editDescription: false,
      editTags: false,
      endStats: []
    };
  }
  getJourneyTags = () => {
    getJourneysTags(this.context.asCompany.id).then(({
      data
    }) => {
      const journeysTags = data.results.map((tag, index) => {
        return {
          tag: tag,
          id: index
        };
      });
      this.setState({
        journeysTags
      });
    });
  };
  componentDidMount() {
    this.loadJourney();
  }
  loadJourney = () => {
    this.setState({
      isLoadingJourney: true
    });
    const {
      id
    } = this.props.match.params;
    getJourneyEndStats(id).then(({
      data
    }) => {
      this.setState({
        endStats: data.results
      });
    });
    getJourney(id).then(({
      data
    }) => {
      if (data) {
        this.context.checkCompany(data.company).then(() => {
          this.setState({
            journey: {
              ...data,
              segment_edits: data.segment_edits.filter(j => j.id !== null)
            },
            isLoadingJourney: false
          });
        }).catch(() => {
          // User chose not to switch companies.
        });
      }
    });
    this.getJourneyTags();
  };
  goToJourney = (id, mode) => {
    if (this.context.hasPermission('journey.view_journey')) {
      this.props.history.push({
        pathname: `/customer-journeys/${id}`,
        state: {
          mode
        }
      });
    }
  };
  updateName = name => {
    const {
      id
    } = this.props.match.params;
    return patchJourney(id, {
      name
    }).then(({
      data
    }) => {
      this.setState({
        journey: data
      });
    });
  };
  updateDescripiton = description => {
    const {
      id
    } = this.props.match.params;
    return patchJourney(id, {
      description
    }).then(({
      data
    }) => {
      this.setState({
        journey: data,
        editDescription: false
      });
    });
  };
  render() {
    const {
      isLoadingJourney,
      journey,
      revisionsExpanded,
      segmentTriggersExpanded,
      segmentEditsExpanded,
      emailTemplatesExpanded,
      printTemplatesExpanded,
      editDescription,
      editTags,
      endStats
    } = this.state;
    const {
      id
    } = this.props.match.params;
    return <Scoped css={styles}>
        <div className="wrapper">
          {isLoadingJourney && <Loader overlay />}
          {!isLoadingJourney && <>
              <PageHeaderMui type="Journey Details" name={journey.name} icon={<Icon name="custom-account_tree" size={34} />} />
              <PageHeader name={journey.name} updateName={this.updateName} actions={<>
                    <div className="toggler" style={Style.journeyNavBar}>
                      <Button className={'active'} onClick={() => this.goToJourney(id)}>
                        Details View
                      </Button>
                      <Button onClick={() => this.goToJourney(id, 'DRAFT')}>
                        Draft View
                      </Button>
                      {journey.status !== 'draft' && <Button onClick={() => this.goToJourney(id, 'PUBLISHED')}>
                          Published View
                        </Button>}
                    </div>
                    <div>
                      <Status status={journey.status} className="d-inline ml-3" />
                    </div>
                  </>} />
              <div className="wrapper-scroll journey-detail p-md">
                <div className="row">
                  <div className="col-4">
                    <div className="row form-group mb-5">
                      {editDescription === false && <div className="col-12">
                          <label>
                            Description{' '}
                            <Button actionType="flat" icon="fa-regular-pencil-alt" small className="ml-2" onClick={() => this.setState({
                        editDescription: journey.description || ''
                      })} />
                          </label>
                          <p style={{
                      whiteSpace: 'pre-line'
                    }}>
                            {!journey.description ? <em>No Description</em> : journey.description}
                          </p>
                        </div>}
                      {editDescription !== false && <div className="col-12">
                          <label>
                            Description{' '}
                            <Button icon="fa-regular-check" actionType="flat" small onClick={() => this.updateDescripiton(editDescription)} />
                            <Button icon="fa-regular-times" actionType="flat" small onClick={() => this.setState({
                        editDescription: false
                      })} />
                          </label>
                          <div className="form-group">
                            <textarea className="form-control" value={editDescription} style={{
                        height: '6em'
                      }} onChange={e => this.setState({
                        editDescription: e.target.value
                      })} autoFocus />
                          </div>
                        </div>}
                    </div>
                    <div className="row form-group">
                      <div className="col-12">
                        <label>
                          Tags{' '}
                          <Button actionType="flat" icon="fa-regular-pencil-alt" small className="ml-2" onClick={() => this.setState({
                        editTags: true
                      })} />
                        </label>
                        <div className="mb-3">
                          {!journey.tags ? <em>No Tags</em> : <Tags tags={journey.tags} />}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-8 form-group">
                    <label>End Stats</label>
                    <table className="table-list no-hover mb-3">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Reason</th>
                          <th colSpan={2}>Last 7 Days</th>
                          <th colSpan={2}>Last 30 Days</th>
                          <th colSpan={2}>Last 365 Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        {endStats.map((stat, i) => <tr key={i}>
                            <td>{stat.end_status}</td>
                            <td>{stat.end_reason}</td>
                            <td>{stat.last_7_days}</td>
                            <td>{stat.last_7_days_per}%</td>
                            <td>{stat.last_30_days}</td>
                            <td>{stat.last_30_days_per}%</td>
                            <td>{stat.all_time}</td>
                            <td>{stat.all_time_per}%</td>
                          </tr>)}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-12">
                    <label>Revision History</label>
                    <table className="table-list no-hover mb-3">
                      <tbody>
                        {journey.revisions.filter((r, i) => i < REVISION_MAX || revisionsExpanded).map((revision, index) => <tr key={revision.id}>
                              <td>
                                Revision #{journey.revisions.length - index}
                              </td>
                              <td>
                                <Status status={revision.status} />
                              </td>
                              <td>
                                {!!revision.published_when && <>
                                    Published:{' '}
                                    {moment(revision.published_when).local().format('MMM D, YYYY h:mm A')}
                                  </>}
                              </td>
                              <td>
                                {!!revision.deleted_when && <>
                                    Stopped:{' '}
                                    {moment(revision.deleted_when).local().format('MMM D, YYYY h:mm A')}
                                  </>}
                                {!revision.deleted_when && !!revision.closed_when && <>
                                      Closed:{' '}
                                      {moment(revision.closed_when).local().format('MMM D, YYYY h:mm A')}
                                    </>}
                                {!revision.deleted_when && !revision.closed_when && !!revision.paused_when && <>
                                      Paused:{' '}
                                      {moment(revision.paused_when).local().format('MMM D, YYYY h:mm A')}
                                    </>}
                              </td>
                            </tr>)}
                      </tbody>
                    </table>

                    {journey.revisions.length > REVISION_MAX && <Button actionType="flat" onClick={() => this.setState({
                  revisionsExpanded: !revisionsExpanded
                })}>
                        Show {revisionsExpanded ? 'Less' : 'More'}
                      </Button>}
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-6">
                    <label>Segments Triggered</label>
                    <div>
                      {!journey.segment_triggers.length && <div className="mb-3">
                          <em>No Segments Triggered</em>
                        </div>}
                      {journey.segment_triggers.filter((r, i) => i < ASSET_MAX || segmentTriggersExpanded).map(segment => <Button actionType="primary" key={segment.id} className="mr-3 mb-3" onClick={() => this.props.history.push(`/segments/${segment.id}/edit`)}>
                            {segment.name}
                          </Button>)}
                      {journey.segment_triggers.length > ASSET_MAX && <Button actionType="flat" onClick={() => this.setState({
                    segmentTriggersExpanded: !segmentTriggersExpanded
                  })}>
                          Show {segmentTriggersExpanded ? 'Less' : 'More'}
                        </Button>}
                    </div>
                  </div>
                  <div className="col-6">
                    <label>Segments Edited</label>
                    <div>
                      {!journey.segment_edits.length && <div className="mb-3">
                          <em>No Segments Edited</em>
                        </div>}
                      {journey.segment_edits.filter((r, i) => i < ASSET_MAX || segmentEditsExpanded).map(segment => <Button actionType="primary" key={segment.id} className="mr-3 mb-3" onClick={() => this.props.history.push(`/segments/${segment.id}/edit`)}>
                            {segment.name}
                          </Button>)}
                      {journey.segment_edits.length > ASSET_MAX && <Button actionType="flat" onClick={() => this.setState({
                    segmentEditsExpanded: !segmentEditsExpanded
                  })}>
                          Show {segmentEditsExpanded ? 'Less' : 'More'}
                        </Button>}
                    </div>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-6">
                    <label>Email Templates</label>
                    <div>
                      {!journey.email_templates.length && <div className="mb-3">
                          <em>No Email Templates</em>
                        </div>}
                      {journey.email_templates.filter((r, i) => i < ASSET_MAX || emailTemplatesExpanded).map(template => <Button actionType="primary" key={template.id} className="mr-3 mb-3" onClick={() => this.props.history.push(`/templates/edit/email/${template.id}`)}>
                            {template.name}
                          </Button>)}
                      {journey.email_templates.length > ASSET_MAX && <Button actionType="flat" onClick={() => this.setState({
                    emailTemplatesExpanded: !emailTemplatesExpanded
                  })}>
                          Show {emailTemplatesExpanded ? 'Less' : 'More'}
                        </Button>}
                    </div>
                  </div>
                  <div className="col-6">
                    <label>Print Templates</label>
                    <div>
                      {!journey.print_templates.length && <div className="mb-3">
                          <em>No Print Templates</em>
                        </div>}
                      {journey.print_templates.filter((r, i) => i < ASSET_MAX || printTemplatesExpanded).map(template => <Button actionType="primary" key={template.id} className="mr-3 mb-3" onClick={() => this.props.history.push(`/templates/print/${template.id}`)}>
                            {template.name}
                          </Button>)}
                      {journey.print_templates.length > ASSET_MAX && <Button actionType="flat" onClick={() => this.setState({
                    printTemplatesExpanded: !printTemplatesExpanded
                  })}>
                          Show {printTemplatesExpanded ? 'Less' : 'More'}
                        </Button>}
                    </div>
                  </div>
                </div>
              </div>
              <TagModal onClose={() => this.setState({
            editTags: false
          })} onSuccess={journey => {
            this.setState({
              journey
            });
            this.getJourneyTags();
          }} objectTarget={editTags ? journey : null} getViewTags={getJourneysTags} patchView={patchJourney} viewName={'Journeys'} />
            </>}
        </div>
      </Scoped>;
  }
}