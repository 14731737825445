import React, { Component, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CompaniesList } from './pages/companies/companies-list.component';
import { Company } from './pages/companies/company.component';
import { BroadcastEmailList } from './pages/broadcast/email/broadcast-email-list.component';
import { BroadCastEmailManageList } from './pages/broadcast/manage-email-broadcasts/broadcast-email-manage-list.component';
import { BroadcastSmsList } from './pages/broadcast/sms/broadcast-sms-list.component';
import { DataFields } from './pages/data-fields/data-fields.component';
import { NotificationList } from './pages/notification-list/notification-contact-list.component';
import UsersList from './pages/users/users-list.component';
import Users from './pages/users/users.component';
import { Analytics } from './pages/analytics/analytics.component';
import { CustomerJourneysDetail } from './pages/customer-journeys/customer-journeys-detail.component';
import { CustomerJourneysDetailMui } from './pages/customer-journeys/customer-journeys-detail-mui.component';
import { IntegrationsList } from './pages/integrations/integrations-list.component';
import { CustomerJourneys } from './pages/customer-journeys/customer-journeys.component';
import { CouponsList } from './pages/coupons/coupons-list.component';
import { SegmentsList } from './pages/segments/segments-list.component';
import { EditEmailTemplate } from './pages/templates/edit.email.component';
import { TemplateList } from './pages/templates/template-list.component';
import { TemplateListGallery } from './pages/templates/template-list-gallery.component';
import { MailTemplate } from './pages/templates/options-mail-print/mail-template.component';
import { LocationsList } from './pages/locations/locations-list.component';
import { Customers } from './pages/customers/customers.component';
import { CustomerGroups } from './pages/customer-groups/customer-groups.component';
import { LandingPage } from './pages/assets/landing-pages/landing-page.component';
import { Transactions } from './pages/transactions/transactions.component';
import { TemplateMailPublished } from './pages/templates/template-mail-published.component';
import { WebFormsList } from './pages/web-forms/web-forms-list.component';
import { WebFormEdit } from './pages/web-forms/web-form-edit.component';
import { PhoneNumbers } from './pages/phone-numbers/phone-numbers.component';
import { Domains } from './pages/domains/domains.component';
import { Files } from './pages/files/files.component';
import { Messaging } from './pages/messaging/messaging.component';
import { Imports } from './pages/import/import.component';
import { UploadImport } from './pages/import/upload.component';
import Page404 from './pages/404';
import Page403 from './pages/403';
import RolesList from './pages/roles/roles-list.component';
import Role from './pages/roles/roles.component';
import { Reviews } from './pages/reviews/reviews.component';
import { EditSmsTemplate } from './pages/templates/edit.sms.component';
import { UserStateContext } from 'context/user-state-context';
import { Segment } from './pages/segments/segment.component';
import { EditSegment } from './pages/segments/edit-segment.component';
import { CustomerJourneysListMUI } from './pages/customer-journeys/customer-journeys-list-mui.component';
import { DevTools } from './pages/dev-tools/tools.component';
import { IconList } from './pages/dev-tools/icon-list.component';
import { IntegrationLogs } from './pages/dev-tools/integration-logs.component';
import { EditNotificationContact } from './pages/notification-list/edit-notification-contact.component';
import { SurveyResponsesList } from './pages/surveys/survey-responses-list.component';
import { RepeatTransactionCalculatorList } from './pages/transactions/repeat-transaction-calculator-list.component';
import { RTCBuilder } from './pages/transactions/repeat-transaction-calculator-builder.component';
import { RepeatTransactionCalculatorResults } from './pages/transactions/repeat-transaction-calculator-results.component';
import { TestRunComponent } from './pages/customer-journeys/test-run.component';
import { SurveysSummary } from './pages/surveys/surveys-summary.component';
import { SurveysList } from './pages/surveys/surveys-list.component';
import { SurveysEdit } from './pages/surveys/surveys-edit.component';
import { Community } from './pages/community/community.component';

// import { Billing } from './pages/billing/billing.component';
// import { BillingList } from './pages/billing/billing-list.component';

const PermissionRoute = props => {
  const {
    hasPermission
  } = useContext(UserStateContext);
  return hasPermission(...props.perms) ? <Route {...props} /> : <Route {...props} component={Page403} />;
};
export const AppRoutes = () => {
  const {
    hasMultiAccess
  } = useContext(UserStateContext);
  return <Switch>
      <PermissionRoute path="/analytics" perms={['analytics.view_view']} exact component={Analytics} />
      <Route path="/companies" exact component={CompaniesList} />
      <PermissionRoute path="/companies/:id" perms={['organization.view_company']} exact component={Company} />
      <PermissionRoute path="/email-broadcasts" perms={['email.view_broadcast']} exact component={BroadcastEmailList} />
      {/** Assuming Manage email broadcasts can use the same permission unless otherwise specified. */}
      <PermissionRoute path="/manage-email-broadcasts" perms={['email.view_broadcast']} exact component={BroadCastEmailManageList} />
      <PermissionRoute path="/sms-broadcasts" perms={['communication.view_smsbroadcast']} exact component={BroadcastSmsList} />
      <PermissionRoute path="/data-fields" perms={['organization.view_company']} exact component={DataFields} />
      <PermissionRoute path="/notification-list" perms={['organization.view_company']} exact component={NotificationList} />
      <PermissionRoute path="/notification-list/create" perms={['notification_list.add_notificationcontact']} exact component={EditNotificationContact} />
      <PermissionRoute path="/notification-list/edit/:notificationContactId" perms={['notification_list.view_notificationcontact']} exact component={EditNotificationContact} />
      <PermissionRoute path="/forms" perms={['forms.view_form']} exact component={WebFormsList} />
      <PermissionRoute path="/forms/:formId" perms={['forms.view_form']} exact component={WebFormEdit} />
      <PermissionRoute path="/users" perms={['authentication.view_user']} exact component={UsersList} />
      <PermissionRoute path="/users/:userId" perms={['authentication.view_user']} exact component={Users} />
      <PermissionRoute path="/customer-journeys" perms={['journey.view_journey']} exact component={CustomerJourneysListMUI} />
      <PermissionRoute path="/customer-journeys/details/:id" perms={['journey.view_journey']} component={CustomerJourneysDetailMui} />
      <PermissionRoute path="/customer-journeys/detailsold/:id" perms={['journey.view_journey']} component={CustomerJourneysDetail} />
      <PermissionRoute path="/customer-journeys/:status/:id" perms={['journey.view_journey']} component={CustomerJourneys} />
      <PermissionRoute path="/customer-journeys/:id" perms={['journey.view_journey']} component={CustomerJourneys} />
      <PermissionRoute path="/test-runs/:id" perms={['journey.view_journey']} component={TestRunComponent} />
      <PermissionRoute path="/integrations/:redirect?" perms={['integration.view_credential']} exact component={IntegrationsList} />
      <PermissionRoute path="/coupons" perms={['coupon.view_coupon']} exact component={CouponsList} />
      <PermissionRoute path="/segments" perms={['customer.view_segment']} exact component={SegmentsList} />
      <PermissionRoute path="/segments/:id" perms={['customer.view_segment']} exact component={Segment} />
      <PermissionRoute path="/segments/:id/edit" perms={['customer.view_segment']} exact component={EditSegment} />
      <PermissionRoute path="/segments/:id/copy" perms={['customer.view_segment']} exact component={EditSegment} />
      <PermissionRoute path="/reviews" perms={['reviews.view_review']} exact component={Reviews} />
      <PermissionRoute path="/create-segment" perms={['customer.view_segment']} exact component={EditSegment} />
      <PermissionRoute exact path="/templates/edit/email/:idEmailTemplate" perms={['template.view_template']} component={EditEmailTemplate} />
      <PermissionRoute exact path="/templates/edit/sms/:idEmailTemplate" perms={['template.view_template']} component={EditSmsTemplate} />
      <PermissionRoute exact path="/templates/gallery" perms={['template.view_template']} component={TemplateListGallery} />
      <PermissionRoute exact path="/templates" perms={['template.view_template']} component={TemplateList} />
      <PermissionRoute path="/templates/edit/print/:idMailTemplate" perms={['template.view_template']} exact component={MailTemplate} />
      <PermissionRoute path="/templates/print/:id/:revisionID?" perms={['template.view_template']} exact component={TemplateMailPublished} />
      <PermissionRoute path="/locations" perms={['organization.view_location']} exact component={LocationsList} />
      <PermissionRoute path="/customers" perms={['customer.view_customer']} component={Customers} />
      <PermissionRoute path="/customer-groups" perms={['customer.view_customerrefgroup']} component={CustomerGroups} />
      <PermissionRoute path="/imports/upload" perms={['csvimport.change_import']} component={UploadImport} />
      <PermissionRoute path="/imports" perms={['csvimport.change_import']} component={Imports} />
      <PermissionRoute path="/transactions" perms={['transaction.view_transaction']} component={Transactions} />
      <PermissionRoute path="/phone-numbers" perms={['communication.view_phonenumber']} component={PhoneNumbers} />
      <Route path="/files" component={Files} />
      <PermissionRoute path="/surveys/edit/:id" perms={['surveys.view_survey']} component={SurveysEdit} />
      <PermissionRoute path="/surveys/summary/:id" perms={['surveys.view_survey']} component={SurveysSummary} />
      <PermissionRoute path="/surveys" perms={['surveys.view_survey']} component={SurveysList} />
      <PermissionRoute path="/survey-responses" perms={['surveys.view_survey']} component={SurveyResponsesList} />
      <PermissionRoute path="/domains/:action?/:id?/:hash?" perms={['email.view_domain']} exact component={Domains} />
      <PermissionRoute path="/messaging" perms={['customer.view_two_way_messaging']} exact component={Messaging} />
      <PermissionRoute path="/landing-pages" perms={['integration.manage_landing_pages']} exact component={LandingPage} />
      <PermissionRoute path="/community" perms={['organization.view_company']} exact component={Community} />
      {/* <Route
        path="/billing"
        exact
        component={BillingList}
       />
       <Route
        path="/billing/manage"
        exact
        component={Billing}
       /> */}
      <PermissionRoute path="/roles" perms={['authentication.view_role']} exact component={RolesList} />
      <PermissionRoute path="/roles/:id" perms={['authentication.view_role']} exact component={Role} />
      <PermissionRoute path="/dev-tools" perms={['integration.manage_private_integrations']} exact component={DevTools} />
      <PermissionRoute path="/dev-tools/icon-list" perms={['integration.manage_private_integrations']} exact component={IconList} />
      <PermissionRoute path="/dev-tools/integration-logs" perms={['integration.manage_private_integrations']} exact component={IntegrationLogs} />
      <PermissionRoute path="/repeat-transaction-calculator-list" perms={['repeat_transaction_calculator.view_transactioncalculatorrule']} exact component={RepeatTransactionCalculatorList} />
      <PermissionRoute path="/repeat-transaction-calculator-builder" perms={['repeat_transaction_calculator.add_transactioncalculatorrule']} exact component={RepeatTransactionCalculatorList} />
      <PermissionRoute path="/repeat-transaction-calculator-builder/:id" perms={['repeat_transaction_calculator.add_transactioncalculatorrule']} exact component={RTCBuilder} />
      <PermissionRoute path="/repeat-transaction-calculator-results/:id" perms={['repeat_transaction_calculator.view_transactioncalculatorresults']} exact component={RepeatTransactionCalculatorResults} />
      <PermissionRoute path="/repeat-transaction-calculator-results" perms={['repeat_transaction_calculator.view_transactioncalculatorresults']} exact component={RepeatTransactionCalculatorList} />
      <Route from="/" exact render={() => <Redirect to={hasMultiAccess() ? '/companies' : '/customer-journeys'} />} />
      <Route component={Page404} />
    </Switch>;
};