import React from 'react';
function Icon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
      <mask id="mask0_108_10343" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="-1" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M-0.001 0.123H23.999V24.123H-0.001z"></path>
      </mask>
      <g mask="url(#mask0_108_10343)">
        <path fill="#1D252D" d="M11.249 14.073v3.675a.728.728 0 00.75.75.73.73 0 00.538-.212.73.73 0 00.212-.538v-3.675l1.325 1.325a.77.77 0 00.25.163.61.61 0 00.288.05c.091-.009.183-.03.274-.063a.535.535 0 00.238-.175.72.72 0 00.225-.525.72.72 0 00-.225-.525l-2.5-2.5a.825.825 0 00-.287-.188.943.943 0 00-.675 0 .824.824 0 00-.288.188l-2.5 2.5a.72.72 0 00-.225.525c0 .2.083.375.25.525.15.15.321.23.513.238.191.008.37-.071.537-.238l1.3-1.3zm-4.95 7.55c-.5 0-.925-.175-1.275-.525a1.736 1.736 0 01-.525-1.275v-15.4c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h7.2a1.783 1.783 0 011.275.525l4.2 4.2a1.782 1.782 0 01.525 1.275v11.2c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525h-11.4zm7.2-13.9v-3.6h-7.2c-.067 0-.133.033-.2.1s-.1.133-.1.2v15.4c0 .067.033.133.1.2s.133.1.2.1h11.4c.067 0 .133-.033.2-.1s.1-.133.1-.2v-11.2h-3.6a.869.869 0 01-.638-.263.867.867 0 01-.262-.637z"></path>
      </g>
    </svg>;
}
export default Icon;