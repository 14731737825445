import React, { useEffect, useState } from 'react';
import { Scoped } from 'kremling';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import styles from './root.styles.scss';
import { SignIn } from './pages/auth/sign-in.component';
import { SignInSSO } from './pages/auth/sign-in-sso.component';
import { MFA } from './pages/auth/mfa.component';
import { SidebarMui } from 'components/sidebar/sidebarMui.component';
import { AuthCheck } from './components/auth-check.component';
import { Ifa2019 } from './web-forms/ifa2019.component';
import { FbTest } from './web-forms/fb-test.component';
import { LoyaltyForm } from './web-forms/loyalty.component';
import { Maintenance } from './pages/maintenance/maintenance.component';
import Page404 from './pages/404';
import { Toaster } from './components/toaster/toaster.component';
import { SnackbarToaster } from './components/mui/snackbar/snackbar.component';
import { AppRoutes } from './app-routes';
import { NoCompany } from './pages/no-company.component';
import { Unsubscribe } from './pages/unsubscribe.component';
import { ErrorBoundary } from './components/error-boundary/error-boundary.component';
import { getStatus } from './shared/common.api';
import { VerifyAddress } from './pages/domains/verify-address.component';
import { RejectAddress } from './pages/domains/reject-address.component';
import { Support } from './pages/auth/support.component';
import { ThemeProvider } from '@mui/material/styles';
import theme from './shared/theme';
import { api } from './shared/api';
import { ContextProviders } from './context/context-providers';
export const history = createBrowserHistory();
export const Root = () => {
  const [loadSidebar, setLoadSidebar] = useState(true);
  const [inMaintenance, setInMaintenance] = useState(false);
  useEffect(() => {
    // On all API requests check if there is a network error.
    api.interceptors.response.use(res => {
      return res;
    }, err => {
      if (err.message === 'Network Error') {
        // TURN ON MAINTENANCE MODE
        setInMaintenance(true);
        return Promise.resolve(err);
      }
      return Promise.reject(err);
    });

    // Ping the status endpoint once to check if we're online
    getStatus();

    // TODO move sidebar stuff to sub route component since it doesn't affect all of these routes.
    // sidebar query param to hide the side bar
    const sidebarParam = new URLSearchParams(window.location.search).get('sidebar');
    if (sidebarParam == 'false') {
      setLoadSidebar(false);
    }
  }, []);
  useEffect(() => {
    if (inMaintenance) {
      // We're in maintenance mode so check every few seconds to come back online.
      const maintenanceInterval = setInterval(() => {
        getStatus().then(response => {
          if (!response.message || response.message !== 'Network Error') {
            setInMaintenance(false);
          }
        });
      }, 10000);
      return () => clearInterval(maintenanceInterval);
    }
  }, [inMaintenance]);
  return <>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <ContextProviders>
            <Scoped css={styles}>
              <div>
                <ErrorBoundary>
                  {inMaintenance ? <Maintenance /> : <div className="root">
                      <Switch>
                        {/*Web Forms*/}
                        <Route exact path="/web-forms/ifa2019" component={Ifa2019} />
                        <Route exact path="/web-forms/fb-test" component={FbTest} />
                        <Route exact path="/web-forms/wallabys" render={props => <LoyaltyForm {...props} locationAPIKey="sczYOdTUXTP5cUnt6IfQDQ" title="Wallaby's Smokehouse" />} />
                        <Route exact path="/web-forms/staging" render={props => <LoyaltyForm {...props} locationAPIKey="7M-ojI8CNxHSQWrf1UTYog" title="Justin Rae" />} />
                        <Route exact path="/web-forms/renegade" render={props => <LoyaltyForm {...props} locationAPIKey="QWh1aLdmKKDujOGMif1d4w" title="Renegade Burrito" />} />
                        <Route exact path="/web-forms/cravings-bistro" render={props => <LoyaltyForm {...props} locationAPIKey="P8XvW5IpwrMaHTNk0kAlGQ" title="Cravings Bistro" />} />
                        <Route exact path="/web-forms/rock-creek" render={props => <LoyaltyForm {...props} locationAPIKey="Xl9PLmaZbRZqCelQwy5bhA" title="Rock Creek Pizza" />} />
                        <Route exact path="/web-forms/tushar" render={props => <LoyaltyForm {...props} locationAPIKey="XFks1GQfQTnzvpyePM2RbQ" title="Tushar Brazilian Express" />} />
                        <Route exact path="/web-forms/gandolfos" render={props => <LoyaltyForm {...props} locationAPIKey="Xe547XFyAzQj9T_bdBVAMA" title="Gandolfo's" />} />
                        <Route exact path="/web-forms/everest" render={props => <LoyaltyForm {...props} locationAPIKey="OXVRdAqFAdKWOPZBroj5CQ" title="Everest Curry Kitchen" />} />
                        <Route exact path="/web-forms/cravings-cupcakes" render={props => <LoyaltyForm {...props} locationAPIKey="DaxCLEUhKDAv1aX_vTR4nQ" title="Cravings Alisha's Cupcakes" />} />
                        <Route exact path="/web-forms/oilchangers" render={props => <LoyaltyForm {...props} locationAPIKey="4pR1e9YsjH9STA6vCMh-0g" title="Oil Changers" />} />
                        <Route path="/web-forms" component={Page404} />

                        {/* auth pages */}
                        <Route path="/sign-in" exact component={SignIn} />
                        <Route path="/mfa" exact component={MFA} />
                        <Route path="/auth/sso/:provider" component={SignInSSO} />
                        <Route path="/auth/reset-password/:token" component={SignIn} />
                        <Route path="/auth/unlock/:unlockToken" component={SignIn} />
                        <Route path="/support" exact component={Support} />

                        {/*error pages*/}
                        <Route path="/no-company" exact component={NoCompany} />

                        {/*unsubscribe page*/}
                        <Route path="/unsubscribe/:id" exact component={Unsubscribe} />

                        {/*verify email page*/}
                        <Route path="/address/verify/:id/:hash" exact component={VerifyAddress} />

                        {/*reject email page*/}
                        <Route path="/address/reject/:id" exact component={RejectAddress} />

                        <Route path="/" render={props => <AuthCheck {...props}>
                              {loadSidebar && <SidebarMui {...props} />}
                              <AppRoutes {...props} />
                            </AuthCheck>} />
                      </Switch>
                    </div>}
                </ErrorBoundary>
              </div>
              <Toaster />
              <SnackbarToaster />
            </Scoped>
          </ContextProviders>
        </Router>
      </ThemeProvider>
    </>;
};
export default Sentry.withProfiler(Root);