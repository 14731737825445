import React, { Component, createRef } from 'react';
import { Scoped, k, m } from 'kremling';
import { debounce } from 'lodash';
import { PageHeader } from '../../components/page-header/page-header';
import { Calendar } from 'components/calendar/calendar.component';
import { Button } from '../../components/button/button.component';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { List, ListItemButton, ListItemText, Stack, Box, Typography, IconButton } from '@mui/material';
import { UserStateContext } from 'context/user-state-context';
import { v4 as uuid } from 'uuid';
import { getCompany, patchCompany, getBrands, getNAICS, getCompanyStatuses, getCompanyCancelReasons, getAccounts, getUsers, duplicateCompany, bulkUpdateEmailNameAddress } from '../../shared/common.api';
import { Save } from '../../components/save/save.component';
import { Status } from '../../components/status/status.component';
import { Loader } from '../../components/loader/loader.component';
import TimeSelector from '../../components/time-selector/time-selector.component';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import moment from 'moment-timezone';
import { DynamicTypeAhead } from '../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { StaticTypeAhead } from '../../components/static-type-ahead/static-type-ahead.component';
import _ from 'lodash';
import { Toggle, ColorPicker, Icon, PageHeaderMui } from 'components';
import utils from '../../shared/utils';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { AddIcon, DeleteIcon } from 'components/mui';
import { ApiKeys } from './api-keys.component';
import { ModalFileViewer2 } from 'components/file-viewer/modal-file-viewer.component-mui';
export class Company extends Component {
  static contextType = UserStateContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      company: {},
      naics: [],
      companyStatuses: [],
      users: [],
      companyCancelReasons: [],
      activeModal: false,
      errors: {},
      openModalDuplicate: false,
      openModalApplyEmail: false,
      openModalDisableSMS: false,
      newCompany: {
        newCompanyName: '',
        journeys: true,
        templates: true,
        segments: true,
        dataFields: true,
        savedBlocks: true,
        usersAndRoles: false
      },
      showFileViewer: false,
      selectedAccountId: null,
      selectedBrand: null,
      filteredTimezones: [{
        value: 'America/New_York',
        label: 'Eastern Standard Time (EST)'
      }, {
        value: 'America/Chicago',
        label: 'Central Standard Time (CST)'
      }, {
        value: 'America/Denver',
        label: 'Mountain Standard Time (MST)'
      }, {
        value: 'America/Los_Angeles',
        label: 'Pacific Standard Time (PST)'
      }, {
        value: 'America/Anchorage',
        label: 'Alaska Standard Time (AKST)'
      }, {
        value: 'Pacific/Honolulu',
        label: 'Hawaii Standard Time (HST)'
      }, {
        value: 'Etc/GMT+4',
        label: 'Atlantic Standard Time (AST)'
      }, {
        value: 'Etc/GMT+7',
        label: 'Arizona'
      }],
      listRef: createRef(),
      timezoneSelected: null
    };
  }
  componentDidMount() {
    getCompany(this.props.match.params.id).then(companyRes => {
      if (this.context.user.is_superuser) {
        if (companyRes.data.account) {
          getAccounts({
            id: companyRes.data.account
          }).then(accountsRes => {
            if (accountsRes.data.results.length > 0) {
              this.setState({
                selectedAccountId: accountsRes.data.results[0]
              });
            }
          });
        }
        if (companyRes.data.brand) {
          getBrands({
            id: companyRes.data.brand
          }).then(brandsRes => {
            if (brandsRes.data.results.length > 0) {
              this.setState({
                selectedBrand: brandsRes.data.results[0]
              });
            }
          });
        }
      }
      const company = companyRes.data;
      company.phone = utils.formatPhoneNumber(company.phone) || company.phone;
      company.sms_failure_notify_sms = (company.sms_failure_notify_sms || []).map(phone => ({
        key: uuid(),
        val: phone
      }));
      company.sms_failure_notify_emails = (company.sms_failure_notify_emails || []).map(email => ({
        key: uuid(),
        val: email
      }));
      const timezoneSelected = this.state.filteredTimezones.find(timezone => timezone.value === company?.timezone)?.label;
      this.setState({
        loading: false,
        company,
        companyAsId: this.context.asCompany.id,
        timezoneSelected
      });
    });
    if (this.context.user.is_superuser) {
      Promise.all([getCompanyStatuses(this.props.match.params.id), getNAICS(), getUsers({
        limit: 1000
      }), getCompanyCancelReasons()]).then(([companyStatusesRes, NAICSRes, UsersRes, companyCancelReasonRes]) => {
        this.setState({
          loading: false,
          companyStatuses: companyStatusesRes.data.results,
          naics: NAICSRes.data.results,
          users: UsersRes.data.results,
          companyCancelReasons: companyCancelReasonRes.data.results,
          companyAsId: this.context.asCompany.id
        });
      });
    }
  }
  visualSave = () => {
    return new Promise(resolve => {
      this.visualSaveTimeout = setTimeout(() => {
        resolve();
      }, 1000);
    });
  };
  componentWillUnmount() {
    clearTimeout(this.visualSaveTimeout);
  }
  save = debounce(() => {
    const company = JSON.parse(JSON.stringify(this.state.company));
    const nullKeys = ['email_suppress_bounces', 'email_suppress_days', 'email_suppress_unopens', 'session_timeout', 'days_to_reset_password'];
    nullKeys.forEach(key => {
      if (company[key] === '') company[key] = null;
    });
    company.sms_failure_notify_sms = (company.sms_failure_notify_sms || []).map(phone => phone.val).filter(phone => !!phone && phone !== '');
    company.sms_failure_notify_emails = (company.sms_failure_notify_emails || []).map(email => email.val).filter(email => !!email && email !== '');
    this.setState({
      saving: true,
      errors: {}
    });
    Promise.all([patchCompany(company.id, company), this.visualSave()]).then(([{
      data: company
    }, ...args]) => {
      if (this.context.asCompany.id === company.id) {
        this.context.setAsCompany(company);
      }
      this.setState({
        saving: false
      });
    }).catch(patchError => {
      this.setState({
        errors: patchError.response.data,
        saving: false
      });
    });
  }, 1000);
  update = (key, val) => {
    this.setState({
      company: {
        ...this.state.company,
        [key]: val
      }
    }, this.save);
  };
  applyEmail = () => {
    const {
      company
    } = this.state;

    // TODO actually apply email to all
    bulkUpdateEmailNameAddress(company.default_email, company.default_from_name, company.id).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Emails updated.'
      });
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unable to update emails.  Check that the email address is valid and try again.'
      });
    });
  };
  handleDuplicateCompany = () => {
    const {
      newCompany,
      company
    } = this.state;
    return duplicateCompany({
      name: newCompany.newCompanyName,
      status: newCompany.newCompanyStatus,
      account_manager: newCompany.newCompanyAccountManager,
      contract_original_signed_date: newCompany.newCompanyContractSignedDate ? newCompany.newCompanyContractSignedDate.format('YYYY-MM-DD') : null,
      contract_signed_mrr: newCompany.newCompanyContractSignedMRR,
      journeys: newCompany.journeys,
      templates: newCompany.templates,
      segments: newCompany.segments,
      data_fields: newCompany.dataFields,
      saved_blocks: newCompany.savedBlocks,
      user_roles: newCompany.usersAndRoles
    }, company.id);
  };
  addItem = key => {
    const company = JSON.parse(JSON.stringify(this.state.company));
    if (!company[key]) {
      company[key] = [];
    }
    company[key].push({
      key: uuid(),
      val: ''
    });
    this.setState({
      company
    });
  };
  deleteItem = (key, index) => {
    const company = JSON.parse(JSON.stringify(this.state.company));
    company[key].splice(index, 1);
    this.setState({
      company
    });
    this.save();
  };
  updateItem = (key, index, val) => {
    const company = JSON.parse(JSON.stringify(this.state.company));
    company[key][index].val = val;
    this.setState({
      company
    });
  };
  getTimezoneOffset = date => {
    if (!date) return null;
    const d = new Date(date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    return d;
  };
  selectTimezone = (timezone, label) => {
    this.update('timezone', timezone);
    this.setState({
      timezoneSelected: label
    });
    this.state.listRef.current.close();
  };
  render() {
    const {
      loading,
      company,
      activeModal,
      companyAsId,
      saving,
      naics,
      companyStatuses,
      users,
      companyCancelReasons,
      errors,
      newCompany,
      openModalDuplicate,
      openModalApplyEmail,
      showFileViewer,
      openModalDisableSMS,
      selectedAccountId,
      selectedBrand,
      timezoneSelected
    } = this.state;
    const selectedAccountManager = users.find(user => user.id === company.account_manager);
    const selectedBillingContact = users.find(user => user.id === company.billing_contact);
    const selectedPrimaryContact = users.find(user => user.id === company.primary_contact);
    const selectedStatusId = companyStatuses.find(statuses => statuses.id === company.status);
    const selectedCompanyCancelReason = companyCancelReasons.find(companyCancelReason => companyCancelReason.name === company.companyCancelReason);
    const selectedNAICS = naics.find(naics => naics.id === company.naics);
    const workingAs = companyAsId === company.id;
    const canEdit = company && this.context.hasPermission('organization.change_company');
    const timezone = moment.tz.names().map(t => {
      return {
        id: t,
        value: t
      };
    });
    return <Scoped css={css}>
        <div className="wrapper">
          {loading && <Loader overlay background="#fff" />}
          <PageHeaderMui type="Company" name={company.name || ''} icon={<Icon name="custom-dashboard" size={34} />} updateName={canEdit ? name => {
          this.update('name', name.name);
        } : null} updateNamePermission={canEdit} />
          <PageHeader name={company.name || ''} updateName={canEdit ? name => this.update('name', name) : null} actions={<>
                <Save saving={saving} />
                {this.context.user.is_superuser && <Button onClick={() => this.setState({
            openModalDuplicate: true
          })}>Duplicate</Button>}
                {workingAs ? <Status data-cy="btn-work-as" type="success">
                    Working As
                  </Status> : <Button data-cy="btn-work-as" onClick={() => this.context.setAsCompany(company)}>
                    Work as this company
                  </Button>}
                {this.context.user.is_superuser && <div className="toggler">
                    <Button className={m('active', company.is_active)} onClick={company.is_active ? () => {} : () => this.setState({
              activeModal: true
            })}>
                      Active
                    </Button>
                    <Button className={m('active', !company.is_active)} onClick={company.is_active ? () => this.setState({
              activeModal: true
            }) : () => {}}>
                      Inactive
                    </Button>
                  </div>}
              </>} />
          {company.id && <div className="company-list">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Address 1</label>
                    <input onChange={e => this.update('address1', e.target.value)} className="form-control" type="text" disabled={!canEdit} value={company.address1 || ''} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Address 2</label>
                    <input onChange={e => this.update('address2', e.target.value)} className="form-control" type="text" disabled={!canEdit} value={company.address2 || ''} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>City</label>
                    <input onChange={e => this.update('city', e.target.value)} className="form-control" type="text" disabled={!canEdit} value={company.city || ''} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>State</label>
                    <input onChange={e => this.update('state', e.target.value)} className="form-control" type="text" disabled={!canEdit} value={company.state || ''} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Zip</label>
                    <input onChange={e => this.update('zip_code', e.target.value)} className="form-control" type="number" disabled={!canEdit} value={company.zip_code || ''} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Phone</label>
                    <input onChange={e => this.update('phone', e.target.value)} className="form-control" type="phone" disabled={!canEdit} value={company.phone || ''} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Mail Return Address</label>
                    <select value={company.use_mailhouse_return_address} className="form-control" disabled={!canEdit} onChange={e => this.update('use_mailhouse_return_address', e.target.value === 'true')}>
                      <option value="true">Mail House address</option>
                      <option value="false">Company address</option>
                    </select>
                    <div className="text-sm mt-sm">
                      {company.use_mailhouse_return_address ? <em>(Your business will not need to worry about receiving any of the return mail)</em> : <em>(As listed on this page)</em>}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Timezone</label>
                    <Dropdown size="md" ref={this.state.listRef} contentHeight={280} trigger={() => <Button block dropdown>
                          {this.state.timezoneSelected || 'Select Timezone'}
                        </Button>} content={() => <List sx={{
                  height: '100%',
                  width: '100%'
                }}>
                          {this.state.filteredTimezones.map(timezone => <ListItemButton sx={{
                    height: '100%',
                    width: '100%'
                  }} key={timezone.value} onClick={() => this.selectTimezone(timezone.value, timezone.label)}>
                              <ListItemText primary={timezone.label} />
                            </ListItemButton>)}
                        </List>}></Dropdown>
                    {/* <StaticTypeAhead
                      items={timezone}
                      placeholder={company.timezone ? company.timezone : 'Choose Timezone'}
                      displayProperty="value"
                      keyProperty="id"
                      value={company ? company.timezone : ''}
                      onChange={(timezone) => timezone && this.update('timezone', timezone.value)}
                     /> */}
                    {errors.timezone && errors.timezone.map((error, i) => <p key={i} className="pt-sm invalid-input">
                          {error}
                        </p>)}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Default Email Address</label>
                    <input onChange={e => this.update('default_email', e.target.value)} className="form-control" type="email" disabled={!canEdit} value={company.default_email || ''} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group d-flex align-items-bottom">
                    <div className="flex-grow">
                      <label>Default From Name</label>
                      <input onChange={e => this.update('default_from_name', e.target.value)} className="form-control" type="text" disabled={!canEdit} value={company.default_from_name || ''} />
                    </div>
                    <Button className="mx-2" onClick={() => this.setState({
                  openModalApplyEmail: true
                })} disabled={saving || !company.default_email || company.default_email === '' || !company.default_from_name || company.default_from_name === ''}>
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <div className="form-group">
                    <label>Require Two-factor Authentication</label>
                    <Toggle className="ml-3" disabled={!canEdit} checked={!!company.require_mfa} onToggle={() => {
                  this.update('require_mfa', !company.require_mfa);
                }} />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label>Session Timeout Length (in minutes)</label>
                    <input onChange={e => this.update('session_timeout', e.target.value)} className="form-control" type="number" disabled={!canEdit} value={company.session_timeout || ''} />
                    <div className="text-sm mt-sm">Number of minutes of inactivity before a session closes.</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Password Expiration (in days)</label>
                    <input onChange={e => this.update('days_to_reset_password', e.target.value)} className="form-control" type="number" disabled={!canEdit} value={company.days_to_reset_password || ''} />
                    <div className="text-sm mt-sm">Maximum number of days a password is valid before it needs to be reset.</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Logo</label>
                    <div>
                      {company.company_logo && <img onClick={() => {
                    if (canEdit) this.setState({
                      showFileViewer: true
                    });
                  }} src={company.company_logo} height="50px" />}
                      {!company.company_logo && canEdit && <Button onClick={() => this.setState({
                    showFileViewer: true
                  })}>Add Logo</Button>}
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label>Primary Color</label>
                    <ColorPicker disabled={!canEdit} color={company ? company.primary_color : null} onChange={color => this.update('primary_color', color)} />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label>Secondary Color</label>
                    <ColorPicker disabled={!canEdit} color={company ? company.secondary_color : null} onChange={color => this.update('secondary_color', color)} />
                  </div>
                </div>
              </div>
              <hr />
              {this.context.user.is_superuser && <>
                  <div className="col-12 my-5">
                    <strong>Admin Settings</strong>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Status ID</label>
                        <DynamicTypeAhead getItems={getCompanyStatuses} getItemsFilters={{
                    company: this.context.asCompany.id
                  }} placeholder={selectedStatusId ? `${selectedStatusId.name}` : 'Select a Status ID'} displayProperty="name" keyProperty="id" value={selectedStatusId ? selectedStatusId.id : null} onChange={statusId => {
                    this.update('status', statusId ? statusId.id : statusId);
                  }} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Account Manager</label>
                        <DynamicTypeAhead getItems={getUsers} placeholder={selectedAccountManager ? `${selectedAccountManager.email}` : 'Select an Account Manager'} displayProperty="email" keyProperty="id" value={selectedAccountManager ? selectedAccountManager.id : null} onChange={user => {
                    this.update('account_manager', user ? user.id : null);
                  }} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Account ID</label>
                        <DynamicTypeAhead getItems={getAccounts} getItemsFilters={{
                    company: this.context.asCompany.id
                  }} placeholder={selectedAccountId ? `${selectedAccountId.name}` : 'Select a Account ID'} displayProperty="name" keyProperty="id" value={selectedAccountId ? selectedAccountId.id : null} onChange={account => {
                    this.update('account', account ? account.id : account);
                  }} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Brand</label>
                        <DynamicTypeAhead getItems={getBrands} getItemsFilters={{
                    company: this.context.asCompany.id
                  }} placeholder={selectedBrand ? `${selectedBrand.name}` : 'Select a Brand'} displayProperty="name" keyProperty="id" value={selectedBrand ? selectedBrand.id : null} onChange={brand => {
                    this.update('brand', brand ? brand.id : brand);
                  }} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Industry</label>
                        <DynamicTypeAhead getItems={getNAICS} getItemsFilters={{
                    company: this.context.asCompany.id
                  }} placeholder={selectedNAICS ? `${selectedNAICS.title}` : 'Select an Industry'} displayProperty="title" keyProperty="id" value={selectedNAICS ? selectedNAICS.id : null} onChange={naic => {
                    this.update('naics', naic ? naic.id : naic);
                  }} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Contract Original Signed</label>
                        <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                              {company.contract_original_signed_date ? moment(company.contract_original_signed_date).format('MM/DD/YYYY') : 'Select Contract Original Signed'}
                            </Button>} content={({
                    close
                  }) => <div className="p-sm">
                              <Calendar value={this.getTimezoneOffset(company.contract_original_signed_date)} minDate={null} onChange={date => {
                      this.update('contract_original_signed_date', moment(date).format('YYYY-MM-DD'));
                      close();
                    }} />
                              <Button actionType="primary" onClick={() => {
                      this.update('contract_original_signed_date', null);
                      close();
                    }}>
                                Clear Date
                              </Button>
                            </div>} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Contract Signed MRR</label>
                        <input type="number" className="form-control" onChange={e => this.update('contract_signed_mrr', e.target.value)} value={company.contract_signed_mrr ? company.contract_signed_mrr : ''} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Billed First Date</label>
                        <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                              {company.billed_first_date ? moment(company.billed_first_date).format('MM/DD/YYYY') : 'Select Billed First Date'}
                            </Button>} content={({
                    close
                  }) => <div className="p-sm">
                              <Calendar value={this.getTimezoneOffset(company.billed_first_date)} minDate={null} onChange={date => {
                      this.update('billed_first_date', moment(date).format('YYYY-MM-DD'));
                      close();
                    }} />
                              <Button actionType="primary" onClick={() => {
                      this.update('billed_first_date', null);
                      close();
                    }}>
                                Clear Date
                              </Button>
                            </div>} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Cancel Date</label>
                        <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                              {company.cancel_date ? moment(company.cancel_date).format('MM/DD/YYYY') : 'Select Cancel Date'}
                            </Button>} content={({
                    close
                  }) => <div className="p-sm">
                              <Calendar value={this.getTimezoneOffset(company.cancel_date)} minDate={null} onChange={date => {
                      this.update('cancel_date', moment(date).format('YYYY-MM-DD'));
                      close();
                    }} />
                              <Button actionType="primary" onClick={() => {
                      this.update('cancel_date', null);
                      close();
                    }}>
                                Clear Date
                              </Button>
                            </div>} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Cancel Reason ID</label>
                        <DynamicTypeAhead getItems={getCompanyCancelReasons} getItemsFilters={{
                    company: this.context.asCompany.id
                  }} placeholder={selectedCompanyCancelReason ? `${selectedCompanyCancelReason.name}` : 'Select a Cancel Reason Id'} displayProperty="name" keyProperty="id" value={selectedCompanyCancelReason ? selectedCompanyCancelReason.id : null} onChange={cancelReason => {
                    this.update('company_cancel_reason', cancelReason ? cancelReason.id : cancelReason);
                  }} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Billed Last Date</label>
                        <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                              {company.billed_last_date ? moment(company.billed_last_date).format('MM/DD/YYYY') : 'Select Billed Last Date'}
                            </Button>} content={({
                    close
                  }) => <div className="p-sm">
                              <Calendar value={this.getTimezoneOffset(company.billed_last_date)} minDate={null} onChange={date => {
                      this.update('billed_last_date', moment(date).format('YYYY-MM-DD'));
                      close();
                    }} />
                              <Button actionType="primary" onClick={() => {
                      this.update('billed_last_date', null);
                      close();
                    }}>
                                Clear Date
                              </Button>
                            </div>} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Customer End Date</label>
                        <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                              {company.customer_end_date ? moment(company.customer_end_date).format('MM/DD/YYYY') : 'Select Customer End Date'}
                            </Button>} content={({
                    close
                  }) => <div className="p-sm">
                              <Calendar value={this.getTimezoneOffset(company.customer_end_date)} minDate={null} onChange={date => {
                      this.update('customer_end_date', moment(date).format('YYYY-MM-DD'));
                      close();
                    }} />
                              <Button actionType="primary" onClick={() => {
                      this.update('customer_end_date', null);
                      close();
                    }}>
                                Clear Date
                              </Button>
                            </div>} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Entity Data</label>
                        <textarea className="form-control" rows={10} onChange={e => this.update('entity_data', e.target.value)} value={company.entity_data ? company.entity_data : ''} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Cohort Date</label>
                        <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                              {company.cohort_date ? moment(company.cohort_date).format('MM/DD/YYYY') : 'Select Cohort Date'}
                            </Button>} content={({
                    close
                  }) => <div className="p-sm">
                              <Calendar value={this.getTimezoneOffset(company.cohort_date)} minDate={null} onChange={date => {
                      this.update('cohort_date', moment(date).format('YYYY-MM-DD'));
                      close();
                    }} />
                              <Button actionType="primary" onClick={() => {
                      this.update('cohort_date', null);
                      close();
                    }}>
                                Clear Date
                              </Button>
                            </div>} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Commission End Date</label>
                        <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                              {company.commission_end_date ? moment(company.commission_end_date).format('MM/DD/YYYY') : 'Select Commission End Date'}
                            </Button>} content={({
                    close
                  }) => <div className="p-sm">
                              <Calendar value={this.getTimezoneOffset(company.commission_end_date)} minDate={null} onChange={date => {
                      this.update('commission_end_date', moment(date).format('YYYY-MM-DD'));
                      close();
                    }} />
                              <Button actionType="primary" onClick={() => {
                      this.update('commission_end_date', null);
                      close();
                    }}>
                                Clear Date
                              </Button>
                            </div>} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Commission Percent</label>
                        <input type="number" className="form-control" onChange={e => this.update('commission_percent', e.target.value)} value={company.commission_percent ? company.commission_percent : ''} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Contract Closer</label>
                        <input type="text" className="form-control" onChange={e => this.update('contract_closer', e.target.value)} value={company.contract_closer ? company.contract_closer : ''} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Freshsales ID</label>
                        <input type="text" className="form-control" onChange={e => this.update('freshsales_id', e.target.value)} value={company.freshsales_id ? company.freshsales_id : ''} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Lead Source</label>
                        <input type="text" className="form-control" onChange={e => this.update('lead_source', e.target.value)} value={company.lead_source ? company.lead_source : ''} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Mail Threshold</label>
                        <input type="text" className="form-control" onChange={e => this.update('mail_limit', e.target.value)} value={company.mail_limit ? company.mail_limit : ''} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>SMS Threshold</label>
                        <input type="text" className="form-control" onChange={e => this.update('sms_limit', e.target.value)} value={company.sms_limit ? company.sms_limit : ''} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Billing Contact</label>
                        <DynamicTypeAhead getItems={getUsers} placeholder="Select an Billing Contact" displayProperty="email" keyProperty="id" value={company.billing_contact} onChange={user => {
                    this.update('billing_contact', user ? user.id : null);
                  }} getItemsFilters={{
                    company: this.context.asCompany.id
                  }} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Primary Contact</label>
                        <DynamicTypeAhead getItems={getUsers} placeholder="Select an Primary Contact" displayProperty="email" keyProperty="id" value={company.primary_contact ? company.primary_contact : null} onChange={user => {
                    this.update('primary_contact', user ? user.id : null);
                  }} getItemsFilters={{
                    company: this.context.asCompany.id
                  }} />
                      </div>
                    </div>
                  </div>
                  <hr />
                </>}
              <div className="row">
                <div className="col-12 my-5">
                  <strong>SMS Settings</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>SMS Enabled</label>
                    <Toggle className="ml-3" disabled={!canEdit} checked={!!company.sms_enabled} onToggle={() => {
                  company.sms_enabled ? this.setState({
                    openModalDisableSMS: true
                  }) : this.update('sms_enabled', true);
                }} />
                    {errors.sms_enabled && errors.sms_enabled.map((error, i) => <p key={i} className="pt-sm invalid-input">
                          {error}
                        </p>)}
                    {!errors.sms_enabled && <div className="text-sm mt-sm">
                        Enable or disable all SMS and MMS messaging. Warning: be absolutely sure you want to do this.
                      </div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>SMS Maximum Failure Rate</label>
                    <input onChange={e => this.update('sms_max_failure_rate', e.target.value)} disabled={!canEdit} className="form-control" type="number" value={company.sms_max_failure_rate || ''} />
                    {errors.sms_max_failure_rate && errors.sms_max_failure_rate.map((error, i) => <p key={i} className="pt-sm invalid-input">
                          {error}
                        </p>)}
                    {!errors.sms_max_failure_rate && <div className="text-sm mt-sm">
                        On a scale of 1 - 100, what percentage of messages fail before triggering an SMS failure alert?
                      </div>}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>SMS Failure Action</label>
                    <select onChange={e => this.update('sms_failure_action', e.target.value)} disabled={!canEdit} className="form-control" type="number" value={company.sms_failure_action || ''}>
                      <option value={null}>Nothing</option>
                      <option value="notify">Notify only</option>
                      <option value="notify_disable">Notify and disable</option>
                    </select>
                    {errors.sms_failure_action && errors.sms_failure_action.map((error, i) => <p key={i} className="pt-sm invalid-input">
                          {error}
                        </p>)}
                    {!errors.sms_failure_action && <div className="text-sm mt-sm">Action to perform when the threshold is passed.</div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <Stack direction="row" alignItems="center" gap="5px">
                      <label style={{
                    margin: 0,
                    padding: 0
                  }}>SMS Failure Email Notifications</label>
                      {canEdit && <IconButton onClick={() => this.addItem('sms_failure_notify_emails')}>
                          <AddIcon />
                        </IconButton>}
                    </Stack>

                    {!(company.sms_failure_notify_emails || []).length && <div>
                        <em>No emails specified</em>
                      </div>}
                    {(company.sms_failure_notify_emails || []).map((email, i) => <Stack direction="row" alignItems="center" gap="5px" key={email.key}>
                        <input disabled={!canEdit} onBlur={this.save} className="form-control" onChange={e => this.updateItem('sms_failure_notify_emails', i, e.target.value)} type="text" value={email.val} />
                        {canEdit && <IconButton onClick={() => this.deleteItem('sms_failure_notify_emails', i)}>
                            <DeleteIcon />
                          </IconButton>}
                      </Stack>)}

                    {!!Object.values(errors.sms_failure_notify_emails || {}).length && Object.values(errors.sms_failure_notify_emails)[0].map((error, i) => <p key={i} className="pt-sm invalid-input">
                          {error}
                        </p>)}
                    {!Object.values(errors.sms_failure_notify_emails || {}).length && <div className="text-sm mt-sm">List of emails to receive an email notification when the threshold is passed.</div>}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <Stack direction="row" alignItems="center" gap="5px">
                      <label style={{
                    margin: 0,
                    padding: 0
                  }}>SMS Failure SMS Notifications</label>
                      {canEdit && <IconButton onClick={() => this.addItem('sms_failure_notify_sms')}>
                          <AddIcon />
                        </IconButton>}
                    </Stack>
                    {!(company.sms_failure_notify_sms || []).length && <div>
                        <em>No phone numbers specified</em>
                      </div>}
                    {(company.sms_failure_notify_sms || []).map((phone, i) => <Stack direction="row" alignItems="center" gap="5px" key={phone.key}>
                        <input disabled={!canEdit} onBlur={this.save} className="form-control" onChange={e => this.updateItem('sms_failure_notify_sms', i, e.target.value)} type="text" value={phone.val} />
                        {canEdit && <IconButton onClick={() => this.deleteItem('sms_failure_notify_sms', i)}>
                            <DeleteIcon />
                          </IconButton>}
                      </Stack>)}
                    {!!Object.values(errors.sms_failure_notify_sms || {}).length && Object.values(errors.sms_failure_notify_sms)[0].map((error, i) => <p key={i} className="pt-sm invalid-input">
                          {error}
                        </p>)}
                    {!Object.values(errors.sms_failure_notify_email || {}).length && <div className="text-sm mt-sm">List of phone numbers to text when the threshold is passed.</div>}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12 my-5">
                  <strong>Email Suppression Settings</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12 my-5">
                  Suppressed email addresses will block sending to those addresses. Failed email attempts may still be charged as if the
                  message was successful. Addresses are automatically un-suppressed if the customer appears in a new transaction.
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Bounced Messages Threshold</label>
                    <input onChange={e => this.update('email_suppress_bounces', e.target.value)} className="form-control" disabled={!canEdit} type="number" value={company.email_suppress_bounces || ''} />
                    {errors.email_suppress_bounces && errors.email_suppress_bounces.map((error, i) => <p key={i} className="pt-sm invalid-input">
                          {error}
                        </p>)}
                    {!errors.email_suppress_bounces && <div className="text-sm mt-sm">
                        Number of consecutive failed send attempts for a customer's email address to be marked bad.
                      </div>}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Unopened Messages Threshold</label>
                    <input onChange={e => this.update('email_suppress_unopens', e.target.value)} className="form-control" disabled={!canEdit} type="number" value={company.email_suppress_unopens || ''} />
                    {errors.email_suppress_unopens && errors.email_suppress_unopens.map((error, i) => <p key={i} className="pt-sm invalid-input">
                          {error}
                        </p>)}
                    {!errors.email_suppress_unopens && <div className="text-sm mt-sm">
                        Number of consecutive unopened messages for a customer's email address to be marked bad.
                      </div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Unopened Days Threshold</label>
                    <input onChange={e => this.update('email_suppress_days', e.target.value)} className="form-control" disabled={!canEdit} type="number" value={company.email_suppress_days || ''} />
                    {errors.email_suppress_days && errors.email_suppress_days.map((error, i) => <p key={i} className="pt-sm invalid-input">
                          {error}
                        </p>)}
                    {!errors.email_suppress_days && <div className="text-sm mt-sm">
                        Number of consecutive days a message has not been opened for a customer's email address to be marked bad.
                      </div>}
                  </div>
                </div>
              </div>
              <hr />

              <ApiKeys companyId={company.id} />
            </div>}

          <ModalDialog open={activeModal} onClose={() => this.setState({
          activeModal: false
        })} allowBackdropClick title={`${company.is_active ? 'Deactivate' : 'Activate'} Company`} onSubmit={() => this.update('is_active', !company.is_active)} submitText={company.is_active ? 'Deactivate' : 'Activate'}>
            Are you sure you want to {company.is_active ? 'deactivate' : 'activate'} this company?
          </ModalDialog>
          <ModalDialog open={openModalApplyEmail} onClose={() => this.setState({
          openModalApplyEmail: false
        })} allowBackdropClick title="Update All Email Templates" onSubmit={() => this.applyEmail()} submitText="Confirm">
            Are you sure you want to update all of your existing email templates to use these values?
            <br />
            From Address ={company.default_email} <br />
            From Name = {company.default_from_name}
          </ModalDialog>
          <ModalDialog open={openModalDisableSMS} onClose={() => this.setState({
          openModalDisableSMS: false
        })} allowBackdropClick title="Disable All SMS/MMS Messages" onSubmit={() => this.update('sms_enabled', false)} submitText="Confirm">
            Are you sure you want to disable all SMS/MMS messages? This will immediately clear out all SMS and MMS messages from the queue
            and they are NOT recoverable. No automated SMS messages will be sent.
          </ModalDialog>
          <ModalDialog open={openModalDuplicate} onClose={() => this.setState({
          openModalDuplicate: false
        })} allowBackdropClick title={`Duplicate Company`} onSubmit={this.handleDuplicateCompany} submitText="Create Company" submitDisabled={!newCompany.newCompanyName || !newCompany.newCompanyStatus}>
            <div className="form-group mb-sm">
              <label>New Company Name</label>
              <input className="form-control" name="newCompanyName" defaultValue={''} onChange={e => this.setState({
              newCompany: {
                ...newCompany,
                newCompanyName: e.target.value
              }
            })} />
            </div>

            <div className="form-group">
              <label>
                Status <span className="form-error">*</span>
              </label>
              <DynamicTypeAhead getItems={getCompanyStatuses} placeholder="Choose Company Status" displayProperty="name" keyProperty="id" value={newCompany.newCompanyStatus || null} onChange={manager => this.setState({
              newCompany: {
                ...newCompany,
                newCompanyStatus: manager.id
              }
            })} />
            </div>
            <div className="form-group">
              <label>Account Manager</label>
              <DynamicTypeAhead getItems={getUsers} placeholder="Choose Account Manager" displayProperty="email" keyProperty="id" value={newCompany.newCompanyAccountManager || null} onChange={manager => this.setState({
              newCompany: {
                ...newCompany,
                newCompanyAccountManager: manager ? manager.id : null
              }
            })} />
            </div>
            <div className="form-group">
              <label>Contract Signed Date</label>
              <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                    {newCompany.newCompanyContractSignedDate ? newCompany.newCompanyContractSignedDate.format('LL') : 'Select Date'}
                  </Button>} content={({
              close
            }) => <div className="p-sm">
                    <Calendar value={this.getTimezoneOffset(company.contract_original_signed_date)} minDate={null} onChange={date => {
                this.setState({
                  newCompany: {
                    ...newCompany,
                    newCompanyContractSignedDate: moment(date)
                  }
                });
                close();
              }} />
                    <Button actionType="primary" onClick={() => {
                this.update('contract_original_signed_date', null);
                close();
              }}>
                      Clear Date
                    </Button>
                  </div>} />
            </div>
            <div className="form-group">
              <label>Contract Signed MRR</label>
              <input type="text" className="form-control" value={newCompany.newCompanyContractSignedMRR} onChange={e => this.setState({
              newCompany: {
                ...newCompany,
                newCompanyContractSignedMRR: e.target.value
              }
            })} />
            </div>

            <div>
              <ul className="select-list mt-4 ml-2">
                {['journeys', 'templates', 'segments', 'dataFields', 'savedBlocks', 'usersAndRoles'].map((field, i) => {
                return <li key={i}>
                      <div className="checkbox-label">
                        <label className="form-check-label">
                          <input className="form-check-input" name={field} style={{
                        position: 'relative',
                        top: '1px',
                        transform: 'scale(1.25)'
                      }} type="checkbox" onChange={e => this.setState({
                        newCompany: {
                          ...newCompany,
                          [e.target.name]: !newCompany[e.target.name]
                        }
                      })} checked={newCompany && newCompany[field]} />
                          {_.startCase(field)}
                        </label>
                      </div>
                    </li>;
              })}
              </ul>
            </div>
          </ModalDialog>
          <ModalFileViewer2 open={showFileViewer} onSubmit={file => this.update('company_logo', file.url)} onClose={() => this.setState({
          showFileViewer: false
        })} />
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i9"] body,body[kremling="i9"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i9"] .company-list,[kremling="i9"].company-list {
  padding: 2rem;
}

[kremling="i9"] .company-list table button,[kremling="i9"].company-list table button {
  opacity: 0;
}
[kremling="i9"] .company-list table tr:hover button,[kremling="i9"].company-list table tr:hover button {
  opacity: 1;
}

[kremling="i9"] .company-list__address,[kremling="i9"].company-list__address {
  font-size: 12px;
}

[kremling="i9"] .invalid-input,[kremling="i9"].invalid-input {
  margin-bottom: 0px;
  color: red;
}

/* The switch - the box around the slider */
[kremling="i9"] .switch,[kremling="i9"].switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
[kremling="i9"] .switch input,[kremling="i9"].switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
[kremling="i9"] .slider,[kremling="i9"].slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #df5651;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

[kremling="i9"] .slider:before,[kremling="i9"].slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

[kremling="i9"] input:checked+.slider,input[kremling="i9"]:checked+.slider {
  background-color: #73b56e;
}

[kremling="i9"] input:focus+.slider,input[kremling="i9"]:focus+.slider {
  box-shadow: 0 0 1px #73b56e;
}

[kremling="i9"] input:checked+.slider:before,input[kremling="i9"]:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
[kremling="i9"] .slider.round,[kremling="i9"].slider.round {
  border-radius: 34px;
}

[kremling="i9"] .slider.round:before,[kremling="i9"].slider.round:before {
  border-radius: 50%;
}

[kremling="i9"] .companyActive>.isActive,[kremling="i9"].companyActive>.isActive {
  fill: #73b56e;
}

[kremling="i9"] .companyActive>.isNotActive,[kremling="i9"].companyActive>.isNotActive {
  fill: #df5651 !important;
}

[kremling="i9"] .filter,[kremling="i9"].filter {
  display: inline-block !important;
  border: solid 1px #d3d3d3;
  line-height: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.4rem;
  color: #404040;
  border-radius: 0.4rem;
  padding: 0 1rem;
  transition: border-color 200ms ease, background-color 200ms ease, box-shadow 200ms ease;
  height: 3rem;
  background-color: #fff;
}
[kremling="i9"] .filter:focus,[kremling="i9"].filter:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.22rem rgba(72, 122, 174, 0.25);
  background-color: #fff;
  outline: none;
  color: #495057;
}
[kremling="i9"] .filter.inline,[kremling="i9"].filter.inline {
  width: auto;
  display: inline-block;
}
[kremling="i9"] .filter.transparent,[kremling="i9"].filter.transparent {
  border-color: transparent;
  background-color: transparent;
}

[kremling="i9"] .brand,[kremling="i9"].brand {
  position: relative;
}

[kremling="i9"] .brand-close,[kremling="i9"].brand-close {
  position: absolute;
  right: 2.8rem;
  top: 0.4rem;
}`,
  id: 'i9',
  namespace: 'kremling'
};