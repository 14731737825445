import React, { useState, useEffect, useContext } from 'react';
import { Scoped } from 'kremling';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.min.css';
import { PageHeader } from '../../components/page-header/page-header';
import styles from './users.styles.scss';
import { Button } from '../../components/button/button.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { UserStateContext } from 'context/user-state-context';
import { Loader } from '../../components/loader/loader.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { getUsers, getRoles } from '../../shared/common.api';
import { deleteUserCompany, inviteUser as inviteUserAPI, updateUserCompany } from '../../shared/common.api';
import { SortHeader } from '../../components/table/sort-header.component';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { Modal } from '../../components/modal/modal.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { Icon, PageHeaderMui } from 'components';
import CssBaseline from '@mui/material/CssBaseline';
import { IconButton, Tooltip, Box, Stack } from '@mui/material';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { DeleteIcon, EditIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
const UsersList = ({
  history
}) => {
  const {
    asCompany,
    hasPermission,
    user
  } = useContext(UserStateContext);
  const [renderIteration, setRenderIteration] = useState(0);
  const [roles, setRoles] = useState([]);
  const [inviteUser, setInviteUser] = useState(false);
  const [inviteUserEmail, setInviteUserEmail] = useState('');
  const [inviteUserRole, setInviteUserRole] = useState('');
  const [inviteUserFirstName, setInviteUserFirstName] = useState('');
  const [inviteUserLastName, setInviteUserLastName] = useState('');
  const [removeUser, setRemoveUser] = useState();
  const [changeRole, setChangeRole] = useState();
  const [changeRoleRole, setChangeRoleRole] = useState('');
  useEffect(() => {
    if (hasPermission('authentication.view_role')) {
      getRoles({
        offset: 0,
        limit: 1000,
        company: asCompany.id,
        ordering: 'name'
      }).then(({
        data
      }) => {
        if (data) {
          setRoles(data.results);
        }
      });
    }
  }, []);
  const headCells = [{
    key: 'first_name',
    label: 'First Name',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user?.first_name || ''
  }, {
    key: 'last_name',
    label: 'Last Name',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user?.last_name || ''
  }, {
    key: 'email',
    label: 'Email',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user?.email || ''
  }, {
    key: 'created_when',
    label: 'Created',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => moment(user?.created_when).format('MMM D, YYYY')
  }, {
    key: 'last_login',
    label: 'Last Login',
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user.last_login === null ? 'N/A' : moment(user?.last_login).format('MMM D, YYYY')
  }, {
    key: 'role',
    label: 'Role',
    noSort: true,
    width: '16.66%',
    displayComponent: ({
      data: user
    }) => user.is_superuser === true ? 'Superuser' : user.role
  }, {
    key: 'tools',
    noSort: true,
    displayComponent: ({
      data: row,
      onHover
    }) => {
      if (row.id === onHover.row) {
        return <Box sx={{
          position: 'absolute'
        }}>
              <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 0,
            top: '-18px',
            justifyContent: 'flex-end'
          }}>
                {(!row.role_is_private || user.is_superuser) && hasPermission('authentication.change_usertocompany') && <Tooltip title="Change Role">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setChangeRole(row);
                const r = roles.find(role => role.display_name == row.role);
                if (r) {
                  setChangeRoleRole(r.id);
                }
              }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>}
                {(!row.role_is_private || user.is_superuser) && hasPermission('authentication.delete_usertocompany') && <Tooltip title="Remove User">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setRemoveUser(row);
              }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>
            </Box>;
      }
      return <></>;
    }
  }];
  const actuallyRemoveUser = () => {
    if (user.id === removeUser.id) {
      toasterService.error('You cannot remove yourself');
    } else {
      deleteUserCompany(removeUser.user_to_company).then(() => {
        setRenderIteration(i => i + 1);
        toasterService.success('Successfully removed user');
      }).catch(() => {
        toasterService.error('An unknown error occured. Please try again.');
      });
    }
  };
  const actuallyInviteUser = () => {
    inviteUserAPI({
      first_name: inviteUserFirstName,
      last_name: inviteUserLastName,
      company: asCompany.id,
      email: inviteUserEmail,
      role: inviteUserRole
    }).then(() => {
      setInviteUser(false);
      setInviteUserEmail('');
      setInviteUserRole('');
      setInviteUserFirstName('');
      setInviteUserLastName('');
      setRenderIteration(i => i + 1);
      toasterService.success('User invitation sent.');
    }).catch(err => {
      if (err?.response?.data?.non_field_errors) {
        toasterService.error(err?.response?.data?.non_field_errors[0]);
      } else {
        toasterService.error('An unknown error occured. Please try again.');
      }
    });
  };
  const actuallyChangeRole = () => {
    updateUserCompany(changeRole.user_to_company, {
      role: changeRoleRole
    }).then(() => {
      setChangeRole(false);
      setChangeRoleRole('');
      setRenderIteration(i => i + 1);
      toasterService.success('User role updated');
    }).catch(() => {
      toasterService.error('An unknown error occured. Please try again.');
    });
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type="Users" icon={<Icon name="custom-segment" size={34} />} />
        <div className="mui-wrapper">
          <EnhancedTable type="Users" headCells={headCells} api={getUsers} defaultSort={'-updated_when'} handleRowClick={user => history.push(`/users/${user.id}`)} renderIteration={renderIteration} actions={hasPermission('authentication.add_usertocompany') && <NewButton onClick={e => setInviteUser(true)} />} />
        </div>
      </div>
      <ModalDialog open={!!removeUser} onClose={() => setRemoveUser(undefined)} allowBackdropClick title="Remove User" onSubmit={actuallyRemoveUser} submitText="Remove User" actionType="danger">
        Are you sure you want to remove this user from this company?
      </ModalDialog>

      <Modal open={!!inviteUser} onClose={() => setInviteUser(false)} allowBackdropClick title="Invite User">
        <div className="modal__body">
          <p>Enter the information for the user you want to invite:</p>
          <div className="form-group">
            <label>First Name</label>
            <input className="form-control" name="inviteUserFirstName" onChange={e => setInviteUserFirstName(e.target.value)} type="text" value={inviteUserFirstName} />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input className="form-control" name="inviteUserLastName" onChange={e => setInviteUserLastName(e.target.value)} type="text" value={inviteUserLastName} />
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <input className="form-control" name="inviteUserEmail" onChange={e => setInviteUserEmail(e.target.value)} type="email" value={inviteUserEmail} />
          </div>
          <div className="form-group">
            <label>Role</label>
            <select className="form-control" name="inviteUserRole" onChange={e => setInviteUserRole(e.target.value)} value={inviteUserRole}>
              <option value=""></option>
              {roles.map(role => <option key={role.id} value={role.id}>
                  {role.display_name}
                </option>)}
            </select>
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setInviteUser(false)}>
            Cancel
          </Button>
          <Button actionType="primary" disabled={inviteUserRole === '' || inviteUserEmail === '' || inviteUserFirstName === '' || inviteUserLastName === ''} onClick={actuallyInviteUser}>
            Invite
          </Button>
        </div>
      </Modal>

      <Modal open={!!changeRole} onClose={() => setChangeRole(false)} allowBackdropClick title="Change Role">
        <div className="modal__body">
          <p>To what role do you want to change this user:</p>
          <div className="form-group">
            <label>Role</label>
            <select className="form-control" name="changeRoleRole" onChange={e => setChangeRoleRole(e.target.value)} value={changeRoleRole}>
              <option value=""></option>
              {roles.map(role => <option key={role.id} value={role.id}>
                  {role.display_name}
                </option>)}
            </select>
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setChangeRole(false)}>
            Cancel
          </Button>
          <Button actionType="primary" disabled={changeRoleRole === ''} onClick={actuallyChangeRole}>
            Update
          </Button>
        </div>
      </Modal>
    </>;
};
export default UsersList;