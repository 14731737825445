import * as React from 'react';
import { MultiSelectFilter } from './multi-select.component';
import { UserStateContext } from 'context/user-state-context';
export const TagFilter = ({
  api,
  ...props
}) => {
  const {
    asCompany
  } = React.useContext(UserStateContext);
  const [tags, setTags] = React.useState([]);
  React.useEffect(() => {
    if (asCompany.id) {
      api(asCompany.id).then(({
        data
      }) => {
        setTags(data.results.map(t => ({
          label: t.tag || t,
          value: t.tag || t
        })));
      });
    }
  }, [asCompany?.id]);
  return <>{tags.length > 0 && <MultiSelectFilter label="Tags" pluralLabel="Tags" key="tags" options={tags} {...props} />} </>;
};