import React, { useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { DynamicTypeAhead as DTA } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
export const DynamicTypeAhead = props => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const {
    param,
    update,
    data
  } = props;
  return <div className="form-group">
      <label>{param.label}</label>
      <DTA getItems={param.getItems} getItemsFilters={{
      company: asCompany.id,
      ...(param.getItemExtraFilters || {})
    }} placeholder={param.placeholder || `Choose ${param.label}`} displayProperty={param.displayProperty || 'name'} keyProperty={param.keyProperty || 'id'} value={data[param.key] || null} onChange={item => update({
      [param.key]: item ? item[param.keyProperty || 'id'] : null
    })} />
    </div>;
};