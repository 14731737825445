import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import { Scoped } from 'kremling';
import styles from './import.styles.scss';
import { PageHeader } from 'components/page-header/page-header';
import { Button } from 'components/button/button.component';
import { Loader } from 'components/loader/loader.component';
import { UserStateContext } from 'context/user-state-context';
import { SortHeader } from 'components/table/sort-header.component';
import { Pagination } from 'components/pagination/pagination.component';
import { getImports, deleteImport as apiDeleteImport } from 'shared/common.api';
import { toasterService } from 'components/toaster/toaster-service';
import { AsyncModalDialog } from 'components/modal/async-modal-dialog.component';
import { Modal } from '../../components/modal/modal.component';
import { Icon, PageHeaderMui } from 'components';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { IconButton, Tooltip, Box, Stack, Typography } from '@mui/material';
import { DeleteIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
export function Imports(props) {
  const {
    hasPermission
  } = useContext(UserStateContext);
  const [imports, setImports] = useState([]);
  const [deleteImport, setDeleteImport] = useState();
  const [importIssuesModal, setImportIssuesModal] = useState();
  const [importIssues, setImportIssues] = useState([]);
  const [renderIteration, setRenderIteration] = useState(0);
  useEffect(() => {
    if (imports.find(imp => imp.status == 'pending')) {
      const interval = setInterval(() => setRenderIteration(r => r + 1), 5000);
      return () => clearInterval(interval);
    }
  }, [imports]);
  const confirmDeleteImport = () => {
    setDeleteImport(null);
    return apiDeleteImport(deleteImport.id).then(() => {
      toasterService.success(`Import successfully deleted.  Customers will be deleted over the next few minutes.`);
      setRenderIteration(r => r + 1);
    }).catch(() => {
      toasterService.error(`An unknown error occurred while attempting to delete the import. Please try again`);
    });
  };
  const sendToSegment = imp => {
    if (imp.status == 'success') {
      props.history.push(`/segments/${imp.options.new_segment_id}`);
    }
    if (imp.status == 'error') {
      setImportIssuesModal(true);
      setImportIssues(imp.options.import_issues);
    }
  };
  const headCells = [{
    key: 'filename',
    label: 'File Name',
    width: '25%',
    displayComponent: ({
      data: imp
    }) => imp.filename || ''
  }, {
    key: 'source',
    label: 'Source',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => imp.source || ''
  }, {
    key: 'total_records',
    label: 'Records',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => imp.total_records || ''
  }, {
    key: 'status',
    label: 'Status',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => <>
          {imp.status == 'pending' && <div style={{
        float: 'left'
      }}>
              {' '}
              <Loader size="sm" />{' '}
            </div> || <Typography sx={{
        textTransform: 'capitalize'
      }}>{imp.status} </Typography>}
        </>
  }, {
    key: 'created_when',
    label: 'Date Loaded',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => <>{moment(imp.created_when).local().format('MMM D, YYYY h:mm A')}</>
  }, {
    key: 'created_by_first_name',
    label: 'Loaded By',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => <>
          {imp.created_by_first_name} {imp.created_by_last_name}
        </>
  }, {
    key: 'tools',
    noSort: true,
    displayComponent: ({
      data: imp,
      onHover
    }) => {
      if (imp.id === onHover.row) {
        return <Box sx={{
          position: 'absolute'
        }}>
              <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 0,
            top: '-18px',
            justifyContent: 'flex-end'
          }}>
                {imp.status !== 'pending' && hasPermission('csvimport.delete_import') && <Tooltip title="Delete">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setDeleteImport(imp);
              }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>
            </Box>;
      }
      return <></>;
    }
  }];
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type="Customer Import" icon={<Icon name="custom-exit_to_app" size={34} />} />

        <div className="mui-wrapper">
          <EnhancedTable type="Import" headCells={headCells} api={getImports} defaultSort={'-updated_when'} handleRowClick={imp => sendToSegment(imp)} renderIteration={renderIteration} actions={hasPermission('csvimport.add_import') && <NewButton onClick={e => props.history.push('/imports/upload')} />} />
        </div>
      </div>

      <AsyncModalDialog open={!!deleteImport} title="Delete Import" submitText="Confirm" onSubmit={() => confirmDeleteImport()} onClose={() => setDeleteImport(null)} actionType="danger" allowBackdropClick>
        <p>Are you sure you want to delete this import and all the customers imported from it? This cannot be undone.</p>
      </AsyncModalDialog>
      <Modal title="Import Issues" onClose={() => setImportIssuesModal(false)} open={!!importIssuesModal} allowBackdropClick size="lg" type="scroll">
        <div className="modal__body">
          <table className="table-list">
            <thead>
              <tr>
                <th> CSV Line</th>
                <th> Level</th>
                <th> Issue</th>
              </tr>
            </thead>
            <tbody>
              {importIssues?.length && importIssues.map((imp, index) => <tr key={index}>
                    <td>{imp.line || 0} </td>
                    <td style={{
                textTransform: 'capitalize'
              }}>{imp.level}</td>
                    <td> {imp.issue} </td>
                  </tr>) || <div>
                    <tr>
                      <td>Unknown Error</td>
                    </tr>
                    <tr>
                      <td>Contact Cinch Support for help</td>
                    </tr>
                  </div>}
            </tbody>
          </table>
        </div>
        <div className="modal__actions">
          <Button actionType="primary" onClick={() => setImportIssuesModal(false)}>
            Okay
          </Button>
        </div>
      </Modal>
    </Scoped>;
}