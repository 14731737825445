import React, { Component } from 'react';
import { getJourney, patchJourney, getJourneyEndStats, getJourneysTags } from '../../shared/common.api';
import { Loader } from '../../components/loader/loader.component';
import moment from 'moment-timezone';
import { Scoped } from 'kremling';
import styles from './customer-journey.styles.scss';
import { Icon, PageHeaderMui, Save, TagModal } from 'components';
import { UserStateContext } from '../../context/user-state-context';
import { useContext } from 'react';
import { history } from '../../root.component';
import PropTypes from 'prop-types';
import { Box, Paper, Typography, Stack, TextField, InputAdornment, Tooltip, Chip, IconButton, Divider, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid } from '@mui/material';
import { AddCircleIcon, ArchivedIcon, InboxMoveIcon, MailIcon, PlaylistAddCheckIcon, PublishedIcon } from 'components/mui';
import { StatusMui } from 'components/status/status-mui.component';
import { withStyles } from '@mui/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
const Style = {
  journeyNavBar: {
    position: 'absolute',
    left: 350,
    display: 'flex',
    alignItems: 'center'
  }
};
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: `14px`,
      '& fieldset': {
        // border: 'none',
      }
    }
  }
})(TextField);
const REVISION_MAX = 3;
const ASSET_MAX = 10;
function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;
  return <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{}}>
          <Typography component="div">{children}</Typography>
        </Box>}
    </div>;
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}
export const CustomerJourneysDetailMui = props => {
  const {
    hasPermission,
    checkCompany
  } = useContext(UserStateContext);
  const [isLoadingJourney, setIsLoadingJourney] = React.useState(true);
  const [journey, setJourney] = React.useState(null);
  const [revisionsExpanded, setRevisionsExpanded] = React.useState(false);
  const [segmentTriggersExpanded, setSegmentTriggersExpanded] = React.useState(false);
  const [segmentEditsExpanded, setSegmentEditsExpanded] = React.useState(false);
  const [emailTemplatesExpanded, setEmailTemplatesExpanded] = React.useState(false);
  const [printTemplatesExpanded, setPrintTemplatesExpanded] = React.useState(false);
  const [editDescription, setEditDescription] = React.useState(false);
  const [editTags, setEditTags] = React.useState(false);
  const [endStats, setEndStats] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState(0);
  const [isSaving, setIsSaving] = React.useState(false);
  const [showSaved, setShowSaved] = React.useState(false);
  const [companyTagsMenuAnchorEl, setCompanyTagsMenuAnchorEl] = React.useState(null);
  const companyTagsOpen = Boolean(companyTagsMenuAnchorEl);
  const [newTagName, setNewTagName] = React.useState('');
  const [tabButtonHover, setTabButtonHover] = React.useState(null);
  React.useEffect(() => {
    loadJourney();
  }, []);
  const loadJourney = () => {
    setIsLoadingJourney(true);
    const {
      id
    } = props.match.params;
    getJourneyEndStats(id).then(({
      data
    }) => {
      setEndStats(data.results);
    });
    getJourney(id).then(({
      data
    }) => {
      if (data) {
        checkCompany(data.company).then(() => {
          const journ = data;
          journ.segment_edits = data.segment_edits.filter(j => j.id !== null);
          setJourney(journ);
          setIsLoadingJourney(false);
        }).catch(() => {
          // User chose not to switch companies.
        });
      }
    });
  };
  const goToJourney = (id, mode) => {
    if (hasPermission('journey.view_journey')) {
      history.push({
        pathname: `/customer-journeys/${id}`,
        state: {
          mode
        }
      });
    }
  };
  const updateName = name => {
    const {
      id
    } = props.match.params;
    return patchJourney(id, {
      name
    }).then(({
      data
    }) => {
      setJourney(data);
    });
  };
  const updateDescripiton = description => {
    const {
      id
    } = props.match.params;
    return patchJourney(id, {
      description
    }).then(({
      data
    }) => {
      setJourney(data);
      setEditDescription(false);
    });
  };
  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue == 0) {
      //nothing, already on this page
    }
    if (newValue == 1) {
      goToJourney(props.match.params.id, 'DRAFT');
    }
    if (newValue == 2) {
      goToJourney(props.match.params.id, 'PUBLISHED');
    }
  };
  React.useEffect(() => {
    if (showSaved) {
      const delayDebounceFn = setTimeout(() => {
        setIsSaving(true);
        patchJourney(props.match.params.id, journey).then(({
          data
        }) => {
          setIsSaving(false);
        });
      }, 1500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [journey]);
  const updateJourney = (key, val) => {
    setShowSaved(true);
    setJourney(prevState => ({
      ...prevState,
      [key]: val
    }));
  };
  const handleCompanyTagsClick = event => {
    setCompanyTagsMenuAnchorEl(companyTagsOpen ? null : event.currentTarget);
  };
  const handleCompanyTagsClose = () => {
    setCompanyTagsMenuAnchorEl(null);
  };
  const handleNewTagClick = () => {
    if (newTagName?.length > 0 && !isExistingTag()) {
      const newTags = journey.tags || [];
      newTags.push(newTagName);
      updateJourney('tags', newTags);
      setNewTagName('');
    }
  };
  const isExistingTag = () => {
    if (!journey.tags || journey.tags?.length == 0) {
      return false;
    }
    for (const tag of journey.tags) {
      if (tag.toLowerCase() == newTagName?.toLowerCase()) {
        return true;
      }
    }
    return false;
  };
  const handleDelete = (tagToDelete, index) => () => {
    const newTags = journey.tags;
    newTags.splice(index, 1);
    updateJourney('tags', newTags);
  };
  return <>
      <Scoped css={styles}>
        <div className="wrapper">
          {isLoadingJourney && <Loader overlay />}
          {!isLoadingJourney && <>
              <PageHeaderMui type="Journey Details" name={journey.name} icon={<Icon name="custom-account_tree" size={34} />} />

              <Stack sx={{
            px: '20px',
            pt: '20px'
          }} spacing="15px" direction={'row'}>
                <Box className="box-button" sx={{
              backgroundColor: currentTab == 0 ? '#3898D9' : tabButtonHover == 0 ? '#C2E7FF' : '#F3F6FC'
            }} onMouseEnter={() => setTabButtonHover(0)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
              handleTabsChange(e, 0);
            }}>
                  <PlaylistAddCheckIcon fill={currentTab == 0 ? '#ffffff' : '#1D252D'} />

                  <Typography sx={{
                pl: '10px',
                color: currentTab == 0 ? '#ffffff' : '#1D252D'
              }}>
                    Details
                  </Typography>
                </Box>
                <Box className="box-button" sx={{
              backgroundColor: currentTab == 1 ? '#3898D9' : tabButtonHover == 1 ? '#C2E7FF' : '#F3F6FC'
            }} onMouseEnter={() => setTabButtonHover(1)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
              handleTabsChange(e, 1);
            }}>
                  <ArchivedIcon fill={currentTab == 1 ? '#ffffff' : '#1D252D'} />

                  <Typography sx={{
                pl: '10px',
                color: currentTab == 1 ? '#ffffff' : '#1D252D'
              }}>
                    Draft View
                  </Typography>
                </Box>

                {journey.status !== 'draft' && <Box className="box-button" sx={{
              backgroundColor: currentTab == 2 ? '#3898D9' : tabButtonHover == 2 ? '#C2E7FF' : '#F3F6FC'
            }} onMouseEnter={() => setTabButtonHover(2)} onMouseLeave={() => setTabButtonHover(null)} onClick={e => {
              handleTabsChange(e, 2);
            }}>
                    <PublishedIcon fill={currentTab == 2 ? '#ffffff' : '#1D252D'} />

                    <Typography sx={{
                pl: '10px',
                color: currentTab == 2 ? '#ffffff' : '#1D252D'
              }}>
                      Published View
                    </Typography>
                  </Box>}
              </Stack>

              <div className="mui-wrapper">
                <Box sx={{
              m: '20px'
            }}>
                  <Paper sx={{
                width: '100%',
                //height: '85vh',
                borderRadius: '14px'
              }}>
                    <Box sx={{
                  height: '100%'
                }} display="flex" flexDirection="column" justifyContent="space-between">
                      <Stack spacing="30px" sx={{
                    py: '30px',
                    px: '24px'
                  }}>
                        <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 24,
                          color: '#1D252D'
                        }}>Journey Details </Typography>{' '}
                            <Stack direction="row" spacing="40px">
                              <Save saving={isSaving} />
                              <StatusMui hideBackground status={journey.status} className="d-inline ml-3" />
                            </Stack>
                          </Box>

                          <Stack direction="row" spacing={'20px'}>
                            <Box sx={{
                          width: '50%'
                        }}>
                              <CustomTextField multiline label={journey.description ? 'Description' : ''} placeholder="Description" sx={{
                            width: '100%'
                          }} onChange={e => {
                            updateJourney('description', e.target.value);
                          }} value={journey.description || ''} InputLabelProps={{
                            style: {
                              color: '#BEBEBE'
                            }
                          }} InputProps={{
                            startAdornment: <InputAdornment position="start">
                                      <Icon fill="#1D252D" name="custom-description" />
                                    </InputAdornment>
                          }} />
                            </Box>

                            <Stack display="flex" alignItems="center" direction="row" spacing="10px">
                              {hasPermission('journey.change_journey') && <Box onClick={handleCompanyTagsClick}>
                                <CustomTextField label="Add tag" placeholder="Type tag name" sx={{
                              width: '360px',
                              height: '56px'
                            }} onChange={e => setNewTagName(e.target.value.toUpperCase())} onKeyPress={e => {
                              if (e.key === 'Enter') {
                                handleNewTagClick();
                              }
                            }} value={newTagName || ''} InputLabelProps={{
                              style: {
                                color: '#BEBEBE'
                              }
                            }} InputProps={{
                              startAdornment: <InputAdornment position="start">
                                        <Icon fill="#1D252D" name="custom-new_label" />
                                      </InputAdornment>
                            }} />
                              </Box>}

                              {hasPermission('journey.change_journey') && <Tooltip title={<div style={{
                            whiteSpace: 'pre-line'
                          }}>
                                    {newTagName?.length == 0 ? 'Enter a tag name' : isExistingTag() ? 'Unable to add tag ' + newTagName + '\n It has already been added to ' + (journey.name || 'Untitled Journey') : 'Add ' + newTagName + ' to ' + (journey.name || 'Untitled Journey')}
                                  </div>}>
                                <IconButton sx={{
                              width: '40px',
                              height: '40px',
                              textAlign: 'center',
                              padding: 0,
                              backgroundColor: '#fff',
                              ':hover': {
                                backgroundColor: '#DAEFFF'
                              }
                            }} onClick={handleNewTagClick}>
                                  <AddCircleIcon fill={newTagName?.length > 0 && !isExistingTag() ? '#53A6D6' : '#53A6D64D'} />
                                </IconButton>
                              </Tooltip>}

                              <Box display="flex" alignItems="center">
                                <Stack direction="row" spacing="10px" sx={{
                              flexWrap: 'wrap',
                              gap: 1
                            }}>
                                  {journey.tags?.length > 0 && journey.tags.map((tag, index) => <Chip key={index} label={tag} deleteIcon={<CloseOutlinedIcon sx={{
                                transform: 'scale(0.75)'
                              }} />} onDelete={hasPermission('journey.change_journey') && handleDelete(tag, index)} />)}
                                </Stack>
                              </Box>
                            </Stack>
                          </Stack>
                        </Stack>

                        <Divider />

                        <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 20,
                          color: '#1D252D'
                        }}>Revision History </Typography>{' '}
                          </Box>
                          <Box>
                            <Table>
                              <colgroup>
                                <col width="25%" />
                                <col width="25%" />
                                <col width="25%" />
                                <col width="25%" />
                              </colgroup>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Revision</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell>Published</TableCell>
                                  <TableCell>Stopped/Closed/Paused</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {journey.revisions.filter((r, i) => i < REVISION_MAX || revisionsExpanded).map((revision, index) => <TableRow key={revision.id}>
                                      <TableCell>Revision #{journey.revisions.length - index}</TableCell>
                                      <TableCell>
                                        <Box sx={{
                                  display: 'flex'
                                }}>
                                          {' '}
                                          <StatusMui disablePadding hideBackground status={revision.status} />
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        {!!revision.published_when ? <>Published: {moment(revision.published_when).local().format('MMM D, YYYY h:mm A')}</> : <Typography> --</Typography>}
                                      </TableCell>
                                      <TableCell>
                                        {!!revision.deleted_when && <>Stopped: {moment(revision.deleted_when).local().format('MMM D, YYYY h:mm A')}</>}
                                        {!revision.deleted_when && !!revision.closed_when && <>Closed: {moment(revision.closed_when).local().format('MMM D, YYYY h:mm A')}</>}
                                        {!revision.deleted_when && !revision.closed_when && !!revision.paused_when && <>Paused: {moment(revision.paused_when).local().format('MMM D, YYYY h:mm A')}</>}
                                        {!revision.deleted_when && !revision.closed_when && !revision.paused_when && <Typography> --</Typography>}
                                      </TableCell>
                                    </TableRow>)}
                              </TableBody>
                            </Table>
                            {journey.revisions.length > REVISION_MAX && <Box sx={{
                          pt: 1
                        }}>
                                <Button sx={{
                            display: 'flex',
                            width: '125px',
                            borderRadius: '20px',
                            color: '#53A6D6'
                          }} variant="outlined" onClick={() => setRevisionsExpanded(!revisionsExpanded)}>
                                  <Typography sx={{
                              textTransform: 'none'
                            }}> Show {revisionsExpanded ? 'Less' : 'More'}</Typography>
                                </Button>
                              </Box>}
                          </Box>
                        </Stack>

                        <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 20,
                          color: '#1D252D'
                        }}>End Stats </Typography>{' '}
                          </Box>

                          <Table>
                            <colgroup>
                              <col width="20%" />
                              <col width="20%" />
                              <col width="10%" />
                              <col width="10%" />
                              <col width="10%" />
                              <col width="10%" />
                              <col width="10%" />
                              <col width="10%" />
                            </colgroup>
                            <TableHead>
                              <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell>Last 7 Days</TableCell>
                                <TableCell>%</TableCell>
                                <TableCell>Last 30 Days</TableCell>
                                <TableCell>%</TableCell>
                                <TableCell>Last 365 Days</TableCell>
                                <TableCell>%</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {endStats.map((stat, i) => <TableRow key={i}>
                                  <TableCell>{stat.end_status || <Typography> --</Typography>}</TableCell>
                                  <TableCell>{stat.end_reason || <Typography> --</Typography>}</TableCell>
                                  <TableCell>{stat.last_7_days || <Typography> --</Typography>}</TableCell>
                                  <TableCell>{stat.last_7_days_per || <Typography> --</Typography>}%</TableCell>
                                  <TableCell>{stat.last_30_days || <Typography> --</Typography>}</TableCell>
                                  <TableCell>{stat.last_30_days_per || <Typography> --</Typography>}%</TableCell>
                                  <TableCell>{stat.all_time || <Typography> --</Typography>}</TableCell>
                                  <TableCell>{stat.all_time_per || <Typography> --</Typography>}%</TableCell>
                                </TableRow>)}
                            </TableBody>
                          </Table>
                        </Stack>

                        <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 20,
                          color: '#1D252D'
                        }}>Segments</Typography>{' '}
                          </Box>
                          <Stack spacing={1}>
                            <Grid container>
                              {!journey.segment_triggers.length && !journey.segment_edits.length && <div className="mb-3">
                                  <Typography sx={{
                              fontFamily: 'Nexa',
                              fontSize: 14,
                              color: '#1D252D80'
                            }}>
                                    No Segments Triggered or edited
                                  </Typography>
                                </div>}
                              {journey.segment_triggers.filter((r, i) => i < ASSET_MAX || segmentTriggersExpanded).map(segment => <Box sx={{
                            px: '16px',
                            py: '8px'
                          }}>
                                    <Button variant="text" key={segment.id} sx={{
                              cursor: 'pointer',
                              minHeight: '28px',
                              color: '#FFFFFF',
                              backgroundColor: '#53A6D6',
                              borderRadius: '8px',
                              px: '8px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              textTransform: 'none',
                              alignItems: 'center',
                              ':hover': {
                                backgroundColor: '#53A6D6'
                              }
                            }} onClick={() => {
                              history.push(`/segments/${segment.id}/edit`);
                            }}>
                                      <Icon name="custom-border_color" />
                                      <Typography sx={{
                                pl: 1,
                                color: '#FFFFFF'
                              }}> {segment.name}</Typography>
                                    </Button>
                                  </Box>)}
                              {journey.segment_edits.filter((r, i) => i < ASSET_MAX || segmentEditsExpanded).map(segment => <Box sx={{
                            px: '16px',
                            py: '8px'
                          }}>
                                    <Button variant="text" key={segment.id} sx={{
                              cursor: 'pointer',
                              minHeight: '28px',
                              color: '#FFFFFF',
                              backgroundColor: '#53A6D6',
                              borderRadius: '8px',
                              px: '8px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              textTransform: 'none',
                              alignItems: 'center',
                              ':hover': {
                                backgroundColor: '#53A6D6'
                              }
                            }} onClick={() => {
                              history.push(`/segments/${segment.id}/edit`);
                            }}>
                                      <Icon name="custom-border_color" />
                                      <Typography sx={{
                                pl: 1,
                                color: '#FFFFFF'
                              }}> {segment.name}</Typography>
                                    </Button>
                                  </Box>)}
                            </Grid>
                            <Box>
                              {(journey.segment_triggers.length > ASSET_MAX || journey.segment_edits.length > ASSET_MAX) && <Button variant="outlined" sx={{
                            display: 'flex',
                            width: '125px',
                            borderRadius: '20px',
                            color: '#53A6D6'
                          }} onClick={() => {
                            setSegmentTriggersExpanded(!segmentTriggersExpanded);
                            setSegmentEditsExpanded(!segmentEditsExpanded);
                          }}>
                                  <Typography sx={{
                              textTransform: 'none'
                            }}>
                                    {' '}
                                    Show {segmentTriggersExpanded + segmentEditsExpanded ? 'Less' : 'More'}{' '}
                                  </Typography>
                                </Button>}
                            </Box>
                          </Stack>
                        </Stack>

                        <Divider />

                        <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 20,
                          color: '#1D252D'
                        }}>Email Templates </Typography>{' '}
                          </Box>

                          <Stack spacing={1}>
                            <Grid container>
                              {!journey.email_templates.length && <div className="mb-3">
                                  <Typography sx={{
                              fontFamily: 'Nexa',
                              fontSize: 14,
                              color: '#1D252D80'
                            }}> No Email Templates</Typography>
                                </div>}
                              {journey.email_templates.filter((r, i) => i < ASSET_MAX || emailTemplatesExpanded).map(template => <Box sx={{
                            px: '16px',
                            py: '8px'
                          }}>
                                    <Button variant="text" key={template.id} sx={{
                              cursor: 'pointer',
                              minHeight: '28px',
                              color: '#1D252D',
                              backgroundColor: '#53A6D6',
                              borderRadius: '8px',
                              px: '8px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              textTransform: 'none',
                              alignItems: 'center',
                              ':hover': {
                                backgroundColor: '#53A6D6'
                              }
                            }} onClick={() => {
                              history.push(`/templates/edit/email/${template.id}`);
                            }}>
                                      <InboxMoveIcon fill="#fff" size="sm" />
                                      <Typography sx={{
                                pl: 1,
                                color: '#fff'
                              }}> {template.name}</Typography>
                                    </Button>
                                  </Box>)}
                            </Grid>
                            {journey.email_templates.length > ASSET_MAX && <Button variant="outlined" sx={{
                          display: 'flex',
                          width: '125px',
                          borderRadius: '20px',
                          color: '#53A6D6'
                        }} onClick={() => setEmailTemplatesExpanded(!emailTemplatesExpanded)}>
                                <Typography sx={{
                            textTransform: 'none'
                          }}> Show {emailTemplatesExpanded ? 'Less' : 'More'} </Typography>
                              </Button>}
                          </Stack>
                        </Stack>

                        <Divider />

                        <Stack spacing="30px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                        width: '100%'
                      }}>
                            <Typography sx={{
                          fontFamily: 'Nexa',
                          fontSize: 20,
                          color: '#1D252D'
                        }}>Mail Templates </Typography>{' '}
                          </Box>

                          <Stack spacing={1}>
                            <Grid container>
                              {!journey.print_templates.length && <div className="mb-3">
                                  <Typography sx={{
                              fontFamily: 'Nexa',
                              fontSize: 14,
                              color: '#1D252D80'
                            }}> No Mail Templates</Typography>
                                </div>}
                              {journey.print_templates.filter((r, i) => i < ASSET_MAX || printTemplatesExpanded).map(template => <Box sx={{
                            px: '16px',
                            py: '8px'
                          }}>
                                    <Button variant="text" key={template.id} sx={{
                              cursor: 'pointer',
                              height: '28px',
                              color: '#FFFFFF',
                              backgroundColor: '#53A6D6',
                              borderRadius: '8px',
                              px: '8px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              textTransform: 'none',
                              alignItems: 'center',
                              ':hover': {
                                backgroundColor: '#53A6D6'
                              }
                            }} onClick={() => {
                              history.push(`/templates/print/${template.id}`);
                            }}>
                                      <MailIcon size="sm" fill="#FFFFFF" />
                                      <Typography sx={{
                                pl: 1,
                                color: '#FFFFFF'
                              }}> {template.name}</Typography>
                                    </Button>
                                  </Box>)}
                            </Grid>
                            {journey.print_templates.length > ASSET_MAX && <Button variant="outlined" sx={{
                          display: 'flex',
                          width: '125px',
                          borderRadius: '20px',
                          color: '#53A6D6'
                        }} onClick={() => setPrintTemplatesExpanded(!printTemplatesExpanded)}>
                                <Typography sx={{
                            textTransform: 'none'
                          }}> Show {printTemplatesExpanded ? 'Less' : 'More'} </Typography>
                              </Button>}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Paper>
                </Box>
              </div>

              <TagModal onClose={() => setEditTags(false)} onSuccess={journey => {
            setJourney(journey);
          }} objectTarget={editTags ? journey : null} getViewTags={getJourneysTags} patchView={patchJourney} viewName={'Journeys'} />
            </>}
        </div>
      </Scoped>
    </>;
};