import React, { Component } from 'react';
import { func, object, shape, string, array, bool } from 'prop-types';
import { getEmailTemplates, getEmailMetrics, getSurveys } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Icon } from 'components/icon/icon.component';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { history } from '../../../../root.component';
const filterByDayOptions = [{
  id: '0',
  name: 'Today'
}, {
  id: '1',
  name: 'Yesterday'
}, {
  id: '7',
  name: 'Last 7 Days'
}, {
  id: '30',
  name: 'Last 30 Days'
}, {
  id: '90',
  name: 'Last 90 Days'
}, {
  id: 'all_time',
  name: 'All-Time'
}];
const style = {
  metricLabel: {
    maxWidth: '70px',
    width: '100%',
    height: '25px'
  },
  porcentageLabel: {
    width: '45px',
    height: '25px'
  },
  barStyle: {
    maxWidth: '135px',
    minWidth: '6%',
    backgroundColor: '#487aae',
    color: '#fff',
    height: '25px',
    fontWeight: 'bold',
    textAlign: 'center'
  }
};
export class ActionEmail extends Component {
  static contextType = UserStateContext;
  constructor(props) {
    super(props);
    if (!props.data.optStatuses || !props.data.optStatuses.length) {
      props.update({
        optStatuses: ['subscribed', 'unknown', 'pending']
      });
    }
  }
  static propTypes = {
    update: func,
    node: object,
    readOnly: bool,
    data: shape({
      template_id: string,
      google_campaign: string,
      optStatuses: array,
      filterByDay: string,
      resend: string
    })
  };
  state = {
    templateList: [],
    emailSettings: this.props.readOnly ? false : true,
    filterByDay: '7',
    emailMetrics: {
      sends: null,
      delivers: null,
      opens: null,
      clicks: null,
      pct_delivered: null,
      pct_opened: null,
      pct_clicked: null
    }
  };
  componentDidMount() {
    getEmailTemplates({
      company: this.context.asCompany.id,
      published: true,
      archived: false,
      limit: 200
    }).then(({
      data
    }) => {
      this.setState({
        templateList: data.results
      });
    });
    if (this.props.readOnly) {
      getEmailMetrics(this.props.node.id, this.state.filterByDay).then(({
        data
      }) => this.setState({
        emailMetrics: data
      }));
    }
  }
  toggleStatus = status => {
    const optStatuses = [...this.props.data.optStatuses];
    const index = optStatuses.indexOf(status);
    if (index === -1) {
      optStatuses.push(status);
    } else {
      optStatuses.splice(index, 1);
    }
    if (optStatuses.length === 0) {
      optStatuses.push('subscribed');
      optStatuses.push('unknown');
      optStatuses.push('pending');
    }
    this.props.update({
      optStatuses
    });
  };
  toggleTemplate = id => {
    const {
      template_id
    } = this.props.data;
    this.props.update({
      template_id: !template_id ? id : template_id.id !== id ? id : null
    });
  };
  handleFilterByDay = e => {
    const days = e.target.value === 'all_time' ? null : e.target.value;
    this.setState({
      filterByDay: e.target.value
    });
    getEmailMetrics(this.props.node.id, days).then(({
      data
    }) => {
      this.setState({
        emailMetrics: data
      });
    });
  };
  render() {
    const {
      templateList,
      emailSettings,
      emailMetrics,
      filterByDay
    } = this.state;
    const selectedTemplate = templateList.find(template => template.id === this.props.data.template_id);
    const {
      readOnly
    } = this.props;
    const {
      google_campaign,
      optStatuses,
      survey_id,
      resend
    } = this.props.data;
    const statuses = [];
    if (!optStatuses || !optStatuses.length || optStatuses.indexOf('subscribed') > -1) {
      statuses.push('Subscribed');
    }
    if (optStatuses && optStatuses.indexOf('pending') > -1) {
      statuses.push('Pending');
    }
    if (optStatuses && optStatuses.indexOf('suppressed') > -1) {
      statuses.push('Suppressed');
    }
    if (optStatuses && optStatuses.indexOf('unknown') > -1) {
      statuses.push('Unknown');
    }
    const itemFilters = {
      company: this.context.asCompany.id,
      published: true,
      archived: false
    };
    if (this.props.node.subType === 'action_send_survey_email') {
      itemFilters['tags'] = 'SURVEY';
    }
    return <>
        {emailSettings && <>
            <div className="form-group">
              <label>Template</label>
              <DynamicTypeAhead getItems={getEmailTemplates} getItemsFilters={itemFilters} placeholder={selectedTemplate ? `${selectedTemplate.name}` : 'Choose Email Template'} displayProperty="name" keyProperty="id" value={selectedTemplate ? selectedTemplate.id : null} onChange={template => {
            this.toggleTemplate(template ? template.id : null);
          }} />
            </div>
            {!!selectedTemplate ? <div className="form-group">
                <div>
                  <label>Template Preview</label>
                </div>
                <div>
                  <img style={{
              width: '100%',
              border: 'solid',
              borderColor: '#808080',
              borderWidth: '1px',
              maxHeight: '250px',
              maxWidth: '225px',
              margin: 'auto',
              display: 'block',
              objectFit: 'cover',
              objectPosition: 'top'
            }} src={selectedTemplate && selectedTemplate.thumbnail_url} alt="thumbnail for template" />
                </div>
                <div className="d-flex justify-content-flex-end mt-2">
                  <Button disabled={!selectedTemplate} onClick={() => {
              history.push({
                pathname: `/templates/edit/email/${selectedTemplate.id}`,
                state: window.location.pathname
              });
            }}>
                    Edit Template
                  </Button>
                </div>
              </div> : <div className="form-group">
                <div>
                  <label>Template Preview</label>
                </div>
                <div style={{
            height: '250px',
            width: '225px',
            border: 'solid',
            borderColor: '#808080',
            borderWidth: '1px'
          }}>
                  <p style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              marginTop: '120px'
            }}>
                    Select a template to preview
                  </p>
                </div>
              </div>}
            <div className="form-group">
              <label>Opt In Status</label>
              <Dropdown size="block" contentHeight={250} trigger={() => <Button dropdown block>
                    {statuses.length ? statuses.join(', ') : 'Choose Statuses'}
                  </Button>} content={() => <ul className="select-list">
                    <li>
                      <a onClick={() => this.toggleStatus('subscribed')}>
                        <div className="select-list__content">Subscribed</div>
                        {(!optStatuses || !optStatuses.length || optStatuses.indexOf('subscribed') > -1) && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.toggleStatus('pending')}>
                        <div className="select-list__content">Pending</div>
                        {optStatuses && optStatuses.indexOf('pending') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.toggleStatus('suppressed')}>
                        <div className="select-list__content">Suppressed</div>
                        {optStatuses && optStatuses.indexOf('suppressed') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.toggleStatus('unknown')}>
                        <div className="select-list__content">Unknown</div>
                        {optStatuses && optStatuses.indexOf('unknown') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </li>
                  </ul>} />
            </div>
            <div className="form-group">
              <label>Google Analytics Campaign Title</label>
              <input className="form-control" name="google_campaign" onChange={e => {
            const value = e.target.value.split(' ').join('_');
            this.props.update({
              google_campaign: value
            });
          }} placeholder="Optional" value={google_campaign || ''} type="text" />
            </div>
            {this.props.node.subType === 'action_send_survey_email' && <>
                <div className="form-group">
                  <label>Survey</label>
                  <DynamicTypeAhead getItems={getSurveys} getItemsFilters={{
              company: this.context.asCompany.id,
              published: true
            }} placeholder="Choose Survey" displayProperty="title" keyProperty="id" value={survey_id} onChange={survey => {
              this.props.update({
                survey_id: !!survey ? survey.id : null
              });
            }} />
                </div>
                <div className="form-group">
                  <label>Resend Survey Link</label>
                  <select className="form-control" value={resend} onChange={e => {
              this.props.update({
                resend: e.target.value
              });
            }}>
                    {resend === '' && <option></option>}
                    <option value="resend">Resend or Create</option>
                    <option value="create_only">Create Only</option>
                  </select>
                </div>
              </>}
          </>}
        {readOnly && !emailSettings && <>
            <div className="form-group row ">
              <div className="col-9">
                <label>Email Node Detail</label>
                <div className="row">
                  <div className="col-12">
                    <span>{selectedTemplate && selectedTemplate.name}</span>
                  </div>
                </div>
              </div>
              <div className="col-3 mr-3" style={{
            pointerEvents: 'auto !important'
          }}>
                <select className="form-control metrics-form" style={{
              pointerEvents: 'auto !important'
            }} value={filterByDay} onChange={e => this.handleFilterByDay(e)}>
                  {filterByDayOptions.map(option => <option key={option.id} value={option ? option.id : null}>
                      {option.name}
                    </option>)}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6" style={{
            maxHeight: '250px',
            overflow: 'hidden'
          }}>
                <img style={{
              width: '100%'
            }} src={selectedTemplate && selectedTemplate.thumbnail_url} alt="" />
              </div>
              <div className="col-6 mr-3">
                <div className="d-flex align-items-center">
                  <div className="p-2" style={style.metricLabel}>
                    Sent
                  </div>
                  <div className="p-2" style={{
                width: `${!!emailMetrics.sends ? 100 * 100 / 200 : 0}%`,
                ...style.barStyle
              }}>
                    {emailMetrics.sends ? emailMetrics.sends : 0}
                  </div>
                  <div className="p-2" style={style.porcentageLabel}></div>
                </div>
                <div className="d-flex align-items-center  mt-2">
                  <div className="p-2" style={style.metricLabel}>
                    Delivered
                  </div>
                  <div className="p-2" style={{
                width: `${emailMetrics.pct_delivered * 100 / 200}%`,
                ...style.barStyle
              }}>
                    {emailMetrics.delivers ? emailMetrics.delivers : 0}
                  </div>
                  <div className="p-2" style={style.porcentageLabel}>
                    {emailMetrics.pct_delivered ? `${emailMetrics.pct_delivered.toFixed(2)}%` : '0%'}
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <div className="p-2" style={style.metricLabel}>
                    Opened
                  </div>
                  <div className="p-2" style={{
                width: `${emailMetrics.pct_opened * 100 / 200}%`,
                ...style.barStyle
              }}>
                    {emailMetrics.opens ? emailMetrics.opens : 0}
                  </div>
                  <div className="p-2" style={style.porcentageLabel}>
                    {emailMetrics.pct_opened ? `${emailMetrics.pct_opened.toFixed(2)}%` : '0%'}
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <div className="p-2" style={style.metricLabel}>
                    Clicked
                  </div>
                  <div className="p-2" style={{
                width: `${emailMetrics.pct_clicked * 100 / 200}%`,
                ...style.barStyle
              }}>
                    {emailMetrics.clicks ? emailMetrics.clicks : 0}
                  </div>
                  <div className="p-2" style={style.porcentageLabel}>
                    {emailMetrics.pct_clicked ? `${emailMetrics.pct_clicked.toFixed(2)}%` : '0%'}
                  </div>
                </div>
              </div>
            </div>
          </>}
        <div className="toggler email-action mt-5" style={{
        left: readOnly ? '40%' : '43%'
      }}>
          {readOnly && <>
              <Button disabled={!selectedTemplate} className={!emailSettings && 'active'} onClick={() => this.setState({
            emailSettings: false
          })}>
                Stats
              </Button>
              <Button className={emailSettings && 'active'} onClick={() => this.setState({
            emailSettings: true
          })}>
                Settings
              </Button>
            </>}
        </div>
      </>;
  }
}