import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <mask id="mask0_24_6427" style={{
      maskType: 'alpha'
    }} width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.466 0H20.466V20H0.466z"></path>
      </mask>
      <g mask="url(#mask0_24_6427)">
        <path fill={props.fill || "#1D252D"} d="M10.465 15.625a.64.64 0 01-.466-.2.633.633 0 01-.2-.46v-4.298H5.5a.632.632 0 01-.46-.201.64.64 0 010-.932.632.632 0 01.46-.2h4.298V5.034c0-.173.067-.326.2-.46a.64.64 0 01.933 0c.133.134.2.287.2.46v4.298h4.298c.173 0 .326.067.46.2a.64.64 0 010 .933.632.632 0 01-.46.2h-4.298v4.299a.632.632 0 01-.2.46.64.64 0 01-.467.2z"></path>
      </g>
    </svg>;
}
export default Icon;