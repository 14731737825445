import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Scoped } from 'kremling';
import classnames from 'classnames';
import styles from './phone-numbers.styles.scss';
import { Loader } from '../../components/loader/loader.component';
import { Button } from '../../components/button/button.component';
import { ButtonGroup } from '../../components/button/button-group.component';
import { Modal } from '../../components/modal/modal.component';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { getPhoneNumbers, deletePhoneNumber, updatePhoneNumber, getCompanyPhoneCount, refreshPhoneRegistration, getPhoneRegistration } from '../../shared/common.api';
import utils from '../../shared/utils';
import { IconButton, Tooltip, Box, Stack, Chip } from '@mui/material';
import { PhoneNumberModal } from './phone-number-modal.component';
import { Icon, PageHeaderMui, Banner } from 'components';
import { UserStateContext } from 'context/user-state-context';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { DeleteIcon, EditSquareIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
const StatusChip = ({
  status
}) => {
  let label, color;
  if (!status) {
    label = 'Not Started';
    color = 'secondary';
  } else if (status === 'PENDING') {
    label = 'Pending';
    color = 'secondary';
  } else if (status === 'IN_REVIEW') {
    label = 'In Review';
    color = 'secondary';
  } else if (status === 'FAILED') {
    label = 'Failed';
    color = 'error';
  } else if (status === 'APPROVED') {
    label = 'Approved';
    color = 'success';
  } else if (status === 'VERIFIED') {
    label = 'Verified';
    color = 'success';
  } else {
    label = 'Pending';
    color = 'secondary';
  }
  return <Chip label={label} color={color} size='small' variant='outlined' />;
};
export function PhoneNumbers() {
  const {
    hasPermission,
    asCompany
  } = useContext(UserStateContext);
  const [deletePhoneModal, setDeletePhoneModal] = useState();
  const [renamePhoneModal, setRenamePhoneModal] = useState();
  const [loading, setLoading] = useState(true);
  const [ordering, setOrdering] = useState('name');
  const [paginationParams, setPaginationParams] = useState({});
  const [phoneNumberPagination, setPhoneNumberPagination] = useState({});
  const [customerCount, setCustomerCount] = useState(0);
  const [timeToSend, setTimeToSend] = useState(60);
  const [SMSMPM, setSMSMPM] = useState(60);
  const [MMSMPM, setMMSMPM] = useState(30);
  const [sendType, setSendType] = useState('sms');
  const [phoneNumbersNeeded, setPhoneNumbersNeeded] = useState(0);
  const [showCalcTool, setShowCalcTool] = useState(false);
  const [phoneNumberModal, setPhoneNumberModal] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [profile, setProfile] = useState();
  useEffect(() => {
    if (asCompany) {
      // Check if we have a messaging service or registration
      getPhoneRegistration(asCompany.id).then(({
        data: profile
      }) => {
        setProfile(profile);
      }).catch(() => {});
    }
  }, [asCompany]);
  useEffect(() => {
    setPhoneNumbersNeeded(Math.ceil(customerCount / (timeToSend * (sendType === 'sms' ? SMSMPM : MMSMPM))));
  }, [customerCount, timeToSend, SMSMPM, MMSMPM, sendType]);
  const loadData = useCallback(() => {
    if (!asCompany) return;
    return getPhoneNumbers({
      company: asCompany.id,
      ordering,
      ...paginationParams
    }).then(({
      data
    }) => {
      setPhoneNumberPagination(data);
      setLoading(false);
    });
  }, [setLoading, setPhoneNumberPagination, ordering, paginationParams, asCompany?.id]);
  useEffect(() => {
    if (!asCompany) return;
    loadData();
    getCompanyPhoneCount(asCompany.id).then(({
      data
    }) => {
      setCustomerCount(data);
    });
  }, [loadData, asCompany?.id]);
  const onCreate = () => {
    setPhoneNumberModal(true);
  };
  const renameNumber = number => {
    updatePhoneNumber(number.id, {
      name: number.name
    }).then(() => loadData()).then(() => toasterService.success('Phone number successfully updated')).catch(() => {
      toasterService.error('Unable to update phone number. Please try again.');
    });
  };
  const releaseNumber = number => {
    deletePhoneNumber(number.id).then(() => loadData()).then(() => toasterService.success('Phone number successfully released')).catch(() => {
      toasterService.error('Unable to release phone number. Please try again.');
    });
  };
  const headCells = [{
    key: 'title',
    label: 'Name',
    width: '25%',
    displayComponent: ({
      data: phoneNumber
    }) => <>{utils.formatPhoneNumber(phoneNumber.name)}</>
  }, {
    key: 'phone',
    label: 'Phone',
    width: '25%',
    displayComponent: ({
      data: phoneNumber
    }) => <>{utils.formatPhoneNumber(phoneNumber.phone)}</>
  }, {
    key: 'locality',
    label: 'Location',
    width: '25%',
    displayComponent: ({
      data: phoneNumber
    }) => <Stack direction="row" spacing="10px">
          <> {phoneNumber.locality}</>
          <> {phoneNumber.region} </>
          <>{phoneNumber.postal_code} </>
        </Stack>
  }, {
    key: 'status',
    label: 'Status',
    width: '25%',
    displayComponent: ({
      data: phoneNumber
    }) => <>{utils.formatTwilioStatus(phoneNumber.status)}</>
  }, {
    key: 'tools',
    noSort: true,
    displayComponent: ({
      data: phoneNumber,
      onHover
    }) => {
      if (phoneNumber.id === onHover.row) {
        return <Box sx={{
          position: 'absolute'
        }}>
              <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 0,
            top: '-18px',
            justifyContent: 'flex-end'
          }}>
                {hasPermission('communication.change_phonenumber') && <Tooltip title="Rename Phone Number">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setRenamePhoneModal(phoneNumber);
              }}>
                      <EditSquareIcon />
                    </IconButton>
                  </Tooltip>}
                {hasPermission('communication.delete_phonenumber') && <Tooltip title="Release Phone Number">
                    <IconButton onClick={e => {
                e.stopPropagation();
                setDeletePhoneModal(phoneNumber);
              }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>}
              </Stack>
            </Box>;
      }
      return <></>;
    }
  }];
  const handleRowClick = row => {
    setRenamePhoneModal(row);
  };
  const refresh = () => {
    setIsRefreshing(true);
    refreshPhoneRegistration(asCompany.id).then(({
      data
    }) => {
      setProfile(original => ({
        ...original,
        ...data
      }));
      setIsRefreshing(false);
    });
  };
  if (loading) return <Loader overlay />;
  let bannerType = 'secondary';
  if (profile?.brand_status === 'VERIFIED' && profile?.campaign_status === 'APPROVED') {
    bannerType = 'success';
  } else if (profile?.brand_status === 'FAILED' || profile?.campaign_status === 'FAILED') {
    bannerType = 'error';
  }
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type={`Phone Numbers ${phoneNumberPagination.count ? `(${utils.commaize(phoneNumberPagination.count)})` : ''}`} icon={<Icon name="custom-call" size={34} />} />

        <div className="mui-wrapper">
          <Banner type={bannerType}>
            <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
              <Box sx={{
              display: 'flex',
              flexDirection: 'column'
            }}>
                <h3>10DLC Registration</h3>
                <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px'
              }}>
                  <Box>
                    Brand Status:
                  </Box>
                  <StatusChip status={profile?.brand_status} />
                  <Box>
                    Campaign Status:
                  </Box>
                  <StatusChip status={profile?.campaign_status} />
                </Box>
              </Box>
              {isRefreshing ? <Loader size="sm" /> : <Button onClick={refresh} actionType="flat" icon="fa-regular-sync-alt" />}

            </Box>
          </Banner>
          {' '}
          {/* The banner to display if the customer needs to register more phone numbers */}
          {/* <Banner type={phoneNumbersNeeded <= phoneNumberPagination.count ? 'success' : 'warning'} onClick={() => setShowCalcTool(true)}>
            {phoneNumbersNeeded <= phoneNumberPagination.count && (
              <>Based on your number of customers, you have at least the number of phone numbers we recommend.</>
            )}
            {phoneNumbersNeeded > phoneNumberPagination.count && <>We recommend you have {phoneNumbersNeeded} phone numbers.</>}
           </Banner> */}
          <EnhancedTable type="Phone Numbers" headCells={headCells} api={getPhoneNumbers} defaultSort={'-updated_when'} handleRowClick={row => {
          if (hasPermission('communication.change_phonenumber')) {
            handleRowClick(row);
          } else {
            snackbarService.popup({
              type: 'error',
              message: 'You do not have permission to edit this phone number. You need the Manage Phone Number permission on the Roles page'
            });
          }
        }} actions={hasPermission('communication.add_phonenumber') && <NewButton onClick={e => onCreate()} />} />
        </div>

        <Modal open={showCalcTool} onClose={() => setShowCalcTool(false)} allowBackdropClick title="Phone Number Calculation Tool">
          <div className="modal__body">
            <div className="form-group">
              <label>Message Type</label>
              <ButtonGroup>
                <Button className={classnames({
                active: sendType === 'sms'
              })} actionType="white" onClick={() => setSendType('sms')}>
                  SMS
                </Button>
                <Button className={classnames({
                active: sendType === 'mms'
              })} actionType="white" onClick={() => setSendType('mms')}>
                  MMS
                </Button>
              </ButtonGroup>
            </div>
            <div className="form-group">
              <label>Number of Unique Phone Numbers</label>
              <input className="form-control" name="name" type="number" onChange={e => setCustomerCount(parseFloat(e.target.value))} value={customerCount} />
            </div>
            <div className="form-group">
              <label>Time to Send</label>
              <input className="form-control" name="name" type="number" onChange={e => setTimeToSend(parseFloat(e.target.value))} value={timeToSend} />
              <div className="text-sm mt-sm">Number of minutes to send a message to all of your customers</div>
            </div>
            <div className="form-group">
              <label>Max Message Per Minute SMS</label>
              <input className="form-control" name="name" type="number" onChange={e => setSMSMPM(parseFloat(e.target.value))} value={SMSMPM} />
              <div className="text-sm mt-sm">Number of messages each phone number can send per minute</div>
            </div>
            <div className="form-group">
              <label>Max Message Per Minute MMS</label>
              <input className="form-control" name="name" type="number" onChange={e => setMMSMPM(parseFloat(e.target.value))} value={MMSMPM} />
              <div className="text-sm mt-sm">Number of messages each phone number can send per minute</div>
            </div>
            <hr />
            <div className="form-group">
              <label>Number of Phone Numbers Needed</label>
              <input className="form-control" name="name" type="number" disabled={true} value={phoneNumbersNeeded} />
            </div>
          </div>
          <div className="modal__actions">
            <Button onClick={() => setShowCalcTool(false)}>Done</Button>
          </div>
        </Modal>
        <ModalDialog open={!!deletePhoneModal} title="Release Phone Number" submitText="Release" onSubmit={() => releaseNumber(deletePhoneModal)} onClose={() => setDeletePhoneModal(null)} actionType="warning" allowBackdropClick>
          <p>
            <strong>{!!deletePhoneModal && deletePhoneModal.phone}</strong>
          </p>
          <p>Are you sure you want to release this phone number? This action cannot be undone.</p>
        </ModalDialog>
        <ModalDialog open={!!renamePhoneModal} title="Rename Phone Number" onSubmit={() => renameNumber(renamePhoneModal)} onClose={() => setRenamePhoneModal(null)} allowBackdropClick>
          <p>This is a friendly name for the phone number and only impacts how the number is displayed in Cinch.</p>
          <div className="form-group">
            <label>New name for phone number</label>
            <input className="form-control" name="name" value={renamePhoneModal && renamePhoneModal.name || ''} onChange={e => setRenamePhoneModal({
            ...renamePhoneModal,
            name: e.target.value
          })} />
          </div>
        </ModalDialog>
        <PhoneNumberModal profile={profile} open={phoneNumberModal} loadData={loadData} onClose={() => setPhoneNumberModal(false)} />
      </div>
    </Scoped>;
}