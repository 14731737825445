import React from 'react';
function Icon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <mask id="mask0_10_673" style={{
      maskType: 'alpha'
    }} width="25" height="25" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.902 0.141H24.902V24.141H0.902z"></path>
      </mask>
      <g mask="url(#mask0_10_673)">
        <path fill={props.fill || '#107EBE'} d="M7.21 21.641c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.282V4.449c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h7.194c.241 0 .473.047.695.14.223.094.416.223.58.387l4.196 4.196c.165.164.293.358.387.58.093.223.14.454.14.695v11.195c0 .505-.175.932-.525 1.282-.35.35-.777.525-1.282.525H7.21zm7.192-13.904V4.141H7.21a.294.294 0 00-.212.096.294.294 0 00-.096.212v15.385c0 .076.032.147.096.211a.294.294 0 00.212.096h11.385a.294.294 0 00.211-.096.294.294 0 00.096-.211V8.64h-3.596a.876.876 0 01-.645-.258.876.876 0 01-.259-.646z"></path>
      </g>
    </svg>;
}
export default Icon;