import { api, unwrap } from './api';
export function getForms(params) {
  return api.get('/forms', {
    params: {
      archived: false,
      ...params
    }
  });
}
export function getForm(formId, params) {
  return api.get(`/forms/${formId}`, {
    params
  });
}
export function postForm(body) {
  return api.post('/forms', body);
}
export function patchForm(formId, body) {
  return api.patch(`/forms/${formId}`, body);
}
export function deleteForm(formId) {
  return api.delete(`/forms/${formId}`);
}
export function getFormsTags(companyId) {
  return api.get(`/forms/tags/${companyId}`);
}
export function duplicateForm(id, company_ids) {
  return api.post(`/forms/${id}/duplicate`, {
    company_ids
  });
}

// facebook forms fetch
// ----------------------
export function fetchFacebookForms(tokenId) {
  return api.post(`/facebook/token/${tokenId}/fetch_forms`).then(unwrap);
}