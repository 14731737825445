import { getJourneyNodes, createNode, removeLink, removeNode, createLink, patchRevision } from 'shared/common.api';
import { Application, Container, Graphics, Texture, TilingSprite } from 'pixi.js';
import { Node } from './node/node';
import { Text } from './text/text';
import { NodeLink } from './node/node-link';
import { MultiSelect } from './multi-select';
import { nodesCanConnect, linkExists, buildBackdrop, MODES, emptyState } from './node/node-utils';
import { cloneDeep, isEmpty } from 'lodash';
import { pixiUtils } from './node/pixi.utils';

// Canvas DPI
const RATIO = window.devicePixelRatio || 1;
let sx, sy;
class APIController {
  controllers = [];
  add(controller) {
    this.controllers.push(controller);
  }
  remove(controller) {
    const index = this.controllers.indexOf(controller);
    if (index > -1) {
      this.controllers.splice(controller, 1);
    }
  }
  abort() {
    this.controllers.forEach(controller => controller.abort());
    this.controllers = [];
  }
}
function boxesIntersect(a, b) {
  var ab = a.getBounds();
  var bb = b.getBounds();
  return ab.x + ab.width > bb.x && ab.x < bb.x + bb.width && ab.y + ab.height > bb.y && ab.y < bb.y + bb.height;
}
export class JourneyApp {
  nodes = [];
  links = [];
  texts = [];
  nodeLibrary = [];
  invalidNodes = [];

  // Most of these defaults get overwritten by the component.
  el = null;
  revision = null;
  mode = MODES.PAN;
  showGrid = true;
  snapToGrid = true;
  editable = false;
  trigger = null;
  zoom = 1;

  // Pixi containers
  app = null;
  movableContainer = null;
  linksContainer = null;
  nodesContainer = null;
  textsContainer = null;
  gridContainer = null;
  multiSelect = null;

  // Drag tracker
  data = null;

  // contextualized resize function so we have the proper `this`
  resizeListener = null;

  // Current API calls that can be aborted if necessary
  abortController = new APIController();
  constructor(el, editable, trigger) {
    this.el = el;
    this.editable = editable;
    this.trigger = trigger;
    this.app = new Application({
      antialias: true,
      resolution: RATIO,
      backgroundColor: 0xffffff,
      forceCanvas: true
    });
    this.app.view.style.transformOrigin = '0 0';
    this.app.view.style.transform = `scale(${1 / RATIO})`;
    this.movableContainer = new Container();
    this.linksContainer = new Container();
    this.nodesContainer = new Container();
    this.textsContainer = new Container();
    this.movableContainer.addChild(this.linksContainer);
    this.movableContainer.addChild(this.nodesContainer);
    this.movableContainer.addChild(this.textsContainer);
    this.movableContainer.interactive = true;
    this.movableContainer.on('pointerdown', this.onDragStart).on('pointerup', this.onDragEnd).on('pointerupoutside', this.onDragEnd).on('pointermove', this.onDragMove);
    this.multiSelect = new MultiSelect((...args) => this.on(...args), this.snapToGrid, this);
    this.movableContainer.addChild(this.multiSelect.container);
    this.app.stage.addChild(this.movableContainer);
    this.el = el;
    this.el.replaceChildren(this.app.view);
    this.el.ondragover = e => e.preventDefault();
    this.el.oncontextmenu = e => e.preventDefault();
    this.el.addEventListener('mousewheel', this.scroll, false);
    this.resizeListener = this.resize.bind(this);
    window.addEventListener('resize', this.resizeListener);
    this.resize();

    // GRID CONTAINER
    const canvas = document.createElement('canvas');
    const {
      clientWidth: width,
      clientHeight: height
    } = this.el;
    canvas.width = 25;
    canvas.height = 25;
    const context = canvas.getContext('2d');
    context.beginPath();
    context.moveTo(25, 0);
    context.lineTo(0, 0);
    context.lineTo(0, 25);
    context.lineWidth = 1;
    context.strokeStyle = '#d3d3d3';
    context.stroke();
    const tileTexture = Texture.from(canvas);
    this.gridContainer = new TilingSprite(tileTexture, width * 4, height * 4);
    this.gridContainer.x = -100;
    this.gridContainer.y = -100;
    this.gridContainer.alpha = 0;
    this.movableContainer.addChildAt(this.gridContainer, 0);

    // Node shaker
    this.app.ticker.add(function (delta) {
      pixiUtils.update();
    });

    // We don't automatically hit this endpoint for read-only journeys
    this.setCursor();
  }
  resize() {
    this.app.renderer.resize(this.el.parentElement.clientWidth, this.el.parentElement.clientHeight);
  }
  destroy() {
    this.texts.forEach(text => text.willDestroy());
    this.app.destroy(true);
    this.app = null;
    window.removeEventListener('resize', this.resizeListener);
  }
  scroll = e => {
    if (this.mode === MODES.EDIT_PARAM) return;
    this.moveCanvas(Math.round(this.movableContainer.x - e.deltaX / 3), Math.round(this.movableContainer.y - e.deltaY / 3));
  };

  // WARNING! You must use this function for setting the x/y of the movable container.  If you move it manually it will not update the grid
  moveCanvas = (x, y) => {
    this.movableContainer.x = x;
    this.movableContainer.y = y;

    // Update grid container.  We do this to get infinite scrolling.
    this.gridContainer.x = Math.round(this.movableContainer.x / 25) * -25 - 100;
    this.gridContainer.y = Math.round(this.movableContainer.y / 25) * -25 - 100;
  };
  init() {
    if (!this.revision) {
      throw new Exception('Unable to initialize application. Missing revision');
    }
    this.links.forEach(link => link.willDestroy());
    this.texts.forEach(text => text.willDestroy());
    this.nodes.forEach(node => node.willDestroy());
    this.nodes = [];
    this.links = [];
    this.texts = [];
    this.invalidNodes = [];

    // Clear all previous API calls.
    this.abortController.abort();

    // Load custom text
    this.revision.meta?.texts.forEach(text => {
      this.addText(text.text, {
        x: text.x,
        y: text.y
      });
    });
    let promise;
    if (this.revision.nodes) {
      // We already have the nodes so don't query them:
      promise = Promise.resolve({
        data: {
          nodes: this.revision.nodes
        }
      });
    } else {
      // Load nodes with abort controller
      // TODO this endpoint returns a bunch of additional information I don't think we need anymore.
      promise = getJourneyNodes(this.revision.id);
    }
    if (promise.controller) {
      this.abortController.add(promise.controller);
    }
    promise.then(({
      data
    }) => {
      if (promise.controller) {
        this.abortController.remove(promise.controller);
      }

      // Add each node to canvas
      data.nodes.forEach(node => {
        this.addNode(node.template, node.name, {
          x: node.x,
          y: node.y
        }, node.id, node.parameters, (node.from_links || []).length > 0);
      });

      // After all nodes are added to canvas add links
      data.nodes.forEach(node => {
        (node.from_links || []).forEach(link => {
          const initNode = this.nodes.find(n => n.id === node.id);
          const fromNode = this.nodes.find(node => {
            return node.id === link.from_node;
          });
          const toNode = this.nodes.find(node => node.id === link.to_node);
          this.addLink(fromNode, toNode, link.id, link.on_event, initNode.onEventList, initNode.getEvent, initNode.getEventList);
        });
      });
    });
  }

  /**
   * Interface
   * ---------
   *
   * These are functions we expect to be called from outside of this class to update the app as necessary
   * **/

  setNodeLibrary(nodeLibrary) {
    this.nodeLibrary = nodeLibrary;
    this.multiSelect.setNodeLibrary(nodeLibrary);
    if (this.revision) {
      // We probably shouldn't get here but for some reason the revision was loaded first so now we
      // need to init the app.
      this.init();
    }
  }
  addNodeToLibrary(node) {
    this.nodeLibrary.push(node);
    this.multiSelect.setNodeLibrary(this.nodeLibrary);
  }
  setZoom(zoom) {
    this.zoom = zoom;
    this.movableContainer.scale = {
      x: zoom,
      y: zoom
    };
  }
  setRevision(revision) {
    if (!revision) return;
    this.revision = revision;
    if (this.nodeLibrary.length > 0) {
      this.init();
    }
  }
  setShowGrid(showGrid) {
    this.showGrid = showGrid;
    this.gridContainer.alpha = showGrid ? 1 : 0;
  }
  setSnapToGrid(snapToGrid) {
    this.snapToGrid = snapToGrid;

    // Update all nodes modes as well
    // We're doing this to avoid using a global state thingymadoodle
    this.nodes.forEach(node => node.setSnapToGrid(snapToGrid));

    // Update multi select as well
    // We're doing this to avoid using a global state thingymadoodle
    this.multiSelect.setSnapToGrid(snapToGrid);
  }
  setMode(mode) {
    this.mode = mode;

    // Update all nodes modes as well
    // We're doing this to avoid using a global state thingymadoodle
    this.nodes.forEach(node => node.setMode(mode));

    // Update all links modes as well
    // We're doing this to avoid using a global state thingymadoodle
    this.links.forEach(link => link.setMode(mode));

    // Update all links modes as well
    // We're doing this to avoid using a global state thingymadoodle
    this.texts.forEach(text => text.setMode(mode));
    this.setCursor();
  }
  setCursor() {
    if (this.mode === MODES.PAN) {
      this.el.style.cursor = 'all-scroll';
    } else if (this.mode === MODES.TEXT) {
      this.el.style.cursor = 'text';
    } else {
      this.el.style.cursor = 'default';
    }
  }
  analyticsTimeout = null;
  setAnalytics(analytics) {
    clearTimeout(this.analyticsTimeout);
    if (this.nodes.length === 0 && !isEmpty(analytics)) {
      // We don't have nodes yet. Wait a second and try again.
      this.analyticsTimeout = setTimeout(() => this.setAnalytics(analytics), 500);
    }
    let analyticObj;
    // Convert list to object by IDs
    if (analytics.reduce) {
      analyticObj = analytics.reduce((res, item) => {
        res[item.id] = item;
        return res;
      }, {});
    } else {
      analyticObj = analytics;
    }
    this.nodes.forEach(node => {
      if (analyticObj[node.id]) {
        node.updateStats(analyticObj[node.id].enter, analyticObj[node.id].leave);
      } else {
        node.updateStats(0, 0);
      }
    });
  }

  /**
   * Mouse Handling
   * ---------
   * **/

  isMultiSelectRelated = target => {
    //const childCheck = (e) => {
    //  return e === this.multiSelect.container || e.children.some(childCheck);
    //}

    const parentCheck = e => {
      return e === this.multiSelect.container || !!e.parent && parentCheck(e.parent);
    };
    return parentCheck(target);
  };
  onDragStart = event => {
    if (this.mode === MODES.PAN) {
      this.movableContainer.dragging = true;
      this.data = event.data;
      this.data.sx = this.data.getLocalPosition(this.movableContainer).x * this.movableContainer.scale.x;
      this.data.sy = this.data.getLocalPosition(this.movableContainer).y * this.movableContainer.scale.y;
    }
    if (this.mode === MODES.CANVAS && this.multiSelect.active && !this.isMultiSelectRelated(event.target)) {
      this.multiSelect.stop();
    }
    if (this.mode === MODES.CANVAS && event.target === this.movableContainer) {
      this.data = event.data;
      this.data.sx = this.data.getLocalPosition(this.movableContainer).x;
      this.data.sy = this.data.getLocalPosition(this.movableContainer).y;
      this.multiSelect.start(this.data.sx, this.data.sy);
    }
  };
  onDragMove = () => {
    if (this.movableContainer.dragging) {
      this.movableContainer.hasDragged = true;
      const newPosition = this.data.getLocalPosition(this.movableContainer.parent);
      const x = newPosition.x - this.data.sx;
      const y = newPosition.y - this.data.sy;
      this.moveCanvas(Math.round(x), Math.round(y));
    }
    if (this.multiSelect.sizing) {
      const newPosition = this.data.getLocalPosition(this.movableContainer);
      this.multiSelect.setSize(newPosition.x - this.data.sx, newPosition.y - this.data.sy);
      this.checkMultiSelectIntersect(this.data.sx, this.data.sy, newPosition.x - this.data.sx, newPosition.y - this.data.sy);
    }
  };
  onDragEnd = event => {
    if (this.movableContainer.dragging) {
      this.movableContainer.dragging = false;
    }
    if (this.multiSelect.active) {
      this.multiSelect.selectEnd(event);
    }
    if (this.mode === MODES.TEXT && event.target === this.movableContainer) {
      this.addText('', event.data.getLocalPosition(this.movableContainer), true);
    }
  };
  on = (event, obj, ...args) => {
    if (event === 'REMOVE_NODE') {
      this.removeNode(obj);
    } else if (event === 'DUPLICATE_NODE') {
      this.duplicateNode(obj);
    } else if (event === 'SORT_NODES') {
      this.sortNodes(obj);
    } else if (event === 'REMOVE_LINK') {
      this.removeLink(obj);
    } else if (event === 'UPDATE_NODE_LINKS') {
      this.links.forEach(link => {
        if (link.nodeFrom === obj || link.nodeTo === obj) {
          link.updateNodes();
        }
      });
    } else if (event === 'START_POINT_LINK') {
      const [x, y] = args;
      this.setMode(MODES.POINT_LINK);
      this.nodeFrom = obj;
      this.pointLink = new NodeLink(obj.onEventList);
      this.pointLink.customPath(x, y, x, y);
      this.linksContainer.addChild(this.pointLink.container);
    } else if (event === 'UPDATE_POINT_LINK') {
      const [data] = args;
      const {
        x,
        y
      } = data.getLocalPosition(this.linksContainer);
      this.pointLink.updatePointLink(x, y);
    } else if (event === 'END_POINT_LINK') {
      if (this.mode !== MODES.POINT_LINK) return;
      this.setMode(MODES.CANVAS);
      this.linksContainer.removeChild(this.pointLink.container);
    } else if (event === 'ADD_LINK') {
      this.addLink(this.nodeFrom || args[0], obj);
      this.nodeFrom = null;
    } else if (event === 'SAVE_TEXT') {
      this.saveText();
    } else if (event === 'REMOVE_TEXT') {
      this.removeText(obj);
    } else if (event === 'INVALID_NODE' && this.invalidNodes.indexOf(obj) === -1) {
      // Newly invalid node
      this.invalidNodes.push(obj);
      this.trigger('INVALID_NODES', this.invalidNodes);
    } else if (event === 'VALID_NODE' && this.invalidNodes.indexOf(obj) > -1) {
      // Newly valid node
      this.invalidNodes = this.invalidNodes.filter(node => node !== obj);
      this.trigger('INVALID_NODES', this.invalidNodes);
    } else if (event === 'MULTI_SELECT_START') {
      // We don't always clear this out properly so we need to clear it out here just in case.
      this.nodeFrom = null;
    }

    // propogate events to parent
    if (this.trigger) {
      this.trigger(event, obj, ...args);
    }
  };

  /**
   * MultiSelect
   * ---------
   * **/
  checkMultiSelectIntersect = (x, y, width, height) => {
    const textIntersections = [];
    const nodeIntersections = [];
    if (width === 0 || height === 0) {
      return;
    }
    if (width < 0) {
      x += width;
      width = -width;
    }
    if (height < 0) {
      y += height;
      height = -height;
    }
    const scale = this.movableContainer.scale;
    x += this.movableContainer.x / scale.x;
    y += this.movableContainer.y / scale.y;
    const box = {
      getBounds: () => ({
        x: x * this.movableContainer.scale.x,
        y: y * this.movableContainer.scale.y,
        width: width,
        height: height
      })
    };
    this.nodes.forEach(node => {
      if (boxesIntersect(node.container, box)) {
        nodeIntersections.push(node);
      }
    });
    this.texts.forEach(text => {
      if (boxesIntersect(text.container, box)) {
        textIntersections.push(text);
      }
    });
    this.multiSelect.setTextIntersections(textIntersections);
    this.multiSelect.setNodeIntersections(nodeIntersections);
  };

  /**
   * Nodes
   * ---------
   * **/

  sortNodes = node => {
    this.nodesContainer.children.sort((a, b) => {
      if (a === node.container) return 1;
      if (b === node.container) return -1;
      return 0;
    });
  };
  addNode = (templateId, subType, position, id, parameters, hasLinks) => {
    // Merge the database node with the library node.
    const item = cloneDeep(this.nodeLibrary.find(node => !templateId && node.subType === subType || templateId && node.templateId === templateId) || {});
    item.defaultParameters = cloneDeep(item.parameters);
    item.parameters = parameters || (item.initParams ? item.initParams() : item.parameters);

    // Generate PIXI node
    const node = new Node(item, position, hasLinks, this.mode, this.snapToGrid, (...args) => this.on(...args));
    node.id = id;
    this.nodesContainer.addChild(node.container);
    this.nodes.push(node);

    // TODO do this in the react component
    if (id) {
      node.id = id;
      node.shape.children[0].alpha = 1;
    } else {
      createNode(node.type, node.subType, node.templateId, node.container.x, node.container.y, this.revision.id, item.parameters).then(({
        data
      }) => {
        node.id = data.id;
        node.shape.children[0].alpha = 1;
      });
    }
    if (!node.isValidated()) {
      this.invalidNodes.push(node);
      this.trigger('INVALID_NODES', this.invalidNodes);
    }
    return node;
  };
  removeNode = node => {
    const nodeIndex = this.nodes.findIndex(n => n === node);
    this.nodesContainer.removeChild(node.container);
    node.willDestroy();
    this.nodes = [...this.nodes.slice(0, nodeIndex), ...this.nodes.slice(nodeIndex + 1)];
    Promise.all(this.removeLinkByNode(node)).then(() => removeNode(node.id));
  };
  duplicateNode = node => {
    const position = {
      x: node.initialPosition.x + 150,
      y: node.initialPosition.y
    };
    this.addNode(node.templateId, node.subType, position, null, node.parameters);
  };

  /**
   * Texts
   * ---------
   * **/

  addText = (text, position, autoEditParam = false) => {
    const textObj = new Text(text, position, this.mode, (...args) => this.on(...args));
    this.textsContainer.addChild(textObj.container);
    this.texts.push(textObj);
    if (autoEditParam) {
      this.editTextParam(textObj);
    }
    return textObj;
  };
  removeText = textToDelete => {
    this.textsContainer.removeChild(textToDelete.container);
    textToDelete.willDestroy();
    this.texts = this.texts.filter(text => text !== textToDelete);
    this.saveText();
  };
  saveText = () => {
    const texts = this.texts.map(text => ({
      text: text.parameters.text,
      x: text.container.x,
      y: text.container.y
    }));
    patchRevision(this.revision.id, {
      meta: {
        texts
      }
    });
  };
  editTextParam = textObj => {
    this.trigger('EDIT_PARAM', textObj, this.mode);
  };

  /**
   * Links
   * ---------
   * **/

  addLink = (from, to, id, onEvent, onEventList, getEvent, getEventList) => {
    if (from && to && from !== to && !linkExists(from, to, this.links) && nodesCanConnect(from, to)) {
      const link = new NodeLink(this.mode, (...args) => this.on(...args));
      link.connect(from, to, onEvent);
      this.linksContainer.addChild(link.container);
      this.links.push(link);
      if (onEvent && getEvent) {
        getEvent(onEvent).then(event => {
          link.eventText.text = event.toUpperCase();
          link.updateNodes();
        });
      } else if (onEvent && onEventList) {
        const event = onEventList.find(item => item.id === onEvent);
        if (event) {
          link.eventText.text = event.name.toUpperCase();
          link.updateNodes();
        }
      } else if (onEvent) {
        link.eventText.text = onEvent.toUpperCase();
        link.updateNodes();
      }
      if (id) {
        link.id = id;
      } else {
        this.trigger('NEW_LINK', link);
        createLink(from.id, to.id, onEvent).then(({
          data
        }) => {
          link.id = data.id;
          if (!onEvent && (from.onEventList || from.getEventList)) {
            this.trigger('END_POINT_LINK', link);
            this.trigger('EDIT_PARAM', from, this.mode, link);
          }
        });
      }
    }
  };
  removeLink = linkToDelete => {
    this.linksContainer.removeChild(linkToDelete.container);
    this.links = this.links.filter(link => link !== linkToDelete);
    removeLink(linkToDelete.id);
  };
  removeLinkByNode = node => {
    const linksToRemove = this.links.filter(link => link.nodeFrom === node || link.nodeTo === node);
    this.links = this.links.filter(link => link.nodeFrom !== node && link.nodeTo !== node);
    return linksToRemove.map(link => {
      this.linksContainer.removeChild(link.container);
      link.willDestroy();
      return removeLink(link.id);
    });
  };
}