import React from 'react';
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Rating from '@mui/material/Rating';
import moment from 'moment';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'nowrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    overflow: 'auto'
  },
  tab: {
    opacity: 1,
    minWidth: '0px',
    padding: 0
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#DAEFFF',
    color: '#53A6D6',
    borderRadius: '8px',
    textTransform: 'none'
  }
}));
export const ChipsArray = props => {
  const classes = useStyles();
  return <Box className={classes.root} sx={props.sx}>
      <Tabs variant="scrollable" value={false} scrollButtons="auto">
        {props.filterOptions.filter(f => !f.hidden?.hideChip).map(option => {
        const {
          key: k,
          ...subProps
        } = option;
        return <Tab key={k} className={classes.tab} label={React.createElement(option.component, {
          ...subProps,
          filterKey: k,
          filters: props.filters,
          handleFilterChange: props.handleFilterChange,
          showChip: true,
          chipStyle: classes.chip // TODO maybe change this to a theme provider so we don't have to pass it down?
        })} />;
      })}
      </Tabs>
    </Box>;
};