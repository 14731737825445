import React, { Component } from 'react';
import classnames from 'classnames';
import { func, object, shape, string, array, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { UserStateContext } from 'context/user-state-context';
import { getPhoneNumbers } from '../../../../shared/common.api';
import { ModalFileViewer } from '../../../../components/file-viewer/modal-file-viewer.component';
import { fileSize } from '../../../../components/file-viewer/utils';
import { ModalDialog, Dropdown, Button, Icon, Toggle } from 'components';
import { StaticTypeAhead } from '../../../../components/static-type-ahead/static-type-ahead.component';
import utils from '../../../../shared/utils';
import { Emoji } from 'components/emoji/emoji.component';
import { SmsTemplatesMenu } from 'components/templates/sms-templates.component';
const style = {
  textareaFooter: {
    color: '#f28837',
    position: 'relative',
    bottom: '0px',
    left: '8px'
  }
};
export class ActionMMS extends Component {
  static contextType = UserStateContext;
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      numbers: [],
      loading: true,
      addFileOpen: false,
      statusUnderstood: false,
      optAgreeOpen: false,
      messageHeight: 50
    };
  }
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      from: string,
      message: string,
      media: array,
      optStatuses: array,
      create_unique_urls: bool
    })
  };
  componentDidMount() {
    getPhoneNumbers({
      company: this.context.asCompany.id,
      limit: 1000
    }).then(numbers => {
      const numbersWithFormat = numbers.data.results.map(number => {
        let numberFormat = {
          ...number
        };
        numberFormat.phone = utils.formatPhoneNumber(numberFormat.phone);
        return numberFormat;
      });
      this.setState({
        numbers: [{
          id: '',
          phone: 'Automatic'
        }, {
          id: 'location',
          phone: 'Last Location Phone Number'
        }].concat(numbersWithFormat),
        loading: false
      });
    });
  }
  onMessageChange = e => {
    this.setState({
      messageHeight: e.target.scrollHeight
    });
    this.props.update({
      message: e.target.value
    });
  };
  onFromChange = from => {
    this.props.update({
      from
    });
  };
  getMessageCount = message => {
    const messageCount = Math.max(Math.ceil((message.length + 21) / 160), 1);
    let result = `${message.length + 21}* out of 1,600 characters`;
    return result;
  };
  insertData = e => {
    const {
      message
    } = this.props.data;
    this.props.update({
      message: `${message || ''}{{{${e.target.value}}}}`
    });
  };
  insertEmoji = e => {
    this.props.update({
      message: this.props.data.message ? this.props.data.message + e : e
    });
  };
  handleTemplateChoice = (template, revision) => {
    this.props.update({
      message: this.props.data.message ? this.props.data.message + revision.message : revision.message,
      media: [...this.props.data.media, ...revision.media]
    });
  };
  getId = (groupId, fieldId) => {
    return `${groupId}.${fieldId.replace(/__/g, '.')}`;
  };
  addFile = file => {
    if (this.props.data.media.find(m => m.Key === file.Key)) {
      // File already added...
      return;
    }
    this.props.update({
      media: [...this.props.data.media, file]
    });
  };
  removeFile = file => {
    this.props.update({
      media: this.props.data.media.filter(m => m.Key !== file.Key)
    });
  };
  toggleStatus = status => {
    const optStatuses = this.props.data.optStatuses ? [...this.props.data.optStatuses] : [];
    const index = optStatuses.indexOf(status);
    if (index === -1) {
      optStatuses.push(status);
    } else {
      optStatuses.splice(index, 1);
    }

    // If any status other than opt_in is there we need to get approval.
    if (!this.state.statusUnderstood && (optStatuses.indexOf('not_opt_in') > -1 || optStatuses.indexOf('pending') > -1 || optStatuses.indexOf('unknown') > -1 || optStatuses.indexOf('suppressed') > -1)) {
      this.setState({
        optAgreeOpen: true
      });
    }
    this.props.update({
      optStatuses
    });
  };
  render() {
    const {
      message,
      from,
      media,
      optStatuses
    } = this.props.data;
    const {
      numbers,
      loading,
      addFileOpen,
      optAgreeOpen,
      messageHeight
    } = this.state;
    const fields = this.context.companyFields;
    const selectedFrom = numbers.find(n => n.id === from);
    const statuses = [];
    if (optStatuses && optStatuses.indexOf('opt_in') > -1) {
      statuses.push('Opt In');
    }
    if (optStatuses && optStatuses.indexOf('pending') > -1) {
      statuses.push('Pending');
    }
    if (optStatuses && optStatuses.indexOf('suppressed') > -1) {
      statuses.push('Suppressed');
    }
    if (optStatuses && optStatuses.indexOf('unknown') > -1) {
      statuses.push('Unknown');
    }
    if (!loading && !numbers.length) {
      return <div>
          <p>In order to send text messages please set up a phone number.</p>
          <p>
            This can be done under <Link to="/phone-numbers">Assets &gt; Phone Numbers</Link>
          </p>
        </div>;
    }
    return <div>
        <div className="form-group">
          <label>From Phone Number</label>
          <StaticTypeAhead items={numbers} placeholder={selectedFrom ? selectedFrom.phone : 'Automatic'} displayProperty="phone" keyProperty="id" value={selectedFrom ? selectedFrom.id : ''} onChange={from => {
          from && this.onFromChange(from.id);
        }} />
        </div>
        <div className="form-group">
          <label>To Opt-In Status of</label>
          <Dropdown size="block" contentHeight={250} trigger={() => <Button dropdown block>
                {statuses.length ? statuses.join(', ') : 'Choose Statuses'}
              </Button>} content={() => <ul className="select-list">
                <li>
                  <a onClick={() => this.toggleStatus('opt_in')}>
                    <div className="select-list__content">Opt In</div>
                    {optStatuses && optStatuses.indexOf('opt_in') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                  </a>
                </li>
                <li>
                  <a onClick={() => this.toggleStatus('pending')}>
                    <div className="select-list__content">Pending</div>
                    {optStatuses && optStatuses.indexOf('pending') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                  </a>
                </li>
                <li>
                  <a onClick={() => this.toggleStatus('suppressed')}>
                    <div className="select-list__content">Suppressed</div>
                    {optStatuses && optStatuses.indexOf('suppressed') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                  </a>
                </li>
                <li>
                  <a onClick={() => this.toggleStatus('unknown')}>
                    <div className="select-list__content">Unknown</div>
                    {optStatuses && optStatuses.indexOf('unknown') > -1 && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                  </a>
                </li>
              </ul>} />
        </div>
        <div className="form-group">
          <div className="d-flex justify-content-between">
            <label>Media</label>
            {media.length < 10 && <Button icon="fa-regular-plus" actionType="flat" small onClick={() => this.setState({
            addFileOpen: true
          })} />}
          </div>
          {media.map(file => <div className="d-flex" key={file.Key}>
              <div className="flex-grow truncate">{file.name}</div>
              <div className="ml-2">{fileSize(file.Size)}</div>
              <div className="ml-2">
                <Button icon="fa-regular-times" actionType="flat" small onClick={() => this.removeFile(file)} />
              </div>
            </div>)}
          <div className="d-flex justify-content-between mt-2">
            <p className={classnames('text-sm', {
            'text-danger': media.length > 10
          })}>
              {media.length} of 10 files
            </p>
            <p className={classnames('text-sm', {
            'text-danger': media.reduce((x, y) => x + y.Size, 0) > 5200880
          })}>
              {fileSize(media.reduce((x, y) => x + y.Size, 0))} of 5 MB
            </p>
          </div>
        </div>
        <div className="form-group">
          <div className="d-flex justify-content-between align-items-center">
            <label className="my-0">Message</label>
            <div className="d-flex justify-content-between align-items-center">
              <SmsTemplatesMenu handleTemplateChoice={this.handleTemplateChoice} offset={{
              x: 60,
              y: 30
            }} />
              <Emoji openDirection="right" onEmojiClick={e => this.insertEmoji(e)} />
            </div>
          </div>
          <div className="form-control" style={{
          height: 'auto',
          padding: '0px'
        }}>
            <textarea cols="3" className="form-control form-message" style={{
            border: 'none',
            boxShadow: 'none',
            overflowY: 'scroll',
            maxHeight: '250px',
            height: `${messageHeight}px`,
            ':focus': {
              outline: 'none'
            }
          }} onChange={this.onMessageChange} value={message || ''} maxLength={1579} />
            <span style={style.textareaFooter}>Reply STOP to cancel</span>
          </div>
        </div>
        <div className="d-flex mb-3 justify-content-between">
          <span className="text-sm">{(message || '').length + 21}* out of 1,600 characters</span>
          <select className="form-control" style={{
          height: '2rem',
          width: '166px'
        }} onChange={this.insertData} value={''}>
            <option>Insert Dynamic Data</option>
            {fields.map(field => <optgroup key={field.id} label={field.name}>
                {field.fields.map(subfield => <option key={subfield.id} value={this.getId(field.id, subfield.id)}>
                    {subfield.name}
                  </option>)}
              </optgroup>)}
          </select>
        </div>

        <p>
          To improve deliverability MMS messages are throttled. You can adjust your throttling settings on the{' '}
          <a href={`/companies/${this.context.asCompany.id}`}>Company Settings</a> page.
        </p>

        <div>
          <Toggle checked={!!this.props.data.create_unique_urls} onToggle={() => {
          this.props.update({
            create_unique_urls: !this.props.data.create_unique_urls
          });
        }} />
          Create Unique URLs
        </div>

        <ModalFileViewer open={addFileOpen} onSubmit={this.addFile} onClose={() => this.setState({
        addFileOpen: false
      })} />
        <ModalDialog open={optAgreeOpen} title="SMS Liability Warning" cancelText="Nevermind" submitText="I Agree" onSubmit={() => this.setState({
        statusUnderstood: true
      })} onClose={() => this.setState({
        optAgreeOpen: false
      })} onCancel={() => this.props.update({
        optStatuses: ['opt_in']
      })}>
          <p>
            Sending SMS messages to numbers that have not expressly opted to receive such messages may expose you to civil liability under
            applicable law.
          </p>
          <p>
            By using this system to send SMS messages, you affirm that you have received express permission from the recipient to send the
            messages or that such messages are otherwise permitted under applicable law. Further, you agree to assume all such liability and
            to defend, indemnify, and hold harmless Cinch against all claims and damages whatsoever, including cost of defense, caused by
            your use of this system.
          </p>
        </ModalDialog>
      </div>;
  }
}