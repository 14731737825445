import { DynamicTypeAhead } from './dynamic-type-ahead.component';
import { DynamicTypeAhead as ReadDynamicTypeAhead } from './dynamic-type-ahead.read.component';
import { FromPhone } from './from-phone.component';
import { FromPhone as ReadFromPhone } from './from-phone.read.component';
import { SMSOptStatuses } from './sms-opt-statuses.component';
import { SMSOptStatuses as ReadSMSOptStatuses } from './sms-opt-statuses.read.component';
import { EmailOptStatuses } from './email-opt-statuses.component';
import { EmailOptStatuses as ReadEmailOptStatuses } from './email-opt-statuses.read.component';
import { SMSMessage } from './sms-message.component';
import { Text } from './text.component';
import { Text as ReadText } from './text.read.component';
import { Checkbox } from './checkbox.component';
import { Checkbox as ReadCheckbox } from './checkbox.read.component';
import { DynamicMultiTypeAhead } from './dynamic-multi-type-ahead.component';
import { DynamicMultiTypeAhead as ReadDynamicMultiTypeAhead } from './dynamic-multi-type-ahead.read.component';
import { MultiSelect } from './multi-select.component';
import { MultiSelect as ReadMultiSelect } from './multi-select.read.component';
import { ReviewMessage } from './review-message.component';
import { MultiItem } from './multi-item.component';
import { MultiItem as ReadMultiItem } from './multi-item.read.component';
import { Delay } from './delay.component';
import { Delay as ReadDelay } from './delay.read.component';
import { Select } from './select.component';
import { Select as ReadSelect } from './select.read.component';
export const EDIT_COMPONENT_MAP = {
  'dynamic-type-ahead': DynamicTypeAhead,
  'from-phone': FromPhone,
  'sms-message': SMSMessage,
  'sms-opt-statuses': SMSOptStatuses,
  'email-opt-statuses': EmailOptStatuses,
  'text': Text,
  'checkbox': Checkbox,
  'dynamic-multi-type-ahead': DynamicMultiTypeAhead,
  'multi-select': MultiSelect,
  'review-message': ReviewMessage,
  'multi-item': MultiItem,
  'delay': Delay,
  'select': Select
};
export const READ_COMPONENT_MAP = {
  'dynamic-type-ahead': ReadDynamicTypeAhead,
  'from-phone': ReadFromPhone,
  'sms-message': ReadText,
  'sms-opt-statuses': ReadSMSOptStatuses,
  'email-opt-statuses': ReadEmailOptStatuses,
  'text': ReadText,
  'checkbox': ReadCheckbox,
  'dynamic-multi-type-ahead': ReadDynamicMultiTypeAhead,
  'multi-select': ReadMultiSelect,
  'multi-item': ReadMultiItem,
  'delay': ReadDelay,
  'select': ReadSelect
};