import React, { Component } from 'react';
import { Scoped, a } from 'kremling';
import { string, func, object } from 'prop-types';
import { Icon } from '../icon/icon.component';
import Button from '@mui/material/Button';
import styles from './page-header-mui.scss';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { Divider, Grid, InputAdornment, Tooltip } from '@mui/material';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { NavLink } from 'react-router-dom';
import { addUserToLaunchDarklySegment, getCompanies, getFolder, removeUserFromLaunchDarklySegment } from 'shared/common.api';
import { useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import validator from 'validator';
import { useLocation, useHistory } from 'react-router-dom';
import { AddRoadIcon, CallIcon, CommunicationIcon, CompanyIcon, CustomersIcon, DataFieldsIcon, DomainIcon, EjectIcon, IntegrationsIcon, LocationOnIcon, LogoutIcon, MarketingIcon, MarketsIcon, RolesIcon, SettingsIcon } from 'components/mui';
import { Loader } from 'components/loader/loader.component';
const RoundedTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`
    }
  }
})(TextField);
const SearchTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F1F3F4',
      borderRadius: `20px`,
      '& fieldset': {
        border: 'none'
      }
    }
  }
})(TextField);
export const PageHeaderMui = props => {
  const {
    asCompany,
    hasPermission,
    setAsCompany,
    user,
    logOut,
    hasIntegration,
    flags,
    isUserEnrolledInLaunchDarklyBeta
  } = useContext(UserStateContext);
  const [editVal, setEditVal] = React.useState('');
  const [editName, setEditName] = React.useState(false);
  const [settingsAnchorEl, setSettingsAnchorEl] = React.useState(null);
  const settingsOpen = Boolean(settingsAnchorEl);
  const [settingsMenu, setSettingsMenu] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [companiesCount, setCompaniesCount] = React.useState(0);
  const [companiesAnchorEl, setCompaniesAnchorEl] = React.useState(null);
  const companiesOpen = Boolean(companiesAnchorEl);
  const [companiesSearch, setCompaniesSearch] = React.useState('');
  const [folderData, setFolderData] = React.useState();
  const history = useHistory();
  const {
    search
  } = useLocation();
  const searchParams = new URLSearchParams(search);
  const folderParam = searchParams.get('folder');
  const [avatarHover, setAvatarHover] = React.useState(null);
  const [backdrop, setBackdrop] = React.useState(false);
  const [showAllCompanies, setShowAllCompanies] = React.useState(false);
  const [recentlyUsedCompanies, setRecentlyUsedCompanies] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    setRecentlyUsedCompanies(localStorage.getItem('recentlyUsedCompanies') ? JSON.parse(localStorage.getItem('recentlyUsedCompanies')) : []);
  }, []);
  React.useEffect(() => {
    if (showAllCompanies) {
      getCompanies({
        limit: 9999,
        search: companiesSearch,
        view: 'quick',
        is_active: true
      }).then(({
        data
      }) => {
        setCompanies(data.results);
        setCompaniesCount(data.count);
      });
    }
  }, [showAllCompanies]);
  React.useEffect(() => {
    if (!user?.id) {
      return;
    }
    getCompanies({
      search: companiesSearch,
      view: 'quick',
      is_active: true
    }).then(({
      data
    }) => {
      setCompanies(data.results);
      setCompaniesCount(data.count);
      setShowAllCompanies(false);
    });
  }, [user?.id, companiesSearch]);
  React.useEffect(() => {
    setSettingsMenu([{
      key: '/companies/' + asCompany.id,
      name: 'Company',
      icon: CompanyIcon,
      enabled: true
    }, {
      key: '/locations',
      name: 'Locations',
      icon: LocationOnIcon,
      enabled: hasPermission('organization.view_location')
    }, {
      key: '/markets',
      //TODO: Implment markets page
      name: 'Markets',
      icon: MarketsIcon,
      enabled: false
    }, {
      key: '/integrations',
      name: 'Integrations',
      icon: IntegrationsIcon,
      enabled: hasPermission('integration.view_credential')
    }, {
      key: '/domains',
      name: 'Domains',
      icon: DomainIcon,
      enabled: hasPermission('email.view_domain')
    }, {
      key: '/users',
      name: 'Users',
      icon: CustomersIcon,
      enabled: hasPermission('authentication.view_user')
    }, {
      key: '/notification-list',
      name: 'Notification List',
      icon: CustomersIcon,
      enabled: hasPermission('authentication.view_user') && hasIntegration('notification_list')
    }, {
      key: '/roles',
      name: 'Roles',
      icon: RolesIcon,
      enabled: hasPermission('auth.view_group')
    }, {
      key: '/phone-numbers',
      name: 'Phone Numbers',
      icon: CallIcon,
      enabled: hasPermission('communication.view_phonenumber')
    }, {
      key: '/data-fields',
      name: 'Data Fields',
      icon: DataFieldsIcon,
      enabled: hasPermission('organization.view_company')
    }, {
      key: '/community',
      name: isUserEnrolledInLaunchDarklyBeta ? 'Roadmap & Feedback (Beta)' : 'Roadmap & Feedback',
      icon: MarketingIcon,
      enabled: flags.roadmapAndFeedback
    }, {
      key: 'beta',
      name: isUserEnrolledInLaunchDarklyBeta ? 'Leave Cinch Beta' : 'Join Cinch Beta',
      icon: isUserEnrolledInLaunchDarklyBeta ? EjectIcon : AddRoadIcon,
      enabled: true
    }, {
      key: 'logout',
      name: 'Sign out',
      icon: LogoutIcon,
      enabled: true
    }]);
  }, [hasPermission, asCompany?.id, flags, isUserEnrolledInLaunchDarklyBeta]);
  React.useEffect(() => {
    if (props.folderBreadcrumbs && folderParam && validator.isUUID(folderParam)) {
      getFolder(folderParam).then(({
        data
      }) => {
        setFolderData(data);
      });
    } else {
      setFolderData(null);
    }
  }, [folderParam, props.folderBreadcrumbs, editVal]);
  const handleSaveEdit = (old, restore) => {
    props.updateName(restore ? old : {
      id: old.id,
      name: editVal
    });
    setEditName(false);
    if (props.folderBreadcrumbs && folderParam && validator.isUUID(folderParam)) {
      getFolder(folderParam).then(({
        data
      }) => {
        setFolderData(data);
      });
    }
  };
  const handleEditName = () => {
    if (props.updateNamePermission) {
      setEditName(true);
      setEditVal(props.folderBreadcrumbs && folderData ? folderData.path[folderData.path.length - 1].name : props.name);
    }
  };
  const handleSnackbarUndo = old => {
    const undoName = editVal;
    setEditVal(old.name);
    handleSaveEdit(old, true);
    snackbarService.close();
    snackbarService.popup({
      message: `"${undoName}" changed back to "${old.name}"`,
      action: <React.Fragment>
          <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
    });
  };
  const handleSnackbar = old => {
    snackbarService.popup({
      message: '"' + old.name + '" changed to "' + editVal + '"',
      action: <React.Fragment>
          <Button size="small" onClick={e => handleSnackbarUndo(old)}>
            <Typography sx={{
            textTransform: 'none',
            color: '#53A6D6'
          }}>Undo</Typography>
          </Button>
          <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
    });
  };
  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };
  const handleSettingsOpen = event => {
    setSettingsAnchorEl(settingsOpen ? null : event.currentTarget);
  };
  const handleSaveClick = old => {
    handleSnackbar(old);
    handleSaveEdit(old);
  };
  const handleCompaniesOpen = event => {
    setCompaniesAnchorEl(companiesOpen ? null : event.currentTarget);
  };
  const handleCompaniesClose = () => {
    setCompaniesSearch('');
    setShowAllCompanies(false);
    setCompaniesAnchorEl(null);
  };
  const getInitals = name => {
    if (name) return name.split(' ').filter((segment, i) => i < 2).map(segment => segment[0]).join('').toUpperCase();
  };
  const handleSnackbarClose = () => {
    snackbarService.close();
    setBackdrop(false);
  };
  const handleCompanyChange = comp => {
    if (asCompany.id != comp.id) {
      setBackdrop(true);
      setAsCompany(comp);
      handleCompaniesClose();
      handleRecentlyUsedCompanies(comp);
      const path = window?.location?.pathname.split('/');
      for (var i = 0; i < path.length; i++) {
        if (validator.isUUID(path[i])) {
          history.push({
            pathname: '/' + path[1]
          });
        }
      }
      //clear filters when changing company
      const newurl = window.location.pathname;
      history.push({
        pathname: newurl
      });
      snackbarService.popup({
        horizontal: 'center',
        middle: 1,
        duration: 600000,
        message: 'You are now working as ' + comp.name,
        action: <React.Fragment>
            <Button size="small" onClick={e => handleSnackbarClose()}>
              <Typography sx={{
              textTransform: 'none',
              color: '#53A6D6'
            }}>Dismiss</Typography>
            </Button>
          </React.Fragment>
      });
    }
  };
  const reset = () => {
    setCompaniesSearch('');
  };
  const handleBreadcrumbClick = folderId => {
    if (props.folderBreadcrumbs) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get('folder') != folderId) {
        if (folderId != '') {
          queryParams.set('folder', folderId);
        } else {
          queryParams.delete('folder');
        }
        const newurl = window.location.pathname + '?' + queryParams.toString();
        history.push(newurl);
      }
    } else {
      const path = window?.location?.pathname.split('/');
      history.push({
        pathname: '/' + path[1]
      });
    }
  };
  const isSearchInCompanyName = c => {
    let response = true;
    const searchWords = companiesSearch.toLowerCase().split(' ');
    for (let i = 0; i < searchWords.length; i++) {
      if (!c.name.toLowerCase().includes(searchWords[i])) {
        response = false;
      }
    }
    return response;
  };
  const handleRecentlyUsedCompanies = comp => {
    let recents = recentlyUsedCompanies;
    if (recents.indexOf(comp) == -1) {
      if (recents.length == 3) {
        recents.pop();
      }
      recents.unshift(comp);
      localStorage.setItem('recentlyUsedCompanies', JSON.stringify(recents));
      setRecentlyUsedCompanies(recents);
    }
  };
  document.title = props.type ? props.type + ' - Cinch' : 'Cinch';
  return <>
      {isLoading && <Loader overlay background="#e4e9f1" />}
      <Scoped css={styles}>
        <div id="page-header" className="page-header secondary-color" style={{
        px: '20px',
        height: '64px'
      }}>
          <Box sx={{
          display: 'flex',
          width: '100%'
        }} className="d-flex w-100 justify-content-between">
            <Box sx={{
            display: 'flex'
          }} className="d-flex justify-content-between">
              {/*<Stack  spacing={3} sx={{ pl: '16px' , alignItems:'center',}} direction="row">   TODO: add back/forward history
               <Box >
                 <Icon name="custom-arrow_back" size={24} />
               </Box>
               <Box>
               <Icon name="custom-arrow_forward" size={24} />
               </Box>
               <Divider orientation="vertical"  sx={{height:'24px'}} />
               </Stack>*/}
              <Stack sx={{
              pl: '16px'
            }} direction="row">
                <Box sx={{
                py: 1,
                color: 'black',
                fontSize: 40
              }}>
                  {props.icon}
                </Box>
                <Typography onClick={() => {
                handleBreadcrumbClick('');
              }} sx={{
                fontFamily: 'Nexa',
                fontSize: 24,
                color: '#1D252D',
                textAlign: 'center',
                margin: 'auto',
                pl: 1,
                opacity: props.name ? '0.6' : '1',
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: props.folderBreadcrumbs && folderParam ? '#DAEFFF' : '#fff',
                  color: props.folderBreadcrumbs && folderParam ? '#53A6D6' : '#1D252D'
                }
              }}>
                  {props.type}
                </Typography>
                {props.folderBreadcrumbs && folderData && folderData.path.map((f, index) => <Typography key={f.id} onClick={() => {
                handleBreadcrumbClick(f.id);
              }} sx={{
                fontFamily: 'Nexa',
                fontSize: 24,
                color: '#1D252D',
                textAlign: 'center',
                margin: 'auto',
                pl: 1,
                opacity: '0.6',
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: '#DAEFFF',
                  color: '#53A6D6'
                }
              }}>
                      &gt; {index == folderData.path.length - 1 ? f.name : '' + f.name.slice(0, 10) + '...'}
                    </Typography>)}
                {(props.name || !('updateNamePermission' in props) || props.updateNamePermission) && <Stack direction="row" sx={{
                alignItems: 'center',
                gap: '12px'
              }}>
                    {props.name && <Typography sx={{
                  fontFamily: 'Nexa',
                  fontSize: 24,
                  color: '#1D252D',
                  textAlign: 'center',
                  margin: 'auto',
                  pl: 1,
                  opacity: '0.6'
                }}>
                        &gt;
                      </Typography>}
                    {props.objIcon}
                    {props.name && <Tooltip title={props.name}>
                        <Typography noWrap sx={{
                    maxWidth: 'calc(85vw - 70rem)',
                    fontFamily: 'Nexa',
                    fontSize: 24,
                    color: '#1D252D',
                    textAlign: 'center',
                    //margin: 'auto',
                    height: '28px',
                    pt: '6px',
                    cursor: props.updateNamePermission ? 'pointer' : 'default'
                  }} onClick={handleEditName}>
                          {props.name}
                        </Typography>
                      </Tooltip>}
                    {'updateName' in props && props.updateNamePermission && <Box onClick={handleEditName} sx={{
                  cursor: 'pointer'
                }}>
                        <Icon name="custom-pencil" size={24} />
                      </Box>}
                  </Stack>}
              </Stack>
            </Box>
          </Box>
          <Stack spacing={2} sx={{
          pl: '16px',
          alignItems: 'center'
        }} direction="row">
            <Box>
              <Stack spacing={1} sx={{
              pl: '16px',
              alignItems: 'center'
            }} direction="row">
                <Box>
                  <Icon name="custom-contacts" size={24} />
                </Box>
                <Typography variant="tableHeader" sx={{
                minWidth: '85px'
              }}>
                  Working as:
                </Typography>
                <Box sx={{
                cursor: 'pointer',
                borderRadius: '8px',
                alignItems: 'center',
                display: 'flex',
                height: '40px',
                width: '266px',
                backgroundColor: companiesOpen ? '#DAEFFF' : '#FFFFFF'
              }} onClick={handleCompaniesOpen}>
                  <Stack sx={{
                  display: 'flex',
                  flexGrow: 1
                }} direction="row">
                    <Box sx={{
                    px: '12px'
                  }}>
                      <Avatar sx={{
                      height: '30px',
                      width: '30px'
                    }}>{getInitals(asCompany?.name)}</Avatar>
                    </Box>
                    <Box sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '180px',
                    alignItems: 'center',
                    alignContent: 'center',
                    display: 'flex'
                  }}>
                      <Typography sx={{
                      color: '#616161F2'
                    }} noWrap>
                        {asCompany?.name}
                      </Typography>
                    </Box>
                    <Box sx={{
                    flexGrow: 1
                  }} />
                    {companiesOpen ? <Box sx={{
                    pl: '18px',
                    position: 'relative',
                    transform: 'rotate(180deg)',
                    bottom: '5px'
                  }}>
                        <Icon name="custom-keyboard-arrow-down" />
                      </Box> : <Box sx={{
                    pr: '18px',
                    position: 'relative',
                    top: '5px'
                  }}>
                        <Icon name="custom-keyboard-arrow-down" />
                      </Box>}
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Divider sx={{
            minHeight: '24px'
          }} orientation="vertical" />
            <Box sx={{
            pr: '20px'
          }}>
              <Tooltip title="Settings">
                <IconButton sx={{
                width: '36px',
                height: '36px',
                textAlign: 'center',
                padding: 0,
                backgroundColor: settingsOpen ? '#DAEFFF' : '#fff',
                color: settingsOpen ? '#53A6D6' : '#1D252D',
                ':hover': {
                  backgroundColor: '#DAEFFF',
                  color: '#53A6D6'
                }
              }} onClick={handleSettingsOpen}>
                  <SettingsIcon fill="#1D252D" />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </div>

        <Dialog PaperProps={{
        style: {
          borderRadius: '28px',
          width: '400px'
        }
      }} open={editName} onClose={() => {
        setEditName(false);
      }}>
          <DialogTitle sx={{
          m: 0,
          p: 2,
          fontSize: 'medium'
        }}>
            <Stack alignItems="center" component="span">
              <Typography variant="h4" component="span">
                Edit Name
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <Box component="span">
                <RoundedTextField sx={{
                width: '100%'
              }} label={!editVal || editVal === '' ? 'Name' : ''} focused autoFocus onFocus={event => {
                event.target.select();
              }} hiddenLabel value={editVal} onChange={e => setEditVal(e.target.value)} onKeyPress={e => {
                if (e.key === 'Enter' && props.name != editVal && editVal != '') {
                  handleSaveClick(props.folderBreadcrumbs && folderData ? folderData.path[folderData.path.length - 1] : {
                    name: props.name
                  });
                }
              }} />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{
          py: 1
        }}>
            <Button onClick={() => {
            setEditName(false);
          }} variant="text" sx={{
            borderRadius: '20px',
            color: 'rgba(29, 37, 45, 0.7)',
            width: '75px'
          }}>
              Cancel
            </Button>
            <Button disabled={props.name == editVal || editVal == ''} onClick={() => {
            handleSaveClick(props.folderBreadcrumbs && folderData ? folderData.path[folderData.path.length - 1] : {
              name: props.name
            });
          }} variant="text" sx={{
            mr: 1,
            borderRadius: '20px',
            width: '75px',
            color: '#53A6D6'
          }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Popper anchorEl={companiesAnchorEl} sx={{
        zIndex: 200
      }} open={companiesOpen} onClose={handleCompaniesClose} placement="bottom">
          <ClickAwayListener onClickAway={handleCompaniesClose}>
            <Paper elevation={0} sx={{
            borderRadius: '16px',
            overflow: 'visible',
            width: '266px',
            overflowY: 'scroll',
            maxHeight: '66vh',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
              marginTop: '20px',
              marginBottom: '20px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555'
            },
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 30,
              height: 30
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 200
            }
          }}>
              <Box sx={{
              py: 1
            }}>
                <Box sx={{
                position: 'sticky',
                top: 0,
                background: '#fff',
                zIndex: 205
              }}>
                  <Grid container justifyContent="center" sx={{
                  pb: 1
                }}>
                    <Box sx={{
                    px: 1,
                    width: '100%'
                  }}>
                      <SearchTextField placeholder="Search companies" fullWidth autoFocus sx={{
                      height: '40px'
                    }} value={companiesSearch} onChange={e => {
                      setCompaniesSearch(e.target.value);
                    }} InputProps={{
                      startAdornment: <InputAdornment position="start">
                              <Icon name="custom-search" />
                            </InputAdornment>,
                      endAdornment: !!companiesSearch && <InputAdornment onClick={reset} position="start" sx={{
                        cursor: 'pointer'
                      }}>
                              <CloseIcon />
                            </InputAdornment>
                    }} />
                    </Box>
                  </Grid>
                  {!showAllCompanies && companiesCount > 10 && <Stack sx={{
                  pt: 2,
                  pl: 2
                }}>
                      <Typography> Showing first 10 of {companiesCount}</Typography>

                      <Button sx={{
                    textTransform: 'none',
                    display: 'flex',
                    justifyContent: 'left'
                  }} onClick={() => setShowAllCompanies(true)}>
                        Show All
                      </Button>
                    </Stack>}
                  {!companiesSearch && !showAllCompanies && companiesCount > 5 && recentlyUsedCompanies.length > 0 && <>
                      <Divider flexItem sx={{
                    pt: 2
                  }} />
                      <Stack>
                        <Box sx={{
                      py: 1,
                      pl: 1
                    }}> Recently Used Companies</Box>
                        {recentlyUsedCompanies.map((company, index) => <Button disabled={asCompany.id == company} onMouseEnter={e => setAvatarHover(company)} onMouseLeave={e => setAvatarHover(null)} onClick={() => {
                      handleCompanyChange(company);
                    }} variant="text" key={company.id} fullWidth sx={{
                      cursor: 'pointer',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                      borderRadius: '0px',
                      color: '#1D252D',
                      borderLeft: '2px solid #fff',
                      p: 0,
                      //color:companiesSearch && index==0? '#53A6D6':'#1D252D',
                      '&:hover': {
                        backgroundColor: '#fff',
                        color: '#53A6D6',
                        borderLeft: '2px solid #53A6D6'
                      }
                    }}>
                            <Stack sx={{
                        p: 1,
                        color: 'inherit',
                        alignItems: 'center'
                      }} direction="row">
                              <Avatar sx={{
                          backgroundColor: avatarHover == company ? '#53A6D6' : '#BEBEBE'
                        }}>
                                {getInitals(company.name)}
                              </Avatar>
                              <Typography sx={{
                          fontSize: 14,
                          top: '5px',
                          position: 'relative',
                          pl: '10px',
                          color: 'inherit',
                          textAlign: 'left',
                          maxWidth: '266px',
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                          wordBreak: 'break-word'
                        }}>
                                {company.name}
                              </Typography>
                            </Stack>
                          </Button>)}
                      </Stack>
                    </>}
                  <Divider flexItem sx={{
                  pt: 2
                }} />
                </Box>
                <Box display="flex" flexDirection="column">
                  {companies.map(company => <Button disabled={asCompany.id == company} onMouseEnter={e => setAvatarHover(company)} onMouseLeave={e => setAvatarHover(null)} onClick={() => {
                  handleCompanyChange(company);
                }} variant="text" key={company.id} fullWidth sx={{
                  cursor: 'pointer',
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  borderRadius: '0px',
                  color: '#1D252D',
                  borderLeft: '2px solid #fff',
                  p: 0,
                  //color:companiesSearch && index==0? '#53A6D6':'#1D252D',
                  '&:hover': {
                    backgroundColor: '#fff',
                    color: '#53A6D6',
                    borderLeft: '2px solid #53A6D6'
                  }
                }}>
                      <Stack sx={{
                    p: 1,
                    color: 'inherit',
                    alignItems: 'center'
                  }} direction="row">
                        <Avatar sx={{
                      backgroundColor: avatarHover == company ? '#53A6D6' : '#BEBEBE'
                    }}>{getInitals(company.name)}</Avatar>
                        <Typography sx={{
                      fontSize: 14,
                      top: '5px',
                      position: 'relative',
                      pl: '10px',
                      color: 'inherit',
                      textAlign: 'left',
                      maxWidth: '266px',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-word'
                    }}>
                          {company.name}
                        </Typography>
                      </Stack>
                    </Button>)}
                  {!companiesCount && <Box sx={{
                  p: 2
                }}> No results</Box>}
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>

        <Popper anchorEl={settingsAnchorEl} sx={{
        zIndex: 200
      }} open={settingsOpen} onClose={handleSettingsClose} placement="bottom-end">
          <ClickAwayListener onClickAway={handleSettingsClose}>
            <Paper elevation={0} sx={{
            borderRadius: '16px',
            overflow: 'visible',
            width: isUserEnrolledInLaunchDarklyBeta ? '265px' : '220px',
            overflowY: 'hidden',
            filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 76,
              height: 76
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 200
            }
          }}>
              <Box sx={{
              py: 2
            }}>
                <Box display="flex" flexDirection="column">
                  <NavLink to={'/users/' + user.id} style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%'
                }}>
                    <Box sx={{
                    pt: '20px',
                    alignContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                      <Avatar sx={{
                      bgcolor: '#D9D9D9'
                    }}>
                        {user.first_name[0]} {user.last_name[0]}
                      </Avatar>
                      <Typography sx={{
                      maxWidth: '266px',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-word',
                      pt: 2,
                      pb: 1
                    }} variant="tableHeader">
                        {user.first_name} {user.last_name}{' '}
                      </Typography>
                      <Typography sx={{
                      maxWidth: '266px',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-word',
                      pb: 2
                    }}>
                        {user.email}
                      </Typography>
                    </Box>
                  </NavLink>
                  <Divider />
                  <Stack>
                    <Box sx={{
                    pt: 1
                  }}>
                      {settingsMenu.filter(sub => sub.enabled).map(subItem => <Button variant="text" key={subItem.name} fullWidth onMouseEnter={e => setAvatarHover(subItem.key)} onMouseLeave={e => setAvatarHover(null)} sx={{
                      cursor: 'pointer',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                      borderRadius: '0px',
                      color: '#1D252D',
                      '&:hover': {
                        backgroundColor: '#fff',
                        color: '#53A6D6'
                      }
                    }}>
                            {subItem.key == 'beta' || subItem.key == 'logout' ? <Stack onClick={e => {
                        if (subItem.key == 'beta') {
                          handleSettingsClose();
                          setIsLoading(true);
                          if (isUserEnrolledInLaunchDarklyBeta) {
                            removeUserFromLaunchDarklySegment({
                              segment_key: 'beta'
                            }).then(({
                              data
                            }) => {
                              window.location.reload();
                            });
                          } else {
                            addUserToLaunchDarklySegment({
                              segment_key: 'beta'
                            }).then(({
                              data
                            }) => {
                              window.location.reload();
                            });
                          }
                        } else {
                          //is logout
                          logOut();
                        }
                      }} sx={{
                        p: 1,
                        color: 'inherit',
                        alignItems: 'center'
                      }} direction="row">
                                <subItem.icon fill={avatarHover == subItem.key ? '#53A6D6' : '#1D252D'} />
                                <Typography variant="tableHeader" sx={{
                          fontSize: 14,
                          mt: '2px',
                          pl: '10px',
                          color: 'inherit'
                        }}>
                                  {subItem.name}
                                </Typography>
                              </Stack> : <NavLink to={subItem.key} style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        width: '100%'
                      }}>
                                <Stack sx={{
                          p: 1,
                          color: 'inherit',
                          alignItems: 'center'
                        }} direction="row">
                                  <subItem.icon fill={avatarHover == subItem.key ? '#53A6D6' : '#1D252D'} />
                                  <Typography variant="tableHeader" sx={{
                            fontSize: 14,
                            mt: '2px',
                            pl: '10px',
                            color: 'inherit'
                          }}>
                                    {subItem.name}
                                  </Typography>
                                </Stack>
                              </NavLink>}
                          </Button>)}
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
        {backdrop && <Box sx={{
        backgroundColor: '#354052',
        opacity: '0.5',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 899
      }}></Box>}
      </Scoped>
    </>;
};